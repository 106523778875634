import { CLEAR_ON_SIGNOUT, REFRESH_ACCESS_TOKEN, SET_AUTH_TOKEN } from 'services/constants';

export const setAuthToken = token => ({
  type: SET_AUTH_TOKEN,
  payload: token
});

export const refreshAccessToken = token => ({
  type: REFRESH_ACCESS_TOKEN,
  payload: token
});

export const logout = () => ({
  type: CLEAR_ON_SIGNOUT
});
