import React, { createContext, useReducer, useContext } from 'react';
import rootReducer from './reducers';

const persistedState = localStorage.getItem('bmp-state');

export const initialValue = {
  auth: {
    accessToken: null
  }
};

const initialState = persistedState ? JSON.parse(persistedState) : initialValue;

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  localStorage.setItem('bmp-state', JSON.stringify(state));

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
const useStore = () => useContext(store);

export { store, StateProvider, useStore };
