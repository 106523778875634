import React, { useState } from 'react';
import { Card, Statistic, Row, Col, Typography, Divider, Empty } from 'antd';
import { useQuery } from 'react-query';
import api from 'services/api/api';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import CardHeader from 'components/CardHeader';

const { Title } = Typography;

const StorePage = () => {
  const [state, setState] = useState({
    storeId: null,
    factoryId: null,
    nameId: null,
    formId: null
  });

  const grandTotal = useQuery('grandTotal', api.requests.warehouse.getGrandTotal);
  const grandTotalInfo = grandTotal?.data?.data;

  const productsByStore = useQuery(['productsByStore', state.factoryId], () =>
    api.requests.warehouse.getByStore(state.factoryId)
  );

  const productsByStoreInfo = productsByStore?.data?.data;

  console.log('productsByStoreInfo => ', productsByStore);

  const productsByName = useQuery(['productsByName', state.factoryId, state.storeId], () =>
    api.requests.warehouse.getByName({ factoryId: state.factoryId, storeId: state.storeId })
  );
  const productsByNameInfo = productsByName?.data?.data?.data;

  const productsByForm = useQuery(
    ['productsByForm', state.factoryId, state.storeId, state.nameId],
    () =>
      api.requests.warehouse.getByForm({ factoryId: state.factoryId, storeId: state.storeId, nameId: state.nameId }),
    {
      enabled: !!state.nameId
    }
  );
  const productsByFormInfo = productsByForm?.data?.data?.data;

  const productsByCaliber = useQuery(
    ['productsByCaliber', state.factoryId, state.storeId, state.nameId, state.formId],
    () =>
      api.requests.warehouse.getByCaliber({
        factoryId: state.factoryId,
        storeId: state.storeId,
        nameId: state.nameId,
        formId: state.formId
      }),
    {
      enabled: !!state.nameId && !!state.formId
    }
  );
  const productsByCaliberInfo = productsByCaliber?.data?.data?.data;

  const renderStatusIcon = active =>
    active ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />;

  return (
    <div>
      <CardHeader title="Склад" />
      <br />
      <Row gutter={16}>
        <Col span={6}>
          <Card
            style={{ cursor: 'pointer', borderColor: !state.factoryId && 'blue' }}
            onClick={() =>
              setState({
                factoryId: null,
                storeId: null,
                nameId: null,
                formId: null
              })
            }
          >
            <Title level={3} style={{ marginBottom: '5px' }}>
              Все Заводы
            </Title>
            <Statistic value={grandTotalInfo?.total} />
          </Card>
          <br />
        </Col>
        {grandTotalInfo?.factories?.length &&
          grandTotalInfo?.factories?.map((factory, index) => {
            const isSelected = factory.id === state.factoryId;
            return (
              <Col span={6} key={index}>
                <Card
                  style={{ cursor: 'pointer', borderColor: isSelected && 'blue' }}
                  onClick={() =>
                    setState({ ...state, factoryId: factory.id, storeId: null, nameId: null, formId: null })
                  }
                >
                  <Title level={3} style={{ marginBottom: '5px' }}>
                    {factory.name}
                  </Title>
                  <Statistic value={factory.total_balance} />
                </Card>
                <br />
              </Col>
            );
          })}
      </Row>
      <Divider />
      <Title level={3} style={{ marginBottom: '5px' }}>
        Склад
      </Title>
      <Row gutter={16}>
        <Col span={6}>
          <Card
            style={{ cursor: 'pointer', borderColor: !state.storeId && 'blue' }}
            onClick={() =>
              setState({
                ...state,
                storeId: null,
                nameId: null,
                formId: null
              })
            }
          >
            <Title level={3} style={{ marginBottom: '5px' }}>
              Все Склады
            </Title>
            <Statistic value={productsByStoreInfo?.total} />
          </Card>
          <br />
        </Col>
        {productsByStoreInfo?.stores?.length &&
          productsByStoreInfo?.stores?.map((store, index) => {
            const isSelected = store.id === state.storeId;
            return (
              <Col span={6} key={index}>
                <Card
                  style={{ cursor: 'pointer', borderColor: isSelected && 'blue' }}
                  onClick={() => setState({ ...state, storeId: store.id, nameId: null, formId: null })}
                >
                  <Title level={3} style={{ marginBottom: '5px' }}>
                    {store.name}
                  </Title>
                  <Statistic value={store.total_balance} />
                </Card>
                <br />
              </Col>
            );
          })}
      </Row>
      <Divider />
      <Title level={3} style={{ marginBottom: '5px' }}>
        Наименование
      </Title>
      <Row gutter={16}>
        {productsByNameInfo?.length &&
          productsByNameInfo.map((product, index) => {
            const isSelected = product.id === state.nameId;
            return (
              <Col span={6} key={index}>
                <Card
                  style={{ cursor: 'pointer', borderColor: isSelected && 'blue' }}
                  onClick={() => setState({ ...state, nameId: product.id, formId: null })}
                >
                  <Title level={3} style={{ marginBottom: '5px' }}>
                    {product.name}
                  </Title>
                  <Statistic value={product.total} />
                </Card>
                <br />
              </Col>
            );
          })}
      </Row>
      <Divider />
      <Title level={3} style={{ marginBottom: '5px' }}>
        Тип товара
      </Title>
      <Row gutter={16}>
        {productsByFormInfo?.materials?.length ? (
          <>
            {productsByFormInfo?.materials.map((material, index) => {
              return (
                <Col span={6} key={index}>
                  <Card>
                    <Title level={3} style={{ marginBottom: '5px' }}>
                      {material.name}
                    </Title>
                    <Statistic value={material.total_balance} />
                  </Card>
                  <br />
                </Col>
              );
            })}
            <Divider />
          </>
        ) : null}
        {productsByFormInfo?.products?.length ? (
          <>
            {productsByFormInfo?.products.map((product, index) => {
              const isSelected = product.id === state.formId;

              return (
                <Col span={6} key={index}>
                  <Card
                    style={{ cursor: 'pointer', borderColor: isSelected && 'blue' }}
                    onClick={() => setState({ ...state, formId: product.id })}
                  >
                    <Title level={3} style={{ marginBottom: '5px' }}>
                      {product.name}
                    </Title>
                    <Statistic value={product.total_balance} />
                  </Card>
                  <br />
                </Col>
              );
            })}
            <Divider />
          </>
        ) : null}
      </Row>
      <Title level={3} style={{ marginBottom: '5px' }}>
        Калибр
      </Title>
      <Row gutter={16}>
        {productsByCaliberInfo?.length ? (
          productsByCaliberInfo?.map((product, index) => {
            return (
              <Col span={6} key={index}>
                <Card>
                  <Title level={3} style={{ marginBottom: '4px' }}>
                    Калибр: {product.name}
                  </Title>
                  {renderStatusIcon(product.photoseparator)} Фотосепаратор
                  <br />
                  {renderStatusIcon(product.polished)} Полировка
                  <Statistic value={product.total_balance} />
                </Card>
                <br />
              </Col>
            );
          })
        ) : (
          <Card>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Card>
        )}
      </Row>
    </div>
  );
};

export default StorePage;
