import React, { Fragment, useState } from 'react';
import { Checkbox, Button, Card, Form, Input, Modal, notification, Row, Space, Table, Tabs, Select } from 'antd';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';

import api from 'services/api/api';
import NameSection from './sections/NameSection/NameSection';
import IncomeTypeSection from 'pages/CatalogPage/sections/IncomeTypeSection/IncomeTypeSection';
import OutcomeTypeSection from 'pages/CatalogPage/sections/OutcomeTypeSection/OutcomeTypeSection';
import CitySection from './sections/CitySection/CitySection';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import FactorySection from './sections/FactorySection/FactorySection';

const { TabPane } = Tabs;
const { Option } = Select;

const staffList = [
  {
    key: 'accountant',
    title: 'Финансист'
  },
  {
    key: 'user',
    title: 'Закупщик'
  },
  {
    key: 'manager',
    title: 'Менеджер Производства'
  },
  {
    key: 'specialist',
    title: 'Специалист по Контракту'
  },
  {
    key: 'former',
    title: 'Оформитель'
  },
  {
    key: 'worker',
    title: 'Рабочий (кошелёк)'
  }
];

const CatalogPage = () => {
  const [state, setState] = useState({
    activeSection: 'names',
    addModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null,
    parent: null,
    childSection: null,
    selectedStaff: ['user']
  });

  // Cache
  const cache = useQueryCache();
  const forms = useQuery('forms', api.requests.products.forms.getProductFormList);
  const calibers = useQuery('calibers', api.requests.products.calibers.getProductCaliberList);
  const packagingTypes = useQuery('PackagingTypes', api.requests.products.packagingTypes.getProductPackagingTypeList);
  const countries = useQuery('countries', api.requests.countries.getCountryList);
  const currencies = useQuery('currencies', api.requests.currency.getCurrencyList);
  const paymethods = useQuery('paymethods', api.requests.paymethods.getPaymethodList);
  const names = useQuery('names', api.requests.materials.names.getMaterialNameList);
  const materialTypes = useQuery('materialTypes', api.requests.materials.types.getMaterialTypeList);
  const cities = useQuery('cities', api.requests.cities.getCityList);
  const factories = useQuery('factories', api.requests.factories.getFactoriesList);
  const banks = useQuery('banks', api.requests.banks.getBankList);
  const incomeTypes = useQuery('incomeTypes', api.requests.incomeTypes.getIncomeTypeList);
  const outcomeTypes = useQuery('outcomeTypes', api.requests.outcomeTypes.getOutcomeTypeList);
  const materialSpendingSources = useQuery(
    'materialSpendingSources',
    api.requests.materials.spendingSources.getMaterialSpendingSourceList
  );
  const spendingSources = useQuery('spendingSources', api.requests.spendings.spendingSources.getSpendingSourceList);
  const spendingTypes = useQuery('spendingTypes', api.requests.spendings.spendingTypes.getSpendingTypeList);

  const addFunc = data => {
    switch (state.activeSection) {
      case 'names':
        return api.requests.materials.names.createMaterialName(data);
      case 'materialTypes':
        return api.requests.materials.types.createMaterialType(data);
      case 'cities':
        if (state.childSection === 'regions') {
          return api.requests.regions.createRegion(data);
        }
        return api.requests.cities.createCity(data);
      case 'countries':
        return api.requests.countries.createCountry(data);
      case 'forms':
        return api.requests.products.forms.createProductForm(data);
      case 'calibers':
        return api.requests.products.calibers.createProductCaliber(data);
      case 'packagingTypes':
        return api.requests.products.packagingTypes.createProductPackagingType(data);
      case 'currencies':
        return api.requests.currency.createCurrency(data);
      case 'paymethods':
        return api.requests.paymethods.createPaymethod(data);
      case 'factories':
        if (state.childSection === 'stores') {
          return api.requests.stores.createStore(data);
        }
        return api.requests.factories.createFactory(data);
      case 'banks':
        return api.requests.banks.createBank(data);
      case 'materialSpendingSources':
        return api.requests.materials.spendingSources.createMaterialSpendingSource(data);
      case 'spendingSources':
        return api.requests.spendings.spendingSources.createSpendingSource(data);
      case 'incomeTypes':
        if (state.childSection === 'incomeSources') {
          return api.requests.incomeSources.createIncomeSource(data);
        }
        return api.requests.incomeTypes.createIncomeType(data);
      case 'outcomeTypes':
        if (state.childSection === 'outcomeSources') {
          return api.requests.outcomeSources.createOutcomeSource(data);
        }
        return api.requests.outcomeTypes.createOutcomeType(data);
      default:
        return '';
    }
  };

  const updateFunc = data => {
    switch (state.activeSection) {
      case 'names':
        return api.requests.materials.names.updateMaterialName(data);
      case 'materialTypes':
        return api.requests.materials.types.updateMaterialType(data);
      case 'cities':
        if (state.childSection === 'regions') {
          return api.requests.regions.updateRegion(data);
        }
        return api.requests.cities.updateCity(data);
      case 'countries':
        return api.requests.countries.updateCountry(data);
      case 'forms':
        return api.requests.products.forms.updateProductForm(data);
      case 'calibers':
        return api.requests.products.calibers.updateProductCaliber(data);
      case 'packagingTypes':
        return api.requests.products.packagingTypes.updateProductPackagingType(data);
      case 'currencies':
        return api.requests.currency.updateCurrency(data);
      case 'paymethods':
        return api.requests.paymethods.updatePaymethod(data);
      case 'factories':
        if (state.childSection === 'stores') {
          return api.requests.stores.updateStore(data);
        }
        return api.requests.factories.updateFactory(data);
      case 'banks':
        return api.requests.banks.updateBank(data);
      case 'materialSpendingSources':
        return api.requests.materials.spendingSources.updateMaterialSpendingSource(data);
      case 'spendingSources':
        return api.requests.spendings.spendingSources.updateSpendingSource({
          data: { ...data.data, roles: state.selectedStaff },
          id: data.id
        });
      case 'incomeTypes':
        if (state.childSection === 'incomeSources') {
          return api.requests.incomeSources.updateIncomeSource(data);
        }
        return api.requests.incomeTypes.updateIncomeType(data);
      case 'outcomeTypes':
        if (state.childSection === 'outcomeSources') {
          return api.requests.outcomeSources.updateOutcomeSource(data);
        }
        return api.requests.outcomeTypes.updateOutcomeType(data);
      default:
        return '';
    }
  };

  const deleteFunc = () => {
    switch (state.activeSection) {
      case 'names':
        return api.requests.materials.names.deleteMaterialName;
      case 'materialTypes':
        return api.requests.materials.types.deleteMaterialType;
      case 'cities':
        if (state.childSection === 'regions') {
          return api.requests.regions.deleteRegion;
        }
        return api.requests.cities.deleteCity;
      case 'countries':
        return api.requests.countries.deleteCountry;
      case 'forms':
        return api.requests.products.forms.deleteProductForm;
      case 'calibers':
        return api.requests.products.calibers.deleteProductCaliber;
      case 'packagingTypes':
        return api.requests.products.packagingTypes.deleteProductPackagingType;
      case 'currencies':
        return api.requests.currency.deleteCurrency;
      case 'paymethods':
        return api.requests.paymethods.deletePaymethod;
      case 'factories':
        if (state.childSection === 'stores') {
          return api.requests.stores.deleteStore;
        }
        return api.requests.factories.deleteFactory;
      case 'banks':
        return api.requests.banks.deleteBank;
      case 'materialSpendingSources':
        return api.requests.materials.spendingSources.deleteMaterialSpendingSource;
      case 'spendingSources':
        return api.requests.spendings.spendingSources.deleteSpendingSource;
      case 'incomeTypes':
        if (state.childSection === 'incomeSources') {
          return api.requests.incomeSources.deleteIncomeSource;
        }
        return api.requests.incomeTypes.deleteIncomeType;
      case 'outcomeTypes':
        if (state.childSection === 'outcomeSources') {
          return api.requests.outcomeSources.deleteOutcomeSource;
        }
        return api.requests.outcomeTypes.deleteOutcomeType;
      default:
        return '';
    }
  };

  const addModalTitle = () => {
    switch (state.activeSection) {
      case 'names':
        return 'Товара';
      case 'materialTypes':
        return 'Формы Закупа';
      case 'forms':
        return 'Формы Продукта';
      case 'calibers':
        return 'Калибра';
      case 'packagingTypes':
        return 'Типа упаковки';
      case 'countries':
        return 'Страны';
      case 'cities':
        if (state.childSection === 'regions') {
          return 'Региона';
        }
        return 'Города';
      case 'currencies':
        return 'Валюты';
      case 'paymethods':
        return 'Формы Оплаты';
      case 'factories':
        if (state.childSection === 'stores') {
          return 'Склада';
        }
        return 'Завода';
      case 'banks':
        return 'Банка';
      case 'incomeTypes':
        return 'Прихода';
      case 'outcomeTypes':
        return 'Расхода';
      case 'materialSpendingSources':
        return 'Расхода по Закупу';
      case 'spendingSources':
        return 'Личных Расходов';
      default:
        return '';
    }
  };

  // Mutations
  const [addItem, addItemInfo] = useMutation(
    data => {
      return state.editingItem ? updateFunc(data) : addFunc(data);
    },
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem ? `Элемент успешно сохранён` : `Элемент успешно добавлен`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false,
          childSection: null,
          editingItem: null
        });
        clearForm();

        cache.invalidateQueries(state.activeSection);
      },
      onError: error => {
        notification.error({
          message: `Ошибка при добавлении`,
          placement: 'topRight'
        });
      }
    }
  );

  // Mutations
  const [deleteItem, deleteItemInfo] = useMutation(deleteFunc(), {
    onSuccess: mes => {
      notification.success({
        message: `Элемент успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false,
        childSection: null,
        editingItem: null
      });
      clearForm();
      cache.invalidateQueries(state.activeSection);
    },
    onError: error => {
      notification.error({
        message: `Элемент успешно удалён`,
        placement: 'topRight'
      });
    }
  });

  const clearForm = () => {
    formik.values.name = '';

    if (['names', 'materialTypes', 'forms'].includes(state.activeSection)) {
      formik.values.letter = '';
    }

    if (state.activeSection === 'forms') {
      formik.values.calculateCost = false;
    }

    if (state.activeSection === 'currencies') {
      formik.values.symbol = '';
    }

    if (state.activeSection === 'banks') {
      formik.values.corporate = false;
    }
  };

  const startEditing = rec => {
    formik.values.name = rec.name;

    if (['names', 'materialTypes', 'forms'].includes(state.activeSection)) {
      formik.values.letter = rec.letter;
    }

    if (state.activeSection === 'forms') {
      formik.values.calculateCost = rec.calculateCost;
    }

    if (state.activeSection === 'currencies') {
      formik.values.symbol = rec.symbol;
    }

    if (state.activeSection === 'spendingSources') {
      formik.values.spendingTypeId = rec.spendingTypeId;
    }
  };

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;

      if (state.childSection === 'regions') {
        data = {
          parentId: state.parent && state.parent.id,
          ...values
        };
      }

      if (state.childSection === 'stores') {
        data = {
          factoryId: state.parent && state.parent.id,
          ...values
        };
      }

      if (state.childSection === 'incomeSources') {
        data = {
          typesId: state.parent && state.parent.id,
          ...values
        };
      }

      if (state.childSection === 'outcomeSources') {
        data = {
          otypesId: state.parent && state.parent.id,
          ...values
        };
      }

      if (state.activeSection === 'spendingSources') {
        data = {
          ...values,
          roles: state.selectedStaff[0] === '' ? null : state.selectedStaff
        };
      }

      if (state.editingItem) {
        data = {
          id: state.editingItem && state.editingItem.id,
          data: values
        };
      }

      return addItem(data);
    }
  });

  const handleMultipleSelect = value => {
    setState({
      ...state,
      selectedStaff: value
    });
  };

  const expandedRegions = item => {
    const columns = [
      { title: 'id', dataIndex: 'id' },
      { title: 'Наименование', dataIndex: 'name' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  addModalVisible: true,
                  editingItem: record,
                  childSection: 'regions'
                });
                startEditing(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  childSection: 'regions'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        Регион
        <br />
        <Table
          columns={columns}
          bordered
          dataSource={item && item.regions && item.regions.length ? item.regions : []}
          pagination={false}
        />
      </Fragment>
    );
  };

  const expandedStores = item => {
    const columns = [
      { title: 'id', dataIndex: 'id' },
      { title: 'Наименование', dataIndex: 'name' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  addModalVisible: true,
                  editingItem: record,
                  childSection: 'stores'
                });
                startEditing(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  childSection: 'stores'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        Склады
        <br />
        <Table
          columns={columns}
          bordered
          dataSource={item && item.stores && item.stores.length ? item.stores : []}
          pagination={false}
        />
      </Fragment>
    );
  };

  const expandedIncomeSources = item => {
    const columns = [
      { title: 'id', dataIndex: 'id' },
      { title: 'Наименование', dataIndex: 'name' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  addModalVisible: true,
                  editingItem: record,
                  childSection: 'incomeSources'
                });
                startEditing(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  childSection: 'incomeSources'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        Источники
        <br />
        <Table
          columns={columns}
          bordered
          dataSource={item && item.sources && item.sources.length ? item.sources : []}
          pagination={false}
        />
      </Fragment>
    );
  };

  const expandedOutcomeSources = item => {
    const columns = [
      { title: 'id', dataIndex: 'id' },
      { title: 'Наименование', dataIndex: 'name' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  addModalVisible: true,
                  editingItem: record,
                  childSection: 'outcomeSources'
                });
                startEditing(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  childSection: 'outcomeSources'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        Цель
        <br />
        <Table
          columns={columns}
          bordered
          dataSource={item && item.osources && item.osources.length ? item.osources : []}
          pagination={false}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Card>
        <Tabs
          defaultActiveKey={state.activeSection}
          onChange={key => {
            setState({
              ...state,
              activeSection: key
            });
          }}
          tabBarExtraContent={
            <Button
              onClick={() => {
                clearForm();
                setState({
                  ...state,
                  addModalVisible: true,
                  editingItem: null
                });
              }}
              type="primary"
            >
              Добавить
            </Button>
          }
        >
          <TabPane tab="Наименование" key="names">
            <NameSection names={names} setState={setState} state={state} startEditing={startEditing} />
          </TabPane>
          <TabPane tab="Форма закупа" key="materialTypes">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={materialTypes.isLoading || materialTypes.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                { title: 'Код', dataIndex: 'letter' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={materialTypes?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Форма продукта" key="forms">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={forms.isLoading || forms.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                { title: 'Код', dataIndex: 'letter' },
                {
                  title: 'Себестоимость',
                  dataIndex: 'calculateCost',
                  render: calculateCost =>
                    calculateCost ? (
                      <CheckOutlined style={{ color: 'green' }} />
                    ) : (
                      <CloseOutlined style={{ color: 'red' }} />
                    )
                },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={forms?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Калибр" key="calibers">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={calibers.isLoading || calibers.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={calibers?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Тип упаковки" key="packagingTypes">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={calibers.isLoading || calibers.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={packagingTypes?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Страны" key="countries">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={countries.isLoading || countries.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={countries?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Города/Регионы" key="cities">
            <CitySection
              expandedRowRender={expandedRegions}
              cities={cities}
              setState={setState}
              state={state}
              startEditing={startEditing}
            />
          </TabPane>
          <TabPane tab="Заводы/Склады" key="factories">
            <FactorySection
              expandedRowRender={expandedStores}
              factories={factories}
              setState={setState}
              state={state}
              startEditing={startEditing}
            />
          </TabPane>
          <TabPane tab="Банки" key="banks">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={banks.isLoading || banks.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Касса',
                  dataIndex: 'id',
                  render: id =>
                    id === 1 ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
                },
                {
                  title: 'Корпоративный счет',
                  dataIndex: 'corporate',
                  render: corporate =>
                    corporate ? (
                      <CheckOutlined style={{ color: 'green' }} />
                    ) : (
                      <CloseOutlined style={{ color: 'red' }} />
                    )
                },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={banks?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Валюта" key="currencies">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={currencies.isLoading || currencies.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                { title: 'Знак', dataIndex: 'symbol' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={currencies?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Тип Прихода" key="incomeTypes">
            <IncomeTypeSection
              expandedRowRender={expandedIncomeSources}
              incomeTypes={incomeTypes}
              setState={setState}
              state={state}
              startEditing={startEditing}
            />
          </TabPane>
          <TabPane tab="Тип Расхода" key="outcomeTypes">
            <OutcomeTypeSection
              expandedRowRender={expandedOutcomeSources}
              outcomeTypes={outcomeTypes}
              setState={setState}
              state={state}
              startEditing={startEditing}
            />
          </TabPane>
          <TabPane tab="Расходы по Закупу" key="materialSpendingSources">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={materialSpendingSources.isLoading || materialSpendingSources.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={materialSpendingSources?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Расходы пользователей" key="spendingSources">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={spendingSources.isLoading || spendingSources.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Категория',
                  dataIndex: 'spendingType',
                  render: (_, record) => {
                    return record.spendingType?.name;
                  }
                },
                {
                  title: 'Пользователи',
                  dataIndex: 'roles',
                  render: (_, record) => {
                    let userTitles = staffList
                      .map(item => (record.roles.includes(item.key) ? item : null))
                      .filter(item => item && item);
                    let usersList = '';
                    userTitles.map((user, id) => {
                      if (user) {
                        usersList += ` ${user.title} ${id === userTitles.length - 1 ? '' : ','}`;
                      }
                    });
                    return usersList;
                  }
                },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record,
                            selectedStaff: record.roles.length ? record.roles : []
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={spendingSources?.data?.data?.data}
            />
          </TabPane>
          <TabPane tab="Форма оплаты" key="paymethods">
            <Table
              rowKey="id"
              size="middle"
              bordered
              loading={paymethods.isLoading || paymethods.isFetching}
              style={{ overflowX: 'scroll' }}
              columns={[
                { title: 'id', dataIndex: 'id' },
                { title: 'Наименование', dataIndex: 'name' },
                {
                  title: 'Действия',
                  dataIndex: 'actions',
                  render: (_, record) => (
                    <Space>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          startEditing(record);
                          setState({
                            ...state,
                            addModalVisible: true,
                            editingItem: record
                          });
                        }}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModalVisible: true,
                            deletingItem: record
                          });
                        }}
                      />
                    </Space>
                  )
                }
              ]}
              dataSource={paymethods?.data?.data?.data}
            />
          </TabPane>
        </Tabs>
      </Card>
      <Modal
        title={state.editingItem ? `Изменение ${addModalTitle()}` : `Добавление ${addModalTitle()}`}
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Название"
            validateStatus={formik.touched.name && formik.errors.name ? 'error' : 'validating'}
            help={formik.touched.name && formik.errors.name}
          >
            <Input
              name="name"
              placeholder="Наименование"
              value={formik.values.name}
              onChange={formik.handleChange}
              id="name"
            />
          </Form.Item>
          {state.activeSection === 'spendingSources' && (
            <>
              <Form.Item
                label="Категория"
                validateStatus={formik.touched.spendingTypeId && formik.errors.spendingTypeId ? 'error' : 'validating'}
                help={formik.touched.spendingTypeId && formik.errors.spendingTypeId}
              >
                <Select
                  id="spendingTypeId"
                  name="spendingTypeId"
                  value={formik.values.spendingTypeId}
                  onChange={value => formik.setFieldValue('spendingTypeId', value)}
                >
                  {spendingTypes?.data?.data?.data &&
                    spendingTypes?.data?.data?.data.map((item, id) => {
                      return (
                        <Select.Option value={item.id} key={id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Пользователи"
                validateStatus={formik.touched.roles && formik.errors.roles ? 'error' : 'validating'}
                help={formik.touched.roles && formik.errors.roles}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Выберите пользователей"
                  value={state.selectedStaff}
                  onChange={handleMultipleSelect}
                >
                  {staffList.map(user => (
                    <Option key={user.key}>{user.title}</Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {['names', 'materialTypes', 'forms'].includes(state.activeSection) && (
            <Form.Item label="Код">
              <Input
                disabled={state.editingItem}
                name="letter"
                placeholder="Код"
                value={formik.values.letter}
                onChange={formik.handleChange}
                id="letter"
              />
            </Form.Item>
          )}
          {state.activeSection === 'forms' && (
            <Form.Item label=" ">
              <Checkbox
                checked={formik.values.calculateCost}
                onChange={e => formik.setFieldValue('calculateCost', e.target.checked)}
              >
                Себестоимость
              </Checkbox>
            </Form.Item>
          )}
          {state.activeSection === 'banks' && (
            <Form.Item label=" ">
              <Checkbox
                checked={formik.values.corporate}
                onChange={e => formik.setFieldValue('corporate', e.target.checked)}
              >
                Корпоративный счет
              </Checkbox>
            </Form.Item>
          )}
          {state.activeSection === 'currencies' && (
            <Form.Item label="Знак">
              <Input
                name="symbol"
                placeholder="Знак"
                value={formik.values.symbol}
                onChange={formik.handleChange}
                id="symbol"
              />
            </Form.Item>
          )}
          <Row justify="space-between">
            <Button
              onClick={formik.handleSubmit}
              disabled={addItemInfo && addItemInfo.isLoading}
              loading={addItemInfo && addItemInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление ${addModalTitle()}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
        }}
        footer={null}
      >
        Вы уверены что хотите удалить <b>{state.deletingItem ? state.deletingItem.name : ''}</b> ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteItem(state.deletingItem.id);
          }}
          disabled={deleteItemInfo && deleteItemInfo.isLoading}
          loading={deleteItemInfo && deleteItemInfo.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </Fragment>
  );
};

export default CatalogPage;
