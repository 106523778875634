import React, { useState, useEffect } from 'react';
import { Form, notification } from 'antd';
import { useMutation } from 'react-query';

import { FORM_ITEM_LAYOUT } from 'services/constants';
import IncomeForm from './IncomeForm';
import OutcomeForm from './OutcomeForm';
import SelectItem from 'components/FormItems/SelectFormItem';
import { ROLE_LIST, ACCOUNTANT, CASHIER } from 'services/constants/roles';
import {
  TRANSACTION_TYPE_LIST,
  INCOME,
  OUTCOME,
  TRANSFER,
  CONVERSION,
  REFUND
} from 'services/constants/transactionTypes';
import api from 'services/api/api';
import TransferForm from './TransferForm';
import ConversionForm from './ConversionForm';
import RefundForm from './RefundForm';

const { useForm, useWatch } = Form;

const TransactionForm = ({ initialValues, filterList, onFinish }) => {
  const [form] = useForm();
  const [creatorError, setCreatorError] = useState(false);

  const transactionTypeValue = useWatch('transactionType', form);
  const roleValue = useWatch('role', form);
  const creatorIdValue = useWatch('creatorId', form);

  const getURL = () => {
    switch (transactionTypeValue) {
      case INCOME.key:
      case OUTCOME.key:
        return initialValues
          ? api.requests.transactions.updateTransaction
          : api.requests.transactions.createTransaction;
      case TRANSFER.key:
        return initialValues
          ? api.requests.transactions.updateTransaction
          : api.requests.transactions.createTransfer;
      case CONVERSION.key:
        return initialValues
          ? api.requests.transactions.updateTransaction
          : api.requests.transactions.createConversion;
      case REFUND.key:
        return initialValues
          ? api.requests.transactions.updateTransaction
          : api.requests.refunds.createRefund;
      default:
        break;
    }
  };

  const [addTransaction, addTransactionInfo] = useMutation(getURL(), {
    onSuccess: mes => {
      notification.success({
        message: initialValues
          ? `Транзакция успешно сохранена`
          : `Транзакция успешно добавлена:}`,
        placement: 'topRight'
      });
      onFinish();
    },
    onError: error => {
      notification.error({
        message: `Ошибка при добавлении`,
        placement: 'topRight'
      });
    }
  });

  const onDataEntryFinish = values => {
    if (!creatorIdValue) {
      setCreatorError(true);
      return;
    }

    values.id = initialValues?.id;
    values.date = values.date.toISOString();
    values.creatorId = creatorIdValue;
    values.transactionType = transactionTypeValue;

    switch (transactionTypeValue) {
      case INCOME.key:
        break;
      case OUTCOME.key:
        values.amount = -1 * Math.abs(values.amount);
        break;
      case TRANSFER.key:
        values.tranferred = true;
        break;
      case CONVERSION.key:
        values.converted = true;
        break;
      case REFUND.key:
        values.refunded = true;
        break;
      default:
        break;
    }

    addTransaction(values);
  };

  const renderForms = () => {
    switch (transactionTypeValue) {
      case INCOME.key:
        return (
          <IncomeForm
            role={roleValue}
            filterList={filterList}
            onFinish={onDataEntryFinish}
            isCreating={addTransactionInfo?.isLoading}
            initialValues={initialValues}
          />
        );
      case OUTCOME.key:
        return (
          <OutcomeForm
            role={roleValue}
            filterList={filterList}
            onFinish={onDataEntryFinish}
            isCreating={addTransactionInfo?.isLoading}
            initialValues={initialValues}
          />
        );
      case TRANSFER.key:
        return (
          <TransferForm
            role={roleValue}
            filterList={filterList}
            onFinish={onDataEntryFinish}
            isCreating={addTransactionInfo?.isLoading}
            initialValues={initialValues}
          />
        );
      case CONVERSION.key:
        return (
          <ConversionForm
            role={roleValue}
            filterList={filterList}
            onFinish={onDataEntryFinish}
            isCreating={addTransactionInfo?.isLoading}
            initialValues={initialValues}
          />
        );
      case REFUND.key:
        return (
          <RefundForm
            filterList={filterList}
            onFinish={onDataEntryFinish}
            isCreating={addTransactionInfo?.isLoading}
            initialValues={initialValues}
          />
        );
      default:
        return null;
    }
  };

  const getCreatorList = () => {
    if (
      !roleValue ||
      !filterList ||
      !filterList.users ||
      !filterList.users.length
    ) {
      return [];
    }

    return filterList.users.filter(({ role }) => role === roleValue);
  };

  const onRoleChange = () => {
    form.setFieldValue('creatorId', null);
    form.setFieldValue('transactionType', null);
  };

  const getTransactionTypeList = () => {
    const isUser = ![ACCOUNTANT.key, CASHIER.key].includes(roleValue);

    if (!roleValue) {
      return [];
    }

    return TRANSACTION_TYPE_LIST.filter(({ key }) =>
      isUser ? key === REFUND.key : key !== REFUND.key
    );
  };

  const onCreatorChange = value => {
    setCreatorError(!value);
  };

  useEffect(() => {
    if (initialValues) {
      const {
        creatorId,
        creator,
        amount,
        transferred,
        converted,
        refunded
      } = initialValues;

      if (creatorId && creator?.role) {
        form.setFieldsValue({ role: creator?.role });
        form.setFieldsValue({ creatorId });
      }

      if (amount > 0 && !transferred && !converted && !refunded) {
        form.setFieldsValue({ transactionType: INCOME.key });
      }
      if (amount < 0 && !transferred && !converted) {
        form.setFieldsValue({ transactionType: OUTCOME.key });
      }
      if (transferred) {
        form.setFieldsValue({ transactionType: TRANSFER.key });
      }
      if (converted) {
        form.setFieldsValue({ transactionType: CONVERSION.key });
      }
      if (refunded) {
        form.setFieldsValue({ transactionType: REFUND.key });
      }
    }
  }, [initialValues, form]);

  return (
    <>
      <Form
        {...FORM_ITEM_LAYOUT}
        form={form}
        name="transaction-form"
        onFinish={onFinish}
        initialValues={{}}
      >
        <SelectItem
          name="role"
          label="Роль"
          rules={[{ required: true }]}
          placeholder="Выбрать"
          options={ROLE_LIST || []}
          onChange={onRoleChange}
          optionLabel="label"
          optionValue="key"
        />
        <SelectItem
          name="creatorId"
          label="Создатель"
          rules={[{ required: true }]}
          placeholder="Выбрать"
          options={getCreatorList() || []}
          optionLabel="fullName"
          onChange={onCreatorChange}
          hasFeedback
          validateStatus={creatorError ? 'error' : null}
        />
        <SelectItem
          name="transactionType"
          label="Тип"
          rules={[{ required: true }]}
          placeholder="Выбрать"
          options={getTransactionTypeList() || []}
          optionLabel="label"
          optionValue="key"
        />
      </Form>
      {renderForms()}
    </>
  );
};

export default TransactionForm;
