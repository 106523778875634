import { CLEAR_ON_SIGNOUT, REFRESH_ACCESS_TOKEN, SET_AUTH_TOKEN } from '../constants';

const INITIAL_STATE = {
  isAuthenticated: false,
  email: '',
  accessToken: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload
      };
    case REFRESH_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      };
    case CLEAR_ON_SIGNOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
