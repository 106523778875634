import React, { useMemo } from 'react';
import { Button, Form } from 'antd';

import { ACCOUNTANT, CASHIER, FORM_ITEM_LAYOUT } from 'services/constants';
import SelectItem from 'components/FormItems/SelectFormItem';
import api from '../../services/api/api';
import SearchSelectItem from 'components/FormItems/SearchSelectFormItem';
import TextAreaItem from 'components/FormItems/TextAreaFormItem';
import InputNumberFormItem from 'components/FormItems/InputNumberFormItem';
import DatePickerFormItem from 'components/FormItems/DatePickerFormItem';
import { determineDateFormat } from 'utils/helpers';

const { useForm, useWatch } = Form;

const IncomeForm = React.memo(({ role, filterList, initialValues, onFinish, isCreating }) => {
  const { banks, incomeTypes, incomeSources, currencies } = filterList;
  const [form] = useForm();

  const typesIdValue = useWatch('typesId', form);

  const incomeSourcesFiltered = useMemo(() => {
    return typesIdValue ? incomeSources.filter(({ typesId }) => typesId === typesIdValue) : [];
  }, [typesIdValue, incomeSources]);

  const onTypeChange = () => {
    form.setFieldsValue({ sourceId: null });
  };

  const getBanks = () => {
    const isAccountant = role === ACCOUNTANT.key;
    if (!banks?.length) {
      return [];
    }

    return banks.filter(({ corporate }) => (isAccountant ? !corporate : corporate));
  };

  const getInitialValues = () => {
    if (initialValues) {
      const { date } = initialValues;

      return {
        ...initialValues,
        date: determineDateFormat(date)
      };
    }
  };

  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      form={form}
      name="income-form"
      labelAlign="right"
      labelWrap
      onFinish={onFinish}
      initialValues={getInitialValues()}
    >
      <DatePickerFormItem name="date" label="Дата" />
      <SelectItem name="bankId" label="Счет" rules={[{ required: true }]} placeholder="Выбрать" options={getBanks()} />
      <SelectItem
        name="typesId"
        label="Тип прихода"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={incomeTypes || []}
        onChange={onTypeChange}
      />
      <SelectItem
        name="sourceId"
        label="Источник"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={incomeSourcesFiltered}
      />
      <SelectItem
        name="currencyId"
        label="Валюта"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={currencies || []}
      />
      <InputNumberFormItem name="amount" label="Сумма" rules={[{ required: true }]} />
      {role !== CASHIER.key ? (
        <SearchSelectItem
          apiFunction={api.requests.contracts.getContractList}
          queryKey="contractsSearch"
          name="contractId"
          label="Контракт"
          placeholder="Номер контракта"
          optionLabel="contractNumber"
          form={form} // Antd form instance
          rules={[{ required: false, message: 'Please select a contract!' }]} // Validation rules
          onChange={value => form.setFieldsValue({ contractId: value })} // Handle the value change
        />
      ) : null}
      <TextAreaItem
        name="comment"
        label="Комментарии"
        placeholder="Enter your description here"
        rules={[{ required: false, message: 'Description is required' }]}
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
});

export default IncomeForm;
