import React from 'react';
import { Button, Form, Divider, Typography } from 'antd';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import TextAreaItem from 'components/FormItems/TextAreaFormItem';
import InputNumberFormItem from 'components/FormItems/InputNumberFormItem';
import DatePickerFormItem from 'components/FormItems/DatePickerFormItem';
import SelectItem from 'components/FormItems/SelectFormItem';
import { determineDateFormat } from 'utils/helpers';

const { Text } = Typography;
const { useForm, useWatch } = Form;

const ConversionForm = ({ initialValues, role, filterList, onFinish, isCreating }) => {
  const { banks, currencies } = filterList;
  const [form] = useForm();
  const fromBankValue = useWatch('fromBankId', form);
  const toBankValue = useWatch('toBankId', form);
  const fromCurrencyValue = useWatch('fromCurrencyId', form);
  const toCurrencyValue = useWatch('toCurrencyId', form);

  const getFromCurrencies = () => {
    if (!filterList || !filterList.currencies || !filterList.currencies.length) {
      return null;
    }

    const uzs = currencies?.find(({ id }) => id === 1);

    if (fromBankValue === 1) {
      if (toCurrencyValue === 1) {
        return [];
      }
      return [uzs];
    }

    return currencies.filter(({ id }) => id !== toCurrencyValue);
  };

  const getToCurrencies = () => {
    if (!filterList || !filterList.currencies || !filterList.currencies.length) {
      return null;
    }

    const uzs = currencies?.find(({ id }) => id === 1);

    if (toBankValue === 1) {
      if (fromCurrencyValue === 1) {
        return [];
      }
      return [uzs];
    }

    return currencies.filter(({ id }) => id !== fromCurrencyValue);
  };

  const onFromBankChange = value => {
    if (value === 1 && fromCurrencyValue !== 1) {
      form.setFieldsValue({ fromCurrencyId: null });
    }
  };

  const onToBankChange = value => {
    if (value === 1 && toCurrencyValue !== 1) {
      form.setFieldsValue({ toCurrencyId: null });
    }
  };

  const getInitialValues = () => {
    if (initialValues) {
      const { date, amount } = initialValues;

      return {
        ...initialValues,
        date: determineDateFormat(date),
        amount: Math.abs(amount)
      };
    }
  };

  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      form={form}
      name="transfer-form"
      onFinish={onFinish}
      labelAlign="right"
      labelWrap
      initialValues={getInitialValues()}
    >
      <DatePickerFormItem name="date" label="Дата" />
      <Text strong>Со счета</Text>
      <Divider />

      <SelectItem
        name="fromBankId"
        label="Банк"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={banks || []}
        onChange={onFromBankChange}
      />
      <SelectItem
        name="fromCurrencyId"
        label="Валюта"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getFromCurrencies() || []}
      />
      <InputNumberFormItem name="fromAmount" label="Сумма" rules={[{ required: true }]} />
      <Text strong>На счет</Text>
      <Divider />
      <SelectItem
        name="toBankId"
        label="Банк"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={banks || []}
        onChange={onToBankChange}
      />

      <SelectItem
        name="toCurrencyId"
        label="Валюта"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getToCurrencies() || []}
      />
      <InputNumberFormItem name="toAmount" label="Сумма" rules={[{ required: true }]} />
      <InputNumberFormItem name="rate" label="Курс" rules={[{ required: true }]} />
      <TextAreaItem
        name="comment"
        label="Комментарии"
        placeholder="Enter your description here"
        rules={[{ required: false, message: 'Description is required' }]}
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConversionForm;
