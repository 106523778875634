import React from 'react';
import { Form, InputNumber } from 'antd';

const InputNumberFormItem = ({
  name,
  label,
  rules,
  formatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), // Default formatter
  parser = value => value.replace(/\$\s?|(,*)/g, ''), // Default parser to strip out non-numeric characters
  formItemProps,
  inputProps
}) => (
  <Form.Item
    name={name}
    label={label}
    rules={rules}
    {...formItemProps} // spread the rest of the props for further customization
  >
    <InputNumber style={{ width: '100%' }} formatter={formatter} parser={parser} {...inputProps} />
  </Form.Item>
);

export default InputNumberFormItem;
