import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Button, Card, Form, Input, Modal, Row, Select, Space, Switch, Table } from 'antd';
import * as Yup from 'yup';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import KeyOutlined from '@ant-design/icons/lib/icons/KeyOutlined';
import UnlockOutlined from '@ant-design/icons/lib/icons/UnlockOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { useFormik } from 'formik';
import { getNested } from '../../utils/getNested';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { notification } from 'antd';
import CardHeader from 'components/CardHeader';

const roles = [
  {
    key: 'admin',
    title: 'Администратор'
  },
  {
    key: 'accountant',
    title: 'Бухгалтер'
  },
  {
    key: 'cashier',
    title: 'Кассир'
  },
  {
    key: 'user',
    title: 'Закупщик'
  },
  {
    key: 'manager',
    title: 'Менеджер по производству'
  },
  {
    key: 'specialist',
    title: 'Специалист по контракту'
  },
  {
    key: 'former',
    title: 'Оформитель'
  },
  {
    key: 'worker',
    title: 'Сотрудник (Кошелёк)'
  }
];

const UsersPage = () => {
  const [state, setState] = useState({
    addModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null
  });
  // Cache
  const cache = useQueryCache();

  const users = useQuery('users', () => api.requests.users.getUserList());
  const factories = useQuery('factories', () => api.requests.factories.getFactoriesList());

  // Mutations
  const [addUser, addUserInfo] = useMutation(
    state.editingItem ? api.requests.users.updateUser : api.requests.users.createUser,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem ? `Пользователь успешно сохранен` : `Пользователь успешно добавлен`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('users');
      }
    }
  );

  const [deleteCompany, deleteCompanyInfo] = useMutation(api.requests.users.deleteCompany, {
    onSuccess: () => {
      notification.success({
        message: `Закуп успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      formik.resetForm();
      cache.invalidateQueries('users');
    }
  });

  const startEditing = item => {
    formik.values.firstName = item.firstName;
    formik.values.lastName = item.lastName;
    formik.values.middleName = item.middleName;
    formik.values.email = item.email;
    formik.values.role = item.role;
    formik.values.active = item.active;
    formik.values.customId = item.customId;

    if ((formik.values.role = 'manager')) {
      formik.values.factoryId = item.factoryId;
    }
  };

  const clearForm = () => {
    formik.resetForm();
    formik.values.firstName = '';
    formik.values.lastName = '';
    formik.values.middleName = '';
    formik.values.email = '';
    formik.values.role = '';
    formik.values.active = '';
    formik.values.customId = '';
    formik.values.factoryId = '';
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      role: '',
      factoryId: '',
      active: '',
      customId: '',
      password: ''
    },
    validationSchema: Yup.object({
      role: Yup.string().required('Обязательное поле'),
      firstName: Yup.string().required('Обязательное поле'),
      lastName: Yup.string().required('Обязательное поле'),
      middleName: Yup.string().required('Обязательное поле'),
      email: Yup.string().email('Неправильный формат').required('Обязательное поле'),
      factoryId: Yup.string().when('role', {
        is: 'manager',
        then: Yup.string().required('Обязательное поле')
      }),
      customId: Yup.string().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingItem) {
        data = {
          id: state.editingItem.id,
          data: values
        };
      }

      addUser(data);
    }
  });

  const FORM_ITEM_LAYOUT = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 }
    }
  };

  const onClickAdd = () => {
    clearForm();
    setState({
      ...state,
      addModalVisible: true,
      editingItem: null
    });
  };

  return (
    <Spin spinning={users && users.isLoading}>
      <CardHeader title="Пользователи" hasButton onButtonClick={onClickAdd} />
      <Card>
        <Table
          rowKey="id"
          size="middle"
          bordered
          loading={users.isLoading || users.isFetching}
          style={{ overflowX: 'scroll' }}
          columns={[
            { title: 'id', dataIndex: 'id' },
            { title: 'ID пользователя', dataIndex: 'customId' },
            { title: 'Ф.И.О.', dataIndex: 'fullName' },
            { title: 'Имя', dataIndex: 'firstName' },
            { title: 'Фамилия', dataIndex: 'lastName' },
            { title: 'Отчество', dataIndex: 'middleName' },
            { title: 'email', dataIndex: 'email' },
            {
              title: 'Роль',
              dataIndex: 'role',
              render: role => roles.find(r => r.key === role)?.title
            },
            {
              title: 'Завод',
              dataIndex: 'factory',
              render: factory => factory?.name || <CloseOutlined style={{ color: 'red' }} />
            },
            {
              title: 'Активен',
              dataIndex: 'active',
              render: active =>
                active ? <UnlockOutlined style={{ color: 'green' }} /> : <LockOutlined style={{ color: 'red' }} />
            },
            {
              title: 'Действия',
              dataIndex: 'actions',
              render: (_, record) => (
                <Space>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      startEditing(record);
                      setState({
                        ...state,
                        addModalVisible: true,
                        editingItem: record
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<KeyOutlined />}
                    onClick={() => {
                      startEditing(record);
                      setState({
                        ...state,
                        addModalVisible: true,
                        editingItem: record
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={() => {
                      setState({
                        ...state,
                        deleteModalVisible: true,
                        deletingItem: record
                      });
                    }}
                  />
                </Space>
              )
            }
          ]}
          dataSource={getNested(users, 'data', 'data', 'data')}
        />
      </Card>
      <Modal
        title={state.editingItem ? `Изменение пользователя: ${state.editingItem.fullName}` : 'Добавление пользователя'}
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Роль"
            validateStatus={formik.touched.role && formik.errors.role ? 'error' : 'validating'}
            help={formik.touched.role && formik.errors.role}
          >
            <Select
              id="role"
              name="role"
              value={formik.values.role}
              onChange={value => formik.setFieldValue('role', value)}
            >
              {roles.map(({ key, title }, id) => (
                <Select.Option key={id} value={key}>
                  {title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {formik.values.role === 'manager' && (
            <Form.Item
              label="Завод"
              validateStatus={formik.touched.factoryId && formik.errors.factoryId ? 'error' : 'validating'}
              help={formik.touched.factoryId && formik.errors.factoryId}
            >
              <Select
                id="factoryId"
                name="factoryId"
                value={formik.values.factoryId}
                onChange={value => formik.setFieldValue('factoryId', value)}
              >
                {getNested(factories, 'data', 'data', 'data') &&
                  getNested(factories, 'data', 'data', 'data').map((item, id) => {
                    return (
                      <Select.Option value={item.id} key={id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Имя"
            validateStatus={formik.touched.firstName && formik.errors.firstName ? 'error' : 'validating'}
            help={formik.touched.firstName && formik.errors.firstName}
          >
            <Input
              name="firstName"
              placeholder="Имя"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              id="firstName"
            />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            validateStatus={formik.touched.lastName && formik.errors.lastName ? 'error' : 'validating'}
            help={formik.touched.lastName && formik.errors.lastName}
          >
            <Input
              name="lastName"
              placeholder="Фамилия"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              id="lastName"
            />
          </Form.Item>
          <Form.Item
            label="Отчество"
            validateStatus={formik.touched.middleName && formik.errors.middleName ? 'error' : 'validating'}
            help={formik.touched.middleName && formik.errors.middleName}
          >
            <Input
              name="middleName"
              placeholder="Отчество"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              id="middleName"
            />
          </Form.Item>
          <Form.Item
            label="ID"
            validateStatus={formik.touched.customId && formik.errors.customId ? 'error' : 'validating'}
            help={formik.touched.customId && formik.errors.customId}
          >
            <Input
              name="customId"
              placeholder="ID пользователя"
              value={formik.values.customId}
              onChange={formik.handleChange}
              id="customId"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            validateStatus={formik.touched.email && formik.errors.email ? 'error' : 'validating'}
            help={formik.touched.email && formik.errors.email}
          >
            <Input
              name="email"
              placeholder="Наименование"
              value={formik.values.email}
              onChange={formik.handleChange}
              id="email"
            />
          </Form.Item>
          <Form.Item
            label="Пароль"
            validateStatus={formik.touched.password && formik.errors.password ? 'error' : 'validating'}
            help={formik.touched.password && formik.errors.password}
          >
            <Input
              name="password"
              placeholder="Пароль"
              value={formik.values.password}
              onChange={formik.handleChange}
              id="password"
            />
          </Form.Item>
          <Form.Item label="Активен">
            <Switch defaultChecked onChange={value => formik.setFieldValue('active', value)} />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={formik.handleSubmit}
              disabled={addUserInfo && addUserInfo.isLoading}
              loading={addUserInfo && addUserInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление компании: ${state.deletingItem && state.deletingItem.name}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        Вы уверены что хотите удалить компанию <b>{state.deletingItem && state.deletingItem.name}</b>?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteCompany(state.deletingItem.id);
          }}
          disabled={deleteCompanyInfo && deleteCompanyInfo.isLoading}
          loading={deleteCompanyInfo && deleteCompanyInfo.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </Spin>
  );
};

export default UsersPage;
