import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
  Button,
  Card,
  DatePicker,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Statistic,
  Col,
  Divider
} from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import SwapOutlined from '@ant-design/icons/lib/icons/SwapOutlined';
import SwapLeftOutlined from '@ant-design/icons/lib/icons/SwapLeftOutlined';
import SwapRightOutlined from '@ant-design/icons/lib/icons/SwapRightOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { notification } from 'antd';
import { numberWith } from 'utils/numberWith';
import Popover from 'antd/lib/popover';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';
import { getUserFullName } from 'utils/getUserFullName';
import { LOCALE_DATE } from 'services/constants';
import useFilters from 'hooks/useFilters';
import TransactionForm from 'containers/TransactionForm/TransactionForm';
import CardHeader from 'components/CardHeader';

const { Option } = Select;
const { RangePicker } = DatePicker;

const TransactionsPage = () => {
  const [state, setState] = useState({
    contractSearchVal: '',
    addModalVisible: false,
    addNewModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null
  });

  // Cache
  const cache = useQueryCache();
  const queryParams = useQueryParams();
  let history = useHistory();
  const {
    page,
    flow,
    transactionType,
    bankId,
    currencyId,
    incomeTypeId,
    incomeSourceId,
    outcomeTypeId,
    outcomeSourceId,
    contractId,
    creatorId,
    receiverId,
    startDate,
    endDate,
    search
  } = queryParams.values;

  const getFilterFields = () => {
    // TODO: add creator and receiver
    let filterFields = {
      bank: true,
      currency: true,
      flow: true,
      transactionType: true,
      creatorId: true,
      receiverId: true
    };

    if (transactionType === 'income') {
      return {
        ...filterFields,
        incomeType: true,
        incomeSource: true
      };
    }

    if (transactionType === 'outcome') {
      return {
        ...filterFields,
        outcomeType: true,
        outcomeSource: true
      };
    }

    return filterFields;
  };

  const {
    loading: filterLoading,
    list: filterList,
    menuItems: filterMenuItems
  } = useFilters({
    fields: getFilterFields(),
    hasRange: false
  });

  const transactions = useQuery(
    [
      'transactions',
      page,
      flow,
      bankId,
      currencyId,
      incomeTypeId,
      incomeSourceId,
      outcomeTypeId,
      outcomeSourceId,
      contractId,
      creatorId,
      receiverId,
      startDate,
      endDate
    ],
    () =>
      api.requests.transactions.getInTransactionList({
        page: page,
        flow: flow || undefined,
        bankId: bankId || undefined,
        currencyId: currencyId || undefined,
        typesId: incomeTypeId || undefined,
        sourceId: incomeSourceId || undefined,
        otypesId: outcomeTypeId || undefined,
        osourceId: outcomeSourceId || undefined,
        contractId: contractId || undefined,
        creatorId: creatorId || undefined,
        receiverId: receiverId || undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined
      })
  );

  const transactionTotal = useQuery(
    [
      'transactionTotal',
      flow,
      bankId,
      currencyId,
      incomeTypeId,
      incomeSourceId,
      outcomeTypeId,
      outcomeSourceId,
      contractId,
      creatorId,
      receiverId,
      startDate,
      endDate
    ],
    () =>
      api.requests.transactions.getTransactionTotal({
        flow: flow || undefined,
        bankId: bankId || undefined,
        currencyId: currencyId || undefined,
        typesId: incomeTypeId || undefined,
        sourceId: incomeSourceId || undefined,
        otypesId: outcomeTypeId || undefined,
        osourceId: outcomeSourceId || undefined,
        contractId: contractId || undefined,
        creatorId: creatorId || undefined,
        receiverId: receiverId || undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined
      })
  );

  const transactionTotalInfo = transactionTotal?.data?.data;

  const contractList = useQuery(
    ['contracts', state.contractSearchVal],
    () =>
      api.requests.contracts.getContractList({
        search: state.contractSearchVal
      }),
    { enabled: state.contractSearchVal?.length > 2 }
  );
  const contract = useQuery(
    ['contract', contractId],
    () => api.requests.contracts.getSingleContract(contractId),
    {
      enabled: contractId
    }
  );
  const contractsInfo = contractList?.data?.data?.data?.data;
  const contractInfo = contract?.data?.data?.data?.data;

  const users = useQuery('users', () =>
    api.requests.users.getUserList('cashier')
  );
  const usersInfo = users?.data?.data?.data;
  const currentCreator = usersInfo?.find(
    item => item.id === parseInt(creatorId)
  );
  const currentReceiver = usersInfo?.find(
    item => item.id === parseInt(receiverId)
  );

  const [deleteTransaction, deleteTransactionInfo] = useMutation(
    api.requests.transactions.deleteTransaction,
    {
      onSuccess: () => {
        notification.success({
          message: `Транзакция успешно удалён`,
          placement: 'topRight'
        });
        setState({
          ...state,
          deleteModalVisible: false
        });
        cache.invalidateQueries('transactions');
        cache.invalidateQueries('transactionTotal');
      }
    }
  );

  const onRangeChange = value => {
    const isEmpty = value?.length !== 2;
    queryParams.merge({
      startDate: !isEmpty ? moment(value[0]).format('YYYY-MM-DD') : undefined,
      endDate: !isEmpty ? moment(value[1]).format('YYYY-MM-DD') : undefined
    });
  };

  const getTransactionType = ({ converted, transferred, refunded, amount }) => {
    let type = '';

    if (amount > 0) {
      type = 'Приход';
    } else {
      type = 'Расход';
    }

    if (converted) {
      type = 'Конвертация';
    }
    if (transferred) {
      type = 'Перевод';
    }
    if (refunded) {
      type = 'Возврат';
    }

    return type;
  };

  const getExchangedContent = ({
    rate,
    alternativeCurrency,
    alternativeAmount
  }) => {
    return `Курс: ${rate ? numberWith(rate, ',') : '-'}\n
                    Сумма: ${alternativeCurrency?.symbol || ''}${numberWith(
      alternativeAmount > 0 ? alternativeAmount : -alternativeAmount,
      ','
    )}\n
                    Валюта: ${alternativeCurrency?.name || ''}${
      alternativeCurrency?.symbol ? '(' + alternativeCurrency?.symbol + ')' : ''
    }`;
  };

  const getDetailsPopover = (title, content) => {
    return (
      <Popover content={content} title={title} trigger="click">
        <Button type="link" size="medium" style={{ paddingLeft: 0 }}>
          {title}
        </Button>
      </Popover>
    );
  };

  const getDetails = record => {
    const { exchanged, converted, transferred } = record;
    let content = null;

    if (exchanged) {
      content = getDetailsPopover('Обмен валюты', getExchangedContent(record));
    }

    if (converted) {
      content = getDetailsPopover('Конвертация', getExchangedContent(record));
    }

    if (transferred) {
      content = getDetailsPopover('Перевод', getExchangedContent(record));
    }

    // if (refunded) {
    //   type = 'Возврат';
    // }
    // if (refunded) {
    //   type = 'Возврат';
    // }

    return content;
  };

  const onFinishAddingTransaction = () => {
    setState({
      ...state,
      addModalVisible: false,
      addNewModalVisible: false
    });
    cache.invalidateQueries('transactions');
    cache.invalidateQueries('transactionTotal');
  };

  const onClickAdd = () => {
    setState({
      ...state,
      addModalVisible: true,
      editingItem: null
    });
  };

  return (
    <>
      <CardHeader title="Транзакции" hasButton onButtonClick={onClickAdd} />
      <Spin spinning={filterLoading}>{filterMenuItems}</Spin>
      <Card>
        <Space>
          <br />
          <RangePicker
            locale={LOCALE_DATE}
            format="DD-MM-YYYY"
            value={
              startDate && endDate
                ? [
                    moment(startDate, 'YYYY-MM-DD'),
                    moment(endDate, 'YYYY-MM-DD')
                  ]
                : undefined
            }
            onChange={(value, dateString) => onRangeChange(value, dateString)}
          />
          <Select
            showSearch
            allowClear
            value={contract ? contractInfo?.contractNumber : undefined}
            placeholder="Номер контракта"
            style={{ width: 200 }}
            defaultActiveFirstOption={false}
            showArrow
            filterOption={false}
            onSearch={value => setState({ ...state, contractSearchVal: value })}
            onChange={value => {
              return value
                ? queryParams.set('contractId', value)
                : queryParams.reset('contractId');
            }}
            notFoundContent={'Нет результатов'}
          >
            {contractsInfo?.length
              ? contractsInfo.map(contract => (
                  <Option key={contract.id}>{contract.contractNumber}</Option>
                ))
              : null}
          </Select>
        </Space>
      </Card>
      <br />
      {search ? (
        <Space>
          <br />
          Результаты поиска:{' '}
          <Tag
            closable
            onClose={() =>
              queryParams.merge({
                search: undefined,
                page: 1
              })
            }
          >
            {search}
          </Tag>
        </Space>
      ) : null}
      <Row gutter={16}>
        {transactionTotalInfo?.balance ? (
          <Col span={6}>
            <Card title="Общая сумма">
              {transactionTotalInfo?.balance?.length
                ? transactionTotalInfo?.balance.map((child, id) => (
                    <>
                      {id ? <Divider /> : null}
                      <Statistic
                        title={child.currency?.name}
                        value={child.total_amount}
                      />
                    </>
                  ))
                : null}
            </Card>
          </Col>
        ) : null}
      </Row>
      <br />
      <Card>
        Общее количество элементов: <b>{transactions?.data?.data?.count}</b>
        <Table
          loading={transactions?.isLoading || transactions.isFetching}
          rowKey="id"
          size="middle"
          bordered
          style={{ overflowX: 'scroll' }}
          columns={[
            { title: 'ID', dataIndex: 'id' },
            {
              title: 'Дата',
              dataIndex: 'date',
              render: (date, record) => {
                return (
                  <>
                    {date ? moment(date).format('DD.MM.YYYY') : ''} <br />
                  </>
                );
              }
            },
            {
              title: <SwapOutlined />,
              dataIndex: 'flow',
              render: (createdAt, record) =>
                record?.amount > 0 ? (
                  <SwapLeftOutlined style={{ color: 'green' }} />
                ) : (
                  <SwapRightOutlined style={{ color: 'red' }} />
                )
            },
            {
              title: 'Тип',
              dataIndex: 'type',
              render: (bank, record) => {
                return getTransactionType(record);
              }
            },
            { title: 'Банк', dataIndex: 'bank', render: bank => bank?.name },
            {
              title: 'Сумма',
              dataIndex: 'amount',
              render: (amount, record) => {
                return (
                  amount &&
                  `${record?.currency?.symbol || ''}${numberWith(
                    amount > 0 ? amount : -amount,
                    ','
                  )}`
                );
              }
            },
            {
              title: 'Валюта',
              dataIndex: 'currency',
              render: currency =>
                `${currency?.name || ''}${
                  currency?.symbol ? '(' + currency?.symbol + ')' : ''
                }`
            },
            {
              title: 'Детали',
              dataIndex: 'details',
              render: (_, record) => getDetails(record)
            },
            {
              title: 'Тип прихода/расхода',
              dataIndex: 'types',
              render: (types, record) =>
                record?.amount > 0 ? types?.name : record?.otypes?.name
            },
            {
              title: 'Источник/Цель',
              dataIndex: 'source',
              render: (source, record) =>
                record?.amount > 0 ? source?.name : record?.osource?.name
            },
            {
              title: 'Контракт',
              dataIndex: 'contract',
              render: contractItem =>
                contractItem?.contractNumber ? (
                  <Popover
                    content={contractItem?.company?.name}
                    title="Название компании"
                    trigger="hover"
                  >
                    <Button
                      type="link"
                      size="medium"
                      style={{ paddingLeft: 0 }}
                      onClick={() =>
                        history.push(`/contracts/${contractItem.id}`)
                      }
                    >
                      {contractItem?.contractNumber}
                    </Button>
                  </Popover>
                ) : null
            },
            {
              title: 'Получатель',
              dataIndex: 'receiver',
              render: receiver =>
                receiver?.customId ? (
                  <Popover
                    content={getUserFullName(receiver)}
                    title="Ф.И.О"
                    trigger="hover"
                  >
                    <Button
                      type="link"
                      size="medium"
                      style={{ paddingLeft: 0 }}
                    >
                      {receiver?.customId}
                    </Button>
                  </Popover>
                ) : null
            },
            {
              title: 'Создатель',
              dataIndex: 'creator',
              render: creator =>
                creator?.customId ? (
                  <Popover
                    content={getUserFullName(creator)}
                    title="Ф.И.О"
                    trigger="click"
                  >
                    <Button
                      type="link"
                      size="medium"
                      style={{ paddingLeft: 0 }}
                    >
                      {creator?.customId}
                    </Button>
                  </Popover>
                ) : null
            },
            {
              title: 'Описание',
              dataIndex: 'comment',
              render: comment => {
                const longText = comment?.length > 10;
                return comment ? (
                  <Popover content={comment} title="Описание" trigger="hover">
                    {longText ? `${comment?.substring(0, 10)}` : comment}
                    {longText && (
                      <Button type="link" size="medium">
                        ...
                      </Button>
                    )}
                  </Popover>
                ) : null;
              }
            },
            {
              title: 'Создано',
              dataIndex: 'createdAt',
              render: (createdAt, record) => {
                return (
                  <>
                    {createdAt ? moment(createdAt).format('DD.MM.YYYY') : ''}{' '}
                    <br />
                  </>
                );
              }
            },
            {
              title: 'Действия',
              dataIndex: 'actions',
              render: (_, record) => (
                <Space>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setState({
                        ...state,
                        addModalVisible: true,
                        editingItem: record
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={() => {
                      setState({
                        ...state,
                        deleteModalVisible: true,
                        deletingItem: record
                      });
                    }}
                  />
                </Space>
              )
            }
          ]}
          dataSource={transactions?.data?.data?.data}
          pagination={{
            size: 'middle',
            position: ['topRight'],
            total: transactions?.data?.data?.count,
            defaultCurrent: parseInt(transactions?.data?.data?.page) || 1,
            current: parseInt(transactions?.data?.data?.page),
            onChange: page => queryParams.set('page', page)
          }}
        />
      </Card>
      <Modal
        title={`Удаление транзакции: ${state.deletingItem &&
          state.deletingItem.id}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
        }}
        footer={null}
      >
        Вы уверены что хотите удалить транзакцию{' '}
        <b>{state.deletingItem && state.deletingItem.id}</b> ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteTransaction(state.deletingItem.id);
          }}
          disabled={deleteTransactionInfo && deleteTransactionInfo.isLoading}
          loading={deleteTransactionInfo && deleteTransactionInfo.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
      <Modal
        title={
          state.editingItem
            ? `Изменение транзакции: ${state.editingItem.id}`
            : 'Добавление транзакции'
        }
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
        }}
        footer={null}
      >
        <TransactionForm
          filterList={filterList}
          initialValues={state.editingItem}
          onFinish={onFinishAddingTransaction}
        />
      </Modal>
    </>
  );
};

export default TransactionsPage;
