import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';

import useQueryParams from 'hooks/useQueryParams';

const { Title } = Typography;

const DateRangeDisplay = ({ format = 'DD.MM.YYYY', level = 3 }) => {
  const {
    values: { startDate, endDate }
  } = useQueryParams();

  if (!startDate || !endDate) return null;

  const formattedStartDate = moment(startDate).format(format);
  const formattedEndDate = moment(endDate).format(format);

  return (
    <Title level={level}>
      Показаны данные на период с {formattedStartDate} по {formattedEndDate}:
    </Title>
  );
};

export default DateRangeDisplay;
