import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

const SelectFormItem = React.memo(
  ({
    name,
    label,
    rules,
    placeholder,
    options,
    optionLabel = 'name', // Label property of the options
    optionValue = 'id', // Value property of the options
    onChange,
    hasFeedback,
    validateStatus
  }) => (
    <Form.Item name={name} hasFeedback={hasFeedback} validateStatus={validateStatus} label={label} rules={rules}>
      <Select allowClear placeholder={placeholder} onChange={onChange}>
        {options.map(item => (
          <Option key={item[optionValue]} value={item[optionValue]}>
            {item[optionLabel]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
);

export default SelectFormItem;
