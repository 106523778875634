import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Card, DatePicker, Row, Space, Divider, Col, Statistic, Typography } from 'antd';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/ru';
import { localeDate } from 'utils/dateLocale';
import TableOutlined from '@ant-design/icons/lib/icons/TableOutlined';

import api from '../../services/api/api';
import { useHistory } from 'react-router';
import CardHeader from 'components/CardHeader';

const { RangePicker } = DatePicker;
const { Title } = Typography;

const CashboxPage = () => {
  let history = useHistory();
  const [state, setState] = useState({
    bankId: null,
    flow: null,
    hasReceiver: false,
    typeId: null,
    sourceId: null
  });
  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null
  });
  const { startDate, endDate } = dateState;

  const cashboxTotal = useQuery(['cashboxTotal', startDate, endDate], () =>
    api.requests.transactions.getCashboxTotal({ startDate, endDate })
  );
  const cashboxTotalInfo = cashboxTotal?.data?.data;
  const cashboxStats = useQuery(
    ['cashboxStats', state.flow, state.hasReceiver, startDate, endDate],
    () =>
      api.requests.transactions.getCashboxStats({
        flow: state.flow,
        hasReceiver: state.hasReceiver,
        startDate,
        endDate
      }),
    {
      enabled: !!state.flow
    }
  );
  const cashboxStatsInfo = cashboxStats?.data?.data?.data;
  const cashboxStatsByFlowAndType = useQuery(
    ['transactionTotalByBankFlowAndType', state.flow, state.hasReceiver, state.typeId, startDate, endDate],
    () =>
      api.requests.transactions.getCashboxStatsByType(state.typeId, {
        flow: state.flow,
        hasReceiver: state.hasReceiver,
        startDate,
        endDate
      }),
    {
      enabled: !!state.typeId
    }
  );
  const cashboxStatsByFlowAndTypeInfo = cashboxStatsByFlowAndType?.data?.data?.data;

  const onRangeChange = value => {
    const isEmpty = value?.length !== 2;
    setDateState({
      startDate: !isEmpty ? moment(value[0]).format('YYYY-MM-DD') : undefined,
      endDate: !isEmpty ? moment(value[1]).format('YYYY-MM-DD') : undefined
    });
  };

  const formattedStartDate = moment(dateState.startDate).format('DD.MM.YYYY');
  const formattedEndDate = moment(dateState.endDate).format('DD.MM.YYYY');

  return (
    <>
      <div>
        <CardHeader title="Касса" />
        <Divider />
        <Row>
          <Space>
            <RangePicker
              locale={localeDate}
              format="DD-MM-YYYY"
              value={
                dateState.startDate && dateState.endDate
                  ? [moment(dateState.startDate, 'YYYY-MM-DD'), moment(dateState.endDate, 'YYYY-MM-DD')]
                  : undefined
              }
              onChange={(value, dateString) => onRangeChange(value)}
            />
          </Space>
        </Row>
        <Divider />
        {dateState?.startDate && dateState?.endDate ? (
          <Title level={3}>
            Показаны данные на период с {formattedStartDate} по {formattedEndDate}:
          </Title>
        ) : null}
        <Row gutter={8}>
          <Col span={3} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
            <Card
              title="Баланс"
              extra={
                <Button type="link" size="large" style={{ padding: 0 }} onClick={() => history.push(`/transactions`)}>
                  <TableOutlined />
                </Button>
              }
            >
              {cashboxTotalInfo?.balance?.length
                ? cashboxTotalInfo?.balance?.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic title={child.currency?.name} value={child.total_amount} />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
          <Col span={3}>
            <Card
              hoverable
              title="Прямые Расходы"
              extra={
                <Button
                  type="link"
                  size="large"
                  style={{ padding: 0 }}
                  onClick={() => history.push(`/transactions?flow=out`)}
                >
                  <TableOutlined />
                </Button>
              }
              style={{ cursor: 'pointer', borderColor: state.flow === 'out' && !state.hasReceiver && 'blue' }}
              onClick={() => setState({ ...state, flow: 'out', hasReceiver: false, typeId: null, sourceId: null })}
            >
              {cashboxTotalInfo?.outcome?.length
                ? cashboxTotalInfo?.outcome?.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic title={child.currency?.name} value={child.total_amount} />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
          <Col span={3} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
            <Card
              hoverable
              title="Переводено пользователям"
              extra={
                <Button
                  type="link"
                  size="large"
                  style={{ padding: 0 }}
                  onClick={() => history.push(`/transactions?flow=out`)}
                >
                  <TableOutlined />
                </Button>
              }
              style={{ cursor: 'pointer', borderColor: state.flow === 'out' && state.hasReceiver && 'blue' }}
              onClick={() => setState({ ...state, flow: 'out', hasReceiver: true, typeId: null, sourceId: null })}
            >
              {cashboxTotalInfo?.transferredToUsers?.length
                ? cashboxTotalInfo?.transferredToUsers?.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic title={child.currency?.name} value={child.total_amount} />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
          <Col span={3}>
            <Card
              title="Приход/Переводы"
              extra={
                <Button
                  type="link"
                  size="large"
                  style={{ padding: 0 }}
                  onClick={() => history.push(`/transactions?flow=out`)}
                >
                  <TableOutlined />
                </Button>
              }
            >
              {cashboxTotalInfo?.transferredTo?.length
                ? cashboxTotalInfo?.transferredTo?.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic title={child.currency?.name} value={child.total_amount} />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
          <Col span={3}>
            <Card
              title="Конвертация/Получено"
              extra={
                <Button
                  type="link"
                  size="large"
                  style={{ padding: 0 }}
                  onClick={() => history.push(`/transactions?flow=out`)}
                >
                  <TableOutlined />
                </Button>
              }
            >
              {cashboxTotalInfo?.convertedTo?.length
                ? cashboxTotalInfo?.convertedTo?.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic title={child.currency?.name} value={child.total_amount} />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
          <Col span={3}>
            <Card
              title="Возврат"
              extra={
                <Button
                  type="link"
                  size="large"
                  style={{ padding: 0 }}
                  onClick={() => history.push(`/transactions?flow=out`)}
                >
                  <TableOutlined />
                </Button>
              }
            >
              {cashboxTotalInfo?.refund?.length
                ? cashboxTotalInfo?.refund?.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic title={child.currency?.name} value={child.total_amount} />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
        </Row>
        <Divider />
        {cashboxStatsInfo?.length && (
          <>
            <Row gutter={8}>
              {' '}
              {cashboxStatsInfo.map((type, index) => {
                const isSelected = type.id === state.typeId;
                return (
                  <Col span={4} key={index}>
                    <Card
                      hoverable
                      title={type.name}
                      extra={
                        <Button
                          type="link"
                          size="large"
                          style={{ padding: 0 }}
                          onClick={() => history.push(`/transactions?flow=out`)}
                        >
                          <TableOutlined />
                        </Button>
                      }
                      style={{ cursor: 'pointer', borderColor: isSelected && 'blue' }}
                      onClick={() => setState({ ...state, typeId: type.id, sourceId: null })}
                    >
                      {type.children?.length
                        ? type.children.map((child, id) => (
                            <Fragment key={id}>
                              {id ? <Divider /> : null}
                              <Statistic title={child.currency?.name} value={child.total_amount} />
                            </Fragment>
                          ))
                        : null}
                    </Card>
                  </Col>
                );
              })}{' '}
            </Row>
            <Divider />
          </>
        )}
        {state.typeId && cashboxStatsByFlowAndTypeInfo?.length && (
          <>
            <Row gutter={8}>
              {cashboxStatsByFlowAndTypeInfo.map((source, index) => {
                return (
                  <Col span={4} key={index}>
                    <Card
                      title={source.name}
                      extra={
                        <Button
                          type="link"
                          size="large"
                          style={{ padding: 0 }}
                          onClick={() =>
                            history.push(`/transactions?bankId=1&typesId=${state.typeId}&sourceId=${state.sourceId}`)
                          }
                        >
                          <TableOutlined />
                        </Button>
                      }
                    >
                      {source.children?.length
                        ? source.children.map((child, id) => (
                            <Fragment key={id}>
                              {id ? <Divider /> : null}
                              <Statistic title={child.currency?.name} value={child.total_amount} />
                            </Fragment>
                          ))
                        : null}
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Divider />
          </>
        )}
      </div>
    </>
  );
};

export default CashboxPage;
