import { CLEAR_ON_SIGNOUT } from 'services/constants';
import AuthReducer from './AuthReducer';

const rootReducer = (state = {}, action) => {
  if (action.type === CLEAR_ON_SIGNOUT) {
    return { auth: AuthReducer(undefined, action) }; // Resets the auth state
  }

  return {
    auth: AuthReducer(state.auth, action)
  };
};

export default rootReducer;
