export const renderStatus = (status) => {
    switch (status) {
        case "sent":
            return "в пути"
        case "store":
            return "получено"
        case "purchased":
            return "куплено"
        case "produced":
            return "переработано"
        case "shipped":
            return "отгружено"
        case "resold":
            return "перепродажа"
        default:
            return status
    }
}
