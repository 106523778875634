import React from 'react';
import { Card, Row, Button } from 'antd';

const CardHeader = ({ title, hasButton = false, buttonLabel = 'Добавить', onButtonClick }) => {
  return (
    <Card>
      <Row justify="space-between" className="news-table-row">
        <h2 style={{ margin: 0 }}>{title}</h2>
        {hasButton && onButtonClick && (
          <div>
            <Button onClick={onButtonClick} type="primary">
              {buttonLabel}
            </Button>
          </div>
        )}
      </Row>
    </Card>
  );
};

export default CardHeader;
