import React, { Fragment, useState } from 'react';
import { Skeleton, Card, Statistic, Row, Col, Button, Divider } from 'antd';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import api from 'services/api/api';
import TableOutlined from '@ant-design/icons/lib/icons/TableOutlined';
import DateRangeDisplay from 'components/DateRangeDisplay';
import CardHeader from 'components/CardHeader';
import DateRangePicker from 'components/DateRangePicker';

const BanksPage = () => {
  let history = useHistory();
  const [state, setState] = useState({
    bankId: null,
    flow: null,
    typeId: null,
    sourceId: null
  });
  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null
  });
  const { bankId } = state;
  const { startDate, endDate } = dateState;

  const transactionTotal = useQuery(
    ['transactionTotal', startDate, endDate],
    () => api.requests.transactions.getTransactionTotal({ startDate, endDate })
  );
  const transactionTotalInfo = transactionTotal?.data?.data;
  const stats = useQuery(['transactionStats', startDate, endDate], () =>
    api.requests.transactions.getTransactionStats({ startDate, endDate })
  );
  const statsInfo = stats?.data?.data?.data;
  const statsByBank = useQuery(
    ['transactionTotalByBank', bankId, startDate, endDate],
    () =>
      api.requests.transactions.getTransactionTotal({
        bankId,
        startDate,
        endDate
      })
  );
  const statsByBankInfo = statsByBank?.data?.data;
  const statsByBankFlow = useQuery(
    ['transactionTotalByBankFlow', bankId, state.flow, startDate, endDate],
    () =>
      api.requests.transactions.getTransactionStatsByBank(bankId, {
        flow: state.flow,
        startDate,
        endDate
      }),
    {
      enabled: !!state.flow
    }
  );
  const statsByBankFlowInfo = statsByBankFlow?.data?.data?.data;
  const statsByBankFlowAndType = useQuery(
    [
      'transactionTotalByBankFlowAndType',
      bankId,
      state.flow,
      state.typeId,
      startDate,
      endDate
    ],
    () =>
      api.requests.transactions.getTransactionStatsByBankAndType(
        bankId,
        state.typeId,
        {
          flow: state.flow,
          startDate,
          endDate
        }
      ),
    {
      enabled: !!state.typeId
    }
  );
  const statsByBankFlowAndTypeInfo = statsByBankFlowAndType?.data?.data?.data;

  return (
    <div>
      <CardHeader title="Счета" />
      <Divider />
      <DateRangePicker />
      <Divider />
      <DateRangeDisplay />
      {stats?.isLoading ? <Skeleton /> : null}
      {statsInfo?.length ? (
        <Row gutter={8}>
          <Col span={3}>
            <Card
              hoverable
              style={{ cursor: 'pointer', borderColor: !bankId && 'blue' }}
              title="Все счета"
              extra={
                <Button
                  type="link"
                  size="large"
                  style={{ padding: 0 }}
                  onClick={() => history.push(`/transactions`)}
                >
                  <TableOutlined />
                </Button>
              }
              onClick={() =>
                setState({
                  bankId: null,
                  flow: null,
                  typeId: null,
                  sourceId: null
                })
              }
            >
              {transactionTotalInfo?.balance?.length
                ? transactionTotalInfo?.balance.map((child, id) => (
                    <Fragment key={id}>
                      {id ? <Divider /> : null}
                      <Statistic
                        title={child.currency?.name}
                        value={child.total_amount}
                      />
                    </Fragment>
                  ))
                : null}
            </Card>
          </Col>
          {statsInfo.map((bank, index) => {
            const isSelected = bank.id === bankId;
            return (
              <Col span={3} key={index}>
                <Card
                  hoverable
                  style={{
                    cursor: 'pointer',
                    borderColor: isSelected && 'blue'
                  }}
                  title={bank.name}
                  extra={
                    <Button
                      type="link"
                      size="large"
                      style={{ padding: 0 }}
                      onClick={() =>
                        history.push(`/transactions?bankId=${bank.id}`)
                      }
                    >
                      <TableOutlined />
                    </Button>
                  }
                  onClick={() =>
                    setState({
                      ...state,
                      bankId: bank.id,
                      flow: null,
                      typeId: null,
                      sourceId: null
                    })
                  }
                >
                  {bank.children?.length
                    ? bank.children.map((child, id) => (
                        <Fragment key={id}>
                          {id ? <Divider /> : null}
                          <Statistic
                            title={child.currency?.name}
                            value={child.total_amount}
                          />
                        </Fragment>
                      ))
                    : null}
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : null}
      {statsByBank?.isLoading || statsByBank?.isFetching ? <Skeleton /> : null}
      {statsByBankInfo && !statsByBank?.isFetching ? (
        <>
          <Divider />
          <Row gutter={8}>
            <Col span={3}>
              <Card
                hoverable
                title="Приход"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?bankId=${
                          state.bankId
                        }&flow=in&transactionType=income`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
                style={{
                  cursor: 'pointer',
                  borderColor: state.flow === 'in' && 'blue'
                }}
                onClick={() =>
                  setState({
                    ...state,
                    flow: 'in',
                    typeId: null,
                    sourceId: null
                  })
                }
              >
                {statsByBankInfo?.income?.length
                  ? statsByBankInfo?.income?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col
              span={3}
              style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
            >
              <Card
                hoverable
                title="Расход"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?bankId=${
                          state.bankId
                        }&flow=out&transactionType=outcome`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
                style={{
                  cursor: 'pointer',
                  borderColor: state.flow === 'out' && 'blue'
                }}
                onClick={() =>
                  setState({
                    ...state,
                    flow: 'out',
                    typeId: null,
                    sourceId: null
                  })
                }
              >
                {statsByBankInfo?.outcome?.length
                  ? statsByBankInfo?.outcome?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col span={3}>
              <Card
                title="Переводы/Отправлено"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?bankId=${
                          state.bankId
                        }&flow=out&transactionType=transferred`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
              >
                {statsByBankInfo?.transferredFrom?.length
                  ? statsByBankInfo?.transferredFrom?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col span={3}>
              <Card
                title="Переводы/Получено"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?bankId=${
                          state.bankId
                        }&flow=in&transactionType=transferred`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
              >
                {statsByBankInfo?.transferredTo?.length
                  ? statsByBankInfo?.transferredTo?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col span={3}>
              <Card
                title="Конвертация/Отправлено"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?bankId=${
                          state.bankId
                        }&flow=out&transactionType=converted`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
              >
                {statsByBankInfo?.convertedFrom?.length
                  ? statsByBankInfo?.convertedFrom?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col
              span={3}
              style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
            >
              <Card
                title="Конвертация/Получено"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?bankId=${
                          state.bankId
                        }&flow=in&transactionType=converted`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
              >
                {statsByBankInfo?.convertedTo?.length
                  ? statsByBankInfo?.convertedTo?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col span={3}>
              <Card
                title="Переводы на кассу"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?flow=out&transactionType=transferred&bankId=1`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
              >
                {statsByBankInfo?.transferredToCashbox?.length
                  ? statsByBankInfo?.transferredToCashbox?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={
                            child.total_amount ? -1 * child.total_amount : 0
                          }
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
            <Col span={3}>
              <Card
                title="Конвертация на кассу"
                extra={
                  <Button
                    type="link"
                    size="large"
                    style={{ padding: 0 }}
                    onClick={() =>
                      history.push(
                        `/transactions?flow=out&transactionType=converted&bankId=1`
                      )
                    }
                  >
                    <TableOutlined />
                  </Button>
                }
              >
                {statsByBankInfo?.convertedToCashbox?.length
                  ? statsByBankInfo?.convertedToCashbox?.map((child, id) => (
                      <Fragment key={id}>
                        {id ? <Divider /> : null}
                        <Statistic
                          title={child.currency?.name}
                          value={child.total_amount}
                        />
                      </Fragment>
                    ))
                  : null}
              </Card>
            </Col>
          </Row>
        </>
      ) : null}

      {statsByBankFlow?.isLoading ? <Skeleton /> : null}
      {statsByBankFlowInfo?.length ? (
        <>
          <Divider />
          <Row gutter={8}>
            {statsByBankFlowInfo.map((type, index) => {
              const isSelected = type.id === state.typeId;
              return (
                <Col span={3} key={index}>
                  <Card
                    hoverable
                    title={type.name}
                    extra={
                      <Button
                        type="link"
                        size="large"
                        style={{ padding: 0 }}
                        onClick={() =>
                          history.push(
                            `/transactions?bankId=${state.bankId}&flow=${
                              state.flow
                            }&${
                              state.flow === 'in'
                                ? `incomeTypeId=${type.id}`
                                : `outcomeTypeId=${type.id}`
                            }`
                          )
                        }
                      >
                        <TableOutlined />
                      </Button>
                    }
                    style={{
                      cursor: 'pointer',
                      borderColor: isSelected && 'blue'
                    }}
                    onClick={() =>
                      setState({
                        ...state,
                        typeId: type.id,
                        sourceId: null
                      })
                    }
                  >
                    {type.children?.length
                      ? type.children.map((child, id) => (
                          <Fragment key={id}>
                            {id ? <Divider /> : null}
                            <Statistic
                              title={child.currency?.name}
                              value={child.total_amount}
                            />
                          </Fragment>
                        ))
                      : null}
                  </Card>
                </Col>
              );
            })}{' '}
          </Row>
          <Divider />
        </>
      ) : null}

      {state.typeId && statsByBankFlowAndType?.isLoading ? <Skeleton /> : null}
      {state.typeId && statsByBankFlowAndTypeInfo?.length ? (
        <>
          <Row gutter={8}>
            {statsByBankFlowAndTypeInfo.map((source, index) => {
              return (
                <Col span={3} key={index}>
                  <Card
                    title={source.name}
                    extra={
                      <Button
                        type="link"
                        size="large"
                        style={{ padding: 0 }}
                        onClick={() =>
                          history.push(
                            `/transactions?bankId=${bankId}&typesId=${
                              state.typeId
                            }&sourceId=${state.sourceId}`
                          )
                        }
                      >
                        <TableOutlined />
                      </Button>
                    }
                  >
                    {source.children?.length
                      ? source.children.map((child, id) => (
                          <Fragment key={id}>
                            {id ? <Divider /> : null}
                            <Statistic
                              title={child.currency?.name}
                              value={child.total_amount}
                            />
                          </Fragment>
                        ))
                      : null}
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Divider />
        </>
      ) : null}
    </div>
  );
};

export default BanksPage;
