import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

ReactDOM.render(
  <ReactQueryCacheProvider queryCache={queryCache}>
    <App />
  </ReactQueryCacheProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
