import React, { Component } from 'react';
import { Typography, Space } from 'antd';

const { Title, Text } = Typography;

class PrivacyPolicyPage extends Component {
  render() {
    return (
      <Space direction="vertical">
        <Title>Privacy Policy</Title>

        <Text>Last Updated: 23 May 2024</Text>

        <Title>Introduction</Title>
        <Text>
          Welcome to BMP! We value your privacy and are committed to protecting
          your personal information. This Privacy Policy explains how we
          collect, use, and disclose your information when you use our website
          and mobile application (collectively, the "Service"). By using the
          Service, you agree to the collection and use of information in
          accordance with this policy.
        </Text>

        <Title>1. Information We Collect</Title>
        <Text>
          We collect various types of information to provide and improve our
          Service to you. This includes:
        </Text>
        <Text>
          - Personal Information: When you create an account, we may collect
          personal information such as your name, email address, phone number,
          and company details.
        </Text>
        <Text>
          - Usage Data: We may collect information on how the Service is
          accessed and used. This may include your device's Internet Protocol
          (IP) address, browser type, browser version, the pages of our Service
          that you visit, the time and date of your visit, the time spent on
          those pages, and other diagnostic data.
        </Text>
        <Text>
          - Cookies and Tracking Technologies: We use cookies and similar
          tracking technologies to track activity on our Service and hold
          certain information. You can instruct your browser to refuse all
          cookies or to indicate when a cookie is being sent.
        </Text>

        <Title>2. How We Use Your Information</Title>
        <Text>
          We use the collected information for various purposes, including:
        </Text>
        <Text>- To provide and maintain our Service</Text>
        <Text>- To notify you about changes to our Service</Text>
        <Text>
          - To allow you to participate in interactive features of our Service
          when you choose to do so
        </Text>
        <Text>- To provide customer support</Text>
        <Text>
          - To gather analysis or valuable information so that we can improve
          our Service
        </Text>
        <Text>- To monitor the usage of our Service</Text>
        <Text>- To detect, prevent, and address technical issues</Text>
        <Text>
          - To provide you with news, special offers, and general information
          about other goods, services, and events which we offer unless you have
          opted not to receive such information
        </Text>

        <Title>3. Sharing Your Information</Title>
        <Text>
          We do not share your personal information with third parties except as
          described in this Privacy Policy. We may share your information with:
        </Text>
        <Text>
          - Service Providers: We may employ third-party companies and
          individuals to facilitate our Service, provide the Service on our
          behalf, perform Service-related services, or assist us in analyzing
          how our Service is used.
        </Text>
        <Text>
          - Legal Requirements: We may disclose your personal information if
          required to do so by law or in response to valid requests by public
          authorities (e.g., a court or a government agency).
        </Text>

        <Title>4. Security of Your Information</Title>
        <Text>
          The security of your information is important to us. While we strive
          to use commercially acceptable means to protect your personal
          information, we cannot guarantee its absolute security.
        </Text>

        <Title>5. Your Data Protection Rights</Title>
        <Text>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </Text>
        <Text>
          - The right to access – You have the right to request copies of your
          personal information.
        </Text>
        <Text>
          - The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate or complete
          information you believe is incomplete.
        </Text>
        <Text>
          - The right to erasure – You have the right to request that we erase
          your personal information, under certain conditions.
        </Text>
        <Text>
          - The right to restrict processing – You have the right to request
          that we restrict the processing of your personal information, under
          certain conditions.
        </Text>
        <Text>
          - The right to object to processing – You have the right to object to
          our processing of your personal information, under certain conditions.
        </Text>
        <Text>
          - The right to data portability – You have the right to request that
          we transfer the data that we have collected to another organization,
          or directly to you, under certain conditions.
        </Text>

        <Title>6. Changes to This Privacy Policy</Title>
        <Text>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </Text>

        <Title>7. Contact Us</Title>
        <Text>
          If you have any questions about this Privacy Policy, please contact us
          at group.decoders@gmail.com
        </Text>

        <Text>
          By using the Service, you acknowledge that you have read and
          understood this Privacy Policy and agree to be bound by its terms.
        </Text>
      </Space>
    );
  }
}

export default PrivacyPolicyPage;
