import React, { useMemo } from 'react';
import { Button, Form } from 'antd';
import { ACCOUNTANT, FORM_ITEM_LAYOUT } from 'services/constants';
import SelectItem from 'components/FormItems/SelectFormItem';
import TextAreaItem from 'components/FormItems/TextAreaFormItem';
import InputNumberFormItem from 'components/FormItems/InputNumberFormItem';
import DatePickerFormItem from 'components/FormItems/DatePickerFormItem';
import { determineDateFormat } from 'utils/helpers';

const { useForm, useWatch } = Form;

const OutcomeForm = React.memo(({ initialValues, role, filterList, onFinish, isCreating }) => {
  const { banks, outcomeTypes, outcomeSources, currencies } = filterList;
  const [form] = useForm();

  const otypesIdValue = useWatch('otypesId', form);
  const currencyIdValue = useWatch('currencyId', form);
  const bankIdValue = useWatch('bankId', form);
  const receiverValue = useWatch('receiverId', form);

  const outcomeSourcesFiltered = useMemo(() => {
    return otypesIdValue ? outcomeSources.filter(({ otypesId }) => otypesId === otypesIdValue) : [];
  }, [otypesIdValue, outcomeSources]);

  const onTypeChange = () => {
    form.setFieldsValue({ sourceId: null });
  };

  const onReceiverChange = value => {
    if (value) {
      if (currencyIdValue !== 1) {
        form.setFieldValue('currencyId', null);
      }

      if (bankIdValue !== 1) {
        form.setFieldValue('bankId', null);
      }
    }
  };

  const getReceiverList = () => {
    // Check if filterList exists and it has a 'users' array with at least one user
    if (!filterList || !filterList.users || !filterList.users.length) {
      return null;
    }

    // Filter out the users with specified roles
    return filterList.users.filter(({ role }) => !['admin', 'accountant', 'cashier'].includes(role));
  };

  const getCurrencies = () => {
    const uzs = currencies?.find(({ id }) => id === 1);

    if (uzs && (bankIdValue === 1 || receiverValue)) {
      return [uzs];
    }
    return currencies;
  };

  const getBanks = () => {
    const isAccountant = role === ACCOUNTANT.key;
    if (!banks?.length) {
      return [];
    }

    return banks.filter(({ corporate }) => (isAccountant ? !corporate : corporate));
  };

  const getInitialValues = () => {
    if (initialValues) {
      const { date, amount } = initialValues;

      return {
        ...initialValues,
        date: determineDateFormat(date),
        amount: Math.abs(amount)
      };
    }
  };

  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      form={form}
      name="outcome-form"
      labelAlign="right"
      labelWrap
      onFinish={onFinish}
      initialValues={getInitialValues()}
    >
      <DatePickerFormItem name="date" label="Дата" />
      <SelectItem
        name="bankId"
        label="Банк"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getBanks() || []}
      />
      <SelectItem
        name="otypesId"
        label="Тип расхода"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={outcomeTypes || []}
        onChange={onTypeChange}
      />
      <SelectItem
        name="osourceId"
        label="Источник"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={outcomeSourcesFiltered}
      />
      <SelectItem
        name="currencyId"
        label="Валюта"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getCurrencies() || []}
      />
      <InputNumberFormItem name="amount" label="Сумма" rules={[{ required: true }]} />
      {role !== ACCOUNTANT.key ? (
        <SelectItem
          name="receiverId"
          label="Получатель"
          placeholder="Выбрать"
          options={getReceiverList() || []}
          onChange={onReceiverChange}
          optionLabel="fullName"
        />
      ) : null}
      <TextAreaItem
        name="comment"
        label="Комментарии"
        placeholder="Enter your description here"
        rules={[{ required: false, message: 'Description is required' }]}
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
});

export default OutcomeForm;
