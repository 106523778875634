import React from 'react';
import { Link } from 'react-router-dom';

const withLink = path => {
  return children => {
    if (path) {
      return <Link to={path}>{children}</Link>;
    }
    return children;
  };
};

export default withLink;
