import React from 'react';
import { Button, Space, Table, Tabs } from 'antd';
import PlusSquareOutlined from '@ant-design/icons/lib/icons/PlusSquareOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';

const FactorySection = ({ expandedRowRender, factories, setState, state, startEditing }) => {
  return (
    <Table
      rowKey="id"
      size="middle"
      bordered
      expandable={{ expandedRowRender }}
      loading={factories.isLoading || factories.isFetching}
      style={{ overflowX: 'scroll' }}
      columns={[
        { title: 'id', dataIndex: 'id' },
        { title: 'Наименование', dataIndex: 'name' },
        {
          title: 'Действия',
          dataIndex: 'actions',
          render: (_, record) => (
            <Space>
              <Button
                type="link"
                icon={<PlusSquareOutlined />}
                onClick={() => {
                  setState({
                    ...state,
                    addModalVisible: true,
                    parent: record,
                    childSection: 'stores'
                  });
                }}
              />
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  startEditing(record);
                  setState({
                    ...state,
                    addModalVisible: true,
                    editingItem: record,
                    childSection: null
                  });
                }}
              />
              <Button
                type="link"
                icon={<DeleteOutlined style={{ color: 'red' }} />}
                onClick={() => {
                  setState({
                    ...state,
                    deleteModalVisible: true,
                    deletingItem: record,
                    childSection: null
                  });
                }}
              />
            </Space>
          )
        }
      ]}
      dataSource={factories?.data?.data?.data}
    />
  );
};

export default FactorySection;
