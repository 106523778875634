import React from 'react';
import { Form, Button, Input, Space } from 'antd';
import { useMutation } from 'react-query';

import api from 'services/api/api';
import { showNotification } from 'utils/showNotification';
import InputFormItem from 'components/FormItems/InputFormItem';
import { useStore } from 'services/store';
import { setAuthToken } from 'services/actions';

const LoginForm = () => {
  const { dispatch } = useStore();

  const [login, loginInfo] = useMutation(api.requests.auth.login, {
    onSuccess: ({ data }) => {
      dispatch(setAuthToken(data?.token));
    },
    onError: () => {
      showNotification('error', 'Ошибка при входе');
    }
  });

  const onFinish = values => {
    login(values);
  };

  return (
    <Form
      name="login-form"
      layout="vertical"
      style={{
        width: 400
      }}
      initialValues={{
        email: 'admin@mail.ru',
        password: '12345'
      }}
      onFinish={onFinish}
    >
      <InputFormItem label="Логин" name="email" rules={[{ required: true, message: 'Please input your email!' }]} />
      <Form.Item
        label="Пароль"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!'
          }
        ]}
      >
        <Input.Password />
      </Form.Item>
      <br />
      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loginInfo?.isLoading} disabled={loginInfo?.isLoading}>
          Войти
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
