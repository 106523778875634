import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Col,
  Statistic,
  Typography
} from 'antd';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/ru';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { useFormik } from 'formik';
import { getNested } from 'utils/getNested';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { notification } from 'antd';
import { numberWith } from 'utils/numberWith';
import Popover from 'antd/lib/popover';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';
import { getUserFullName } from 'utils/getUserFullName';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import CardHeader from 'components/CardHeader';

const { Title } = Typography;

const ExpensesPage = () => {
  const [state, setState] = useState({
    contractSearchVal: '',
    addModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null
  });
  // Cache
  const cache = useQueryCache();
  const queryParams = useQueryParams();
  let history = useHistory();

  const spendings = useQuery(
    [
      'spendings',
      queryParams.values.page,
      queryParams.values.creator,
      queryParams.values.receiver,
      queryParams.values.startDate,
      queryParams.values.endDate
    ],
    () =>
      api.requests.spendings.getSpendingList({
        page: queryParams.values.page,
        creatorId: queryParams.values.creator || undefined,
        receiverId: queryParams.values.receiver || undefined,
        startDate: queryParams.values.startDate || undefined,
        endDate: queryParams.values.endDate || undefined
      })
  );
  const spendingSources = useQuery('spendingSources', api.requests.spendings.spendingSources.getSpendingSourceList);
  const users = useQuery('users', () => api.requests.users.getUserList());
  const usersInfo = users?.data?.data?.data;
  // const currentCreator = usersInfo?.find(item => item.id === parseInt(queryParams.values.creator));
  // const currentReceiver = usersInfo?.find(item => item.id === parseInt(queryParams.values.receiver));

  const spendingStats = useQuery('spendingStats', api.requests.spendings.getSpendingStats);
  const spendingStatsInfo = spendingStats?.data?.data?.data;

  // Mutations
  const [addSpending, addSpendingInfo] = useMutation(
    state.editingItem ? api.requests.spendings.updateSpending : api.requests.spendings.createSpending,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem
            ? `Расход успешно сохранён`
            : `Расход успешно добавлен: ${getNested(mes, 'data', 'data', 'data', 'material', 'id')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('spendings');
        cache.invalidateQueries('spendingStats');
      }
    }
  );

  const [deleteSpending, deleteSpendingInfo] = useMutation(api.requests.spendings.deleteSpending, {
    onSuccess: () => {
      notification.success({
        message: `Расход успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      formik.resetForm();
      cache.invalidateQueries('spendings');
      cache.invalidateQueries('spendingStats');
    }
  });

  const startEditing = item => {
    formik.values.spendingSourceId = item.spendingSourceId;
    formik.values.amount = item.amount;
    formik.values.date = item.date;
    formik.values.creatorId = item.creatorId;
  };

  const clearForm = () => {
    formik.resetForm();
    formik.values.spendingSourceId = '';
    formik.values.amount = '';
    formik.values.date = '';
    formik.values.creatorId = '';
  };

  const formik = useFormik({
    initialValues: {
      spendingSourceId: '',
      amount: '',
      date: '',
      creatorId: ''
    },
    validationSchema: Yup.object({
      spendingSourceId: Yup.number().required('Обязательное поле'),
      amount: Yup.number().required('Обязательное поле'),
      creatorId: Yup.number().required('Обязательное поле')
    }),
    onSubmit: values => {
      const { date, amount, creatorId, spendingSourceId } = values;

      let data = {
        creatorId: creatorId || undefined,
        spendingSourceId: spendingSourceId || undefined,
        amount,
        date: date || new Date()
      };

      if (state.editingItem) {
        data = {
          id: state.editingItem.id,
          data: values
        };
      }
      console.log('values => ', values);
      addSpending(data);
    }
  });

  const onClickAdd = () => {
    clearForm();
    setState({
      ...state,
      addModalVisible: true,
      editingItem: null
    });
  };

  return (
    <>
      <Spin spinning={spendings && spendings.isLoading}>
        <CardHeader title="Расходы пользователей" hasButton onButtonClick={onClickAdd} />
        {queryParams.values.search ? (
          <Space>
            <br />
            Результаты поиска:{' '}
            <Tag
              closable
              onClose={() =>
                queryParams.merge({
                  search: undefined,
                  page: 1
                })
              }
            >
              {queryParams.values.search}
            </Tag>
          </Space>
        ) : null}
        <br />
        <Row gutter={16}>
          {spendingStatsInfo?.map((item, index) => {
            return (
              <Col span={4}>
                <Card>
                  <Title level={2} style={{ marginBottom: '5px' }}>
                    {item.name}
                  </Title>
                  <Statistic value={item.total_amount} />
                </Card>
              </Col>
            );
          })}
        </Row>
        <br />
        <Card>
          <Table
            rowKey="id"
            size="middle"
            bordered
            style={{ overflowX: 'scroll' }}
            columns={[
              { title: 'id', dataIndex: 'id' },
              {
                title: 'Дата',
                dataIndex: 'createdAt',
                render: (createdAt, record) => {
                  return (
                    <Fragment>
                      {createdAt ? moment(createdAt).format('DD.MM.YYYY') : ''} <br />
                    </Fragment>
                  );
                }
              },
              { title: 'Сумма', dataIndex: 'amount', render: amount => amount && numberWith(amount, ',') },
              { title: 'Цель', dataIndex: 'spendingSource', render: spendingSource => spendingSource.name },
              {
                title: 'Категория',
                dataIndex: 'spendingType',
                render: spendingType => spendingType?.name
              },
              {
                title: 'Комментарии',
                dataIndex: 'comment',
                render: comment => {
                  return comment ? (
                    <Popover content={comment} title="Комментарии" trigger="click">
                      {comment.substring(0, 10)}...
                      <Button type="link" size="medium">
                        показать
                      </Button>
                    </Popover>
                  ) : (
                    '-'
                  );
                }
              },
              {
                title: 'Создано',
                dataIndex: 'createdAt',
                render: (createdAt, record) => {
                  return (
                    <Fragment>
                      {createdAt ? moment(createdAt).format('DD.MM.YYYY') : ''} <br />
                    </Fragment>
                  );
                }
              },
              {
                title: 'Пользователь',
                dataIndex: 'creator',
                render: creator =>
                  creator?.customId ? (
                    <Popover content={getUserFullName(creator)} title="Ф.И.О" trigger="hover">
                      <Button
                        type="link"
                        size="medium"
                        style={{ paddingLeft: 0 }}
                        onClick={() => history.push(`/users/${creator?.id}`)}
                      >
                        {creator?.customId}
                      </Button>
                    </Popover>
                  ) : (
                    '-'
                  )
              },
              {
                title: 'Действия',
                dataIndex: 'actions',
                render: (_, record) => (
                  <Space>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        startEditing(record);
                        setState({
                          ...state,
                          addModalVisible: true,
                          editingItem: record
                        });
                      }}
                    />
                    <Button
                      type="link"
                      icon={<DeleteOutlined style={{ color: 'red' }} />}
                      onClick={() => {
                        setState({
                          ...state,
                          deleteModalVisible: true,
                          deletingItem: record
                        });
                      }}
                    />
                  </Space>
                )
              }
            ]}
            dataSource={spendings?.data?.data?.data}
            pagination={{
              size: 'middle',
              position: ['topRight'],
              total: spendings?.data?.data?.count,
              defaultCurrent: parseInt(spendings?.data?.data?.page) || 1,
              current: parseInt(spendings?.data?.data?.page),
              onChange: page => queryParams.set('page', page)
            }}
          />
        </Card>
        <Modal
          title={state.editingItem ? `Изменение расхода: ${state.editingItem.id}` : 'Добавление расхода'}
          open={state.addModalVisible}
          maskClosable={false}
          destroyOnClose
          onCancel={() => {
            setState({
              ...state,
              addModalVisible: false,
              editingItem: null
            });
            clearForm();
          }}
          footer={null}
        >
          <Form {...FORM_ITEM_LAYOUT}>
            <Form.Item label="Дата">
              <DatePicker
                value={moment(formik.values.date || new Date())}
                onChange={(obj, datestring) => formik.setFieldValue('date', datestring)}
              />
            </Form.Item>
            <Form.Item
              label="Создатель"
              validateStatus={formik.touched.creatorId && formik.errors.creatorId ? 'error' : 'validating'}
              help={formik.touched.creatorId && formik.errors.creatorId}
            >
              <Select
                id="creatorId"
                name="creatorId"
                value={formik.values.creatorId}
                onChange={value => formik.setFieldValue('creatorId', value)}
              >
                {getNested(users, 'data', 'data', 'data') &&
                  getNested(users, 'data', 'data', 'data').map((item, id) => {
                    return (
                      <Select.Option value={item.id} key={id}>
                        {item.fullName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Тип расхода"
              validateStatus={
                formik.touched.spendingSourceId && formik.errors.spendingSourceId ? 'error' : 'validating'
              }
              help={formik.touched.spendingSourceId && formik.errors.spendingSourceId}
            >
              <Select
                id="spendingSourceId"
                name="spendingSourceId"
                value={formik.values.spendingSourceId}
                onChange={value => formik.setFieldValue('spendingSourceId', value)}
              >
                {spendingSources?.data?.data?.data &&
                  spendingSources?.data?.data?.data.map((item, id) => {
                    return (
                      <Select.Option value={item.id} key={id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Сумма"
              validateStatus={formik.touched.amount && formik.errors.amount ? 'error' : 'validating'}
              help={formik.touched.amount && formik.errors.amount}
            >
              <Input
                name="amount"
                type="number"
                min={0}
                max={100}
                placeholder="Сумма"
                value={formik.values.amount}
                onChange={formik.handleChange}
                id="amount"
              />
            </Form.Item>

            <Row justify="space-between">
              <Button
                onClick={formik.handleSubmit}
                disabled={addSpendingInfo && addSpendingInfo.isLoading}
                loading={addSpendingInfo && addSpendingInfo.isLoading}
                type="primary"
              >
                Сохранить
              </Button>
            </Row>
          </Form>
        </Modal>
        <Modal
          title={`Удаление расхода: ${state.deletingItem && state.deletingItem.id}`}
          open={state.deleteModalVisible}
          maskClosable={false}
          destroyOnClose
          onCancel={() => {
            setState({
              ...state,
              deleteModalVisible: false,
              editingItem: null,
              deletingItem: null
            });
            clearForm();
          }}
          footer={null}
        >
          Вы уверены что хотите удалить расход <b>{state.deletingItem && state.deletingItem.id}</b> ?
          <br />
          <br />
          <Button
            danger
            onClick={() => {
              deleteSpending(state.deletingItem.id);
            }}
            disabled={deleteSpendingInfo && deleteSpendingInfo.isLoading}
            loading={deleteSpendingInfo && deleteSpendingInfo.isLoading}
            type="primary"
          >
            Удалить
          </Button>
        </Modal>
      </Spin>
    </>
  );
};

export default ExpensesPage;
