import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Popover
} from 'antd';
import moment from 'moment';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import PlusSquareOutlined from '@ant-design/icons/lib/icons/PlusSquareOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getNested } from '../../utils/getNested';
import { renderStatus } from '../../utils/renderStatus';
import { numberWith } from 'utils/numberWith';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import CardHeader from 'components/CardHeader';

const RecyclePage = () => {
  const history = useHistory();
  const [state, setState] = useState({
    addModalVisible: false,
    editMaterialModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null,
    editingMaterialItem: null,
    material: null,
    editingSection: '',
    deletingSection: ''
  });

  const cache = useQueryCache();
  const materials = useQuery('materialsInfo', api.requests.materials.getMaterialInfoList);
  const forms = useQuery('forms', api.requests.products.forms.getProductFormList);
  const calibers = useQuery('calibers', api.requests.products.calibers.getProductCaliberList);
  const stores = useQuery('stores', api.requests.stores.getStoreList);
  const names = useQuery('names', api.requests.names.getNameList);
  const subnames = useQuery('subnames', api.requests.subnames.getSubnameList);

  // Mutations
  const [addProduct, addProductInfo] = useMutation(
    state.editingItem ? api.requests.products.updateProduct : api.requests.products.createProduct,
    {
      onSuccess: mes => {
        notification.success({
          message: `Продукт успешно добавлен`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('materialsInfo');
      }
    }
  );

  const [addMaterial, addMaterialInfo] = useMutation(
    state.editingMaterialItem ? api.requests.materials.updateMaterial : api.requests.materials.createMaterial,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingMaterialItem
            ? `Закуп успешно сохранён`
            : `Закуп успешно добавлен: ${getNested(mes, 'data', 'data', 'data', 'material', 'customId')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          editMaterialModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('materialsInfo');
      }
    }
  );

  const [deleteMaterial, deleteMaterialInfo] = useMutation(api.requests.materials.deleteMaterial, {
    onSuccess: () => {
      notification.success({
        message: `Закуп успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      cache.invalidateQueries('materialsInfo');
    }
  });

  const [deleteProduct, deleteProductInfo] = useMutation(api.requests.products.deleteProduct, {
    onSuccess: () => {
      notification.success({
        message: `Продукт успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      cache.invalidateQueries('materialsInfo');
    }
  });

  const expandedRowRender = item => {
    const columns = [
      {
        title: 'Форма',
        dataIndex: 'productForm',
        render: productForm => getNested(productForm, 'name')
      },
      {
        title: 'Калибр',
        dataIndex: 'productCaliber',
        render: productCaliber => getNested(productCaliber, 'name')
      },
      {
        title: 'Полировка',
        dataIndex: 'polished',
        render: polished =>
          polished ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
      },
      {
        title: 'Фотосепаратор',
        dataIndex: 'photoseparator',
        render: photoseparator =>
          photoseparator ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
      },
      {
        title: 'Себестоимость',
        dataIndex: 'calculateCost',
        render: (calculateCost, product) =>
          calculateCost ? numberWith(product.cost, ' ') : <CloseOutlined style={{ color: 'red' }} />
      },
      {
        title: 'Объём',
        dataIndex: 'amount',
        render: amount => numberWith(amount, ' ')
      },
      {
        title: 'Работник',
        dataIndex: 'creator',
        render: creator =>
          `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
      },
      {
        title: 'Время',
        dataIndex: 'createdAt',
        render: createdAt => (createdAt ? moment(createdAt).format('DD.MM.YYYY HH:mm') : '')
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  editingItem: record,
                  editingSection: 'product',
                  addModalVisible: true
                });
                startEditingProduct(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  deletingSection: 'product'
                });
              }}
            />
          </Space>
        )
      }
    ];

    const resoldColumns = [
      {
        title: 'Объём',
        dataIndex: 'amount',
        render: amount => numberWith(amount, ' ')
      },
      {
        title: 'Работник',
        dataIndex: 'creator',
        render: creator =>
          `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
      },
      {
        title: 'Время',
        dataIndex: 'createdAt',
        render: createdAt => (createdAt ? moment(createdAt).format('DD.MM.YYYY HH:mm') : '')
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  editingItem: record,
                  editingSection: 'product',
                  addModalVisible: true
                });
                startEditingProduct(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  deletingSection: 'product'
                });
              }}
            />
          </Space>
        )
      }
    ];

    const wastedColumns = [
      {
        title: 'Объём',
        dataIndex: 'amount',
        render: amount => numberWith(amount, ' ')
      },
      {
        title: 'Работник',
        dataIndex: 'creator',
        render: creator =>
          `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
      },
      {
        title: 'Время',
        dataIndex: 'createdAt',
        render: createdAt => (createdAt ? moment(createdAt).format('DD.MM.YYYY HH:mm') : '')
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  editingItem: record,
                  editingSection: 'product',
                  addModalVisible: true
                });
                startEditingProduct(record);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  deletingSection: 'product'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        <br />
        Переработка
        <Table
          columns={columns}
          bordered
          dataSource={item && item.producedProducts && item.producedProducts.length ? item.producedProducts : []}
          pagination={false}
        />
        <br />
        Утеря
        <Table
          columns={wastedColumns}
          bordered
          dataSource={item?.wastedProducts?.length ? item.wastedProducts : []}
          pagination={false}
        />
        <br />
        Перепродажа
        <Table
          columns={resoldColumns}
          bordered
          dataSource={item && item.resoldProducts && item.resoldProducts.length ? item.resoldProducts : []}
          pagination={false}
        />
        <br />
        Отгрузка
        <Table
          columns={columns}
          bordered
          dataSource={item && item.shippedProducts && item.shippedProducts.length ? item.shippedProducts : []}
          pagination={false}
        />
      </Fragment>
    );
  };

  const clearForm = () => {
    productForm.resetForm();
    productForm.values.productProcess = '';
    productForm.values.amount = '';
    productForm.values.productFormId = '';
    productForm.values.productCaliberId = '';
  };

  const productForm = useFormik({
    initialValues: {
      productProcess: '',
      amount: '',
      productFormId: '',
      productCaliberId: '',
      polished: false,
      photoseparator: false
    },
    validationSchema: Yup.object({
      productProcess: Yup.string().required('Обязательное поле'),
      amount: Yup.number().required('Обязательное поле').required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingItem) {
        data = {
          id: state.editingItem.id,
          data: values
        };
      }

      if (data.productProcess === 'wasted') {
        data = {
          productProcess: 'wasted',
          amount: values.amount,
          materialId: state.material && state.material.id
        };
      }

      console.log('productForm => ', data);
      addProduct({
        ...data,
        materialId: state.material && state.material.id
      });
    }
  });

  const startEditingMaterial = item => {
    materialForm.values.status = item.status;
    materialForm.values.storeId = item.storeId;
    materialForm.values.nameId = item.nameId;
    materialForm.values.subnameId = item.subnameId;
    materialForm.values.city = item.city;
    materialForm.values.region = item.region;
    materialForm.values.district = item.district;
    materialForm.values.type = item.type;
    materialForm.values.pricePerKg = item.pricePerKg;
    materialForm.values.price = item.price;
    materialForm.values.totalAmount = item.totalAmount;
    materialForm.values.discount = item.discount;
    materialForm.values.driverName = item.driverName;
    materialForm.values.carNumber = item.carNumber;
    materialForm.values.driverPhoneNumber = item.driverPhoneNumber;
  };

  const startEditingProduct = item => {
    productForm.values.productProcess = item.productProcess;
    productForm.values.amount = item.amount;
    productForm.values.productFormId = item.productFormId;
    productForm.values.productCaliberId = item.productCaliberId;
    productForm.values.photoseparator = item.photoseparator;
    productForm.values.polished = item.polished;
  };

  const materialForm = useFormik({
    initialValues: {
      status: '',
      storeId: '',
      nameId: '',
      subnameId: '',
      city: '',
      region: '',
      district: '',
      type: '',
      pricePerKg: '',
      price: '',
      totalAmount: '',
      discount: '',
      driverName: '',
      carNumber: '',
      driverPhoneNumber: ''
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Обязательное поле'),
      storeId: Yup.number().required('Обязательное поле'),
      nameId: Yup.number().required('Обязательное поле'),
      // subnameId: Yup.number()
      //     .required('Обязательное поле'),
      city: Yup.string().required('Обязательное поле'),
      region: Yup.string().required('Обязательное поле'),
      district: Yup.string().required('Обязательное поле'),
      type: Yup.string().required('Обязательное поле'),
      pricePerKg: Yup.string().required('Обязательное поле'),
      price: Yup.string().required('Обязательное поле'),
      totalAmount: Yup.string().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingMaterialItem) {
        data = {
          id: state.editingMaterialItem.id,
          data: values
        };
      }
      console.log('values => ', values);
      addMaterial(data);
    }
  });

  return (
    <Spin spinning={materials && materials.isLoading}>
      <CardHeader title="Производство" />
      <Card>
        <Table
          rowKey="id"
          size="small"
          bordered
          expandable={{ expandedRowRender }}
          style={{ overflowX: 'scroll' }}
          columns={[
            { title: 'id', dataIndex: 'id' },
            {
              title: 'ID',
              render: material => {
                const { creator } = material;
                return material.customId ? (
                  <Popover
                    content={`${creator && creator.lastName} ${creator && creator.firstName} ${
                      creator && creator.middleName
                    }`}
                    title="Ф.И.О"
                    trigger="hover"
                  >
                    <Button
                      type="link"
                      size="medium"
                      style={{ paddingLeft: 0 }}
                      onClick={() => history.push(`/users/${creator.id}`)}
                    >
                      {material.customId}
                    </Button>
                  </Popover>
                ) : (
                  '-'
                );
              }
            },
            {
              title: 'Дата',
              dataIndex: 'createdAt',
              render: createdAt => (createdAt ? moment(createdAt).format('DD.MM.YYYY') : '')
            },
            {
              title: 'Продукт',
              dataIndex: 'name',
              render: name => name && name.name
            },
            {
              title: 'Сорт',
              dataIndex: 'subname',
              render: subname => subname && subname.name
            },
            {
              title: 'Склад',
              dataIndex: 'store',
              render: store => store && store.name
            },
            {
              title: 'Цена за кг',
              dataIndex: 'pricePerKg',
              render: pricePerKg => (pricePerKg ? numberWith(pricePerKg, ' ') : '-')
            },
            {
              title: 'Цена',
              dataIndex: 'price',
              render: price => (price ? numberWith(price, ' ') : '')
            },
            {
              title: 'Объём (куплено)',
              dataIndex: 'purchasedAmount',
              render: purchasedAmount => (purchasedAmount ? numberWith(purchasedAmount, ' ') : '-')
            },
            {
              title: 'Объём (общий)',
              dataIndex: 'totalAmount',
              render: totalAmount => (totalAmount ? numberWith(totalAmount, ' ') : '-')
            },
            { title: 'Скидка', dataIndex: 'discount' },
            {
              title: 'Статус',
              dataIndex: 'status',
              render: status => (
                <Tag color={'green'} key={'tag'}>
                  {status ? renderStatus(status).toUpperCase() : '-'}
                </Tag>
              )
            },
            {
              title: 'Использовано',
              dataIndex: 'producedAmount',
              render: (_, record) => {
                const resoldAmount =
                  record.resoldAmount && !isNaN(record.resoldAmount) ? Number(record.resoldAmount) : 0;
                const wastedAmount =
                  record.wastedAmount && !isNaN(record.wastedAmount) ? Number(record.wastedAmount) : 0;
                const producedAmount =
                  record.producedAmount && !isNaN(record.producedAmount) ? Number(record.producedAmount) : 0;

                return numberWith(resoldAmount + producedAmount + wastedAmount, ' ');
              }
            },
            {
              title: 'Произведено',
              dataIndex: 'producedAmount',
              render: producedAmount => (producedAmount ? numberWith(producedAmount, ' ') : '-')
            },
            {
              title: 'Перепродажа',
              dataIndex: 'resoldAmount',
              render: resoldAmount => (resoldAmount ? numberWith(resoldAmount, ' ') : '-')
            },
            {
              title: 'Утеря',
              dataIndex: 'wastedAmount',
              render: wastedAmount => (wastedAmount ? numberWith(wastedAmount, ' ') : '-')
            },
            {
              title: 'Отгружено',
              dataIndex: 'shippedAmount',
              render: shippedAmount => (shippedAmount ? numberWith(shippedAmount, ' ') : '-')
            },
            {
              title: 'Остаток Непереработанные',
              dataIndex: 'rawBalance',
              render: (_, record) => {
                const resoldAmount =
                  record.resoldAmount && !isNaN(record.resoldAmount) ? Number(record.resoldAmount) : 0;
                const wastedAmount =
                  record.wastedAmount && !isNaN(record.wastedAmount) ? Number(record.wastedAmount) : 0;
                const producedAmount =
                  record.producedAmount && !isNaN(record.producedAmount) ? Number(record.producedAmount) : 0;
                return numberWith(record.purchasedAmount - (resoldAmount + producedAmount + wastedAmount), ' ');
              }
            },
            {
              title: 'Остаток Переработанные',
              dataIndex: 'prodBalance',
              render: (_, record) => numberWith(record.producedAmount - record.shippedAmount, ' ')
            },
            {
              title: 'Действия',
              dataIndex: 'actions',
              render: (_, record) => (
                <Space>
                  <Button
                    type="link"
                    icon={<PlusSquareOutlined />}
                    onClick={() => {
                      setState({
                        ...state,
                        addModalVisible: true,
                        editingItem: null,
                        material: record
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      startEditingMaterial(record);
                      setState({
                        ...state,
                        editingMaterialItem: record,
                        editMaterialModalVisible: true,
                        editingSection: 'material'
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={() => {
                      setState({
                        ...state,
                        deleteModalVisible: true,
                        deletingItem: record,
                        deletingSection: 'material'
                      });
                    }}
                  />
                </Space>
              )
            }
          ]}
          dataSource={getNested(materials, 'data', 'data', 'data')}
        />
      </Card>
      <Modal
        title={state.editingItem ? `Изменение закупа: ${state.editingItem.customId}` : 'Добавление продукта'}
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Процесс"
            validateStatus={
              productForm.touched.productProcess && productForm.errors.productProcess ? 'error' : 'validating'
            }
            help={productForm.touched.productProcess && productForm.errors.productProcess}
          >
            <Select
              id="productProcess"
              name="productProcess"
              value={productForm.values.productProcess}
              onChange={value => {
                productForm.setFieldValue('productProcess', value);
              }}
            >
              <Select.Option value={'produced'}>Переработка</Select.Option>
              <Select.Option value={'resold'}>Перепродажа</Select.Option>
              <Select.Option value={'wasted'}>Утеря</Select.Option>
            </Select>
          </Form.Item>
          {productForm.values.productProcess === 'produced' ? (
            <Fragment>
              <Form.Item
                label="Форма"
                validateStatus={
                  productForm.touched.productFormId && productForm.errors.productFormId ? 'error' : 'validating'
                }
                help={productForm.touched.productFormId && productForm.errors.productFormId}
              >
                <Select
                  id="productFormId"
                  name="productFormId"
                  value={productForm.values.productFormId}
                  onChange={value => productForm.setFieldValue('productFormId', value)}
                >
                  {getNested(forms, 'data', 'data', 'data') &&
                    getNested(forms, 'data', 'data', 'data').map((item, id) => {
                      return (
                        <Select.Option value={item.id} key={id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Калибр"
                validateStatus={
                  productForm.touched.productCaliberId && productForm.errors.productCaliberId ? 'error' : 'validating'
                }
                help={productForm.touched.productCaliberId && productForm.errors.productCaliberId}
              >
                <Select
                  id="productCaliberId"
                  name="productCaliberId"
                  value={productForm.values.productCaliberId}
                  onChange={value => productForm.setFieldValue('productCaliberId', value)}
                >
                  {getNested(calibers, 'data', 'data', 'data') &&
                    getNested(calibers, 'data', 'data', 'data').map((item, id) => {
                      return (
                        <Select.Option value={item.id} key={id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item label=".">
                <Checkbox
                  checked={productForm.values.polished}
                  onChange={e => productForm.setFieldValue('polished', e.target.checked)}
                >
                  Полировка
                </Checkbox>
              </Form.Item>
              <Form.Item label=".">
                <Checkbox
                  checked={productForm.values.photoseparator}
                  onChange={e => productForm.setFieldValue('photoseparator', e.target.checked)}
                >
                  Фотосепаратор
                </Checkbox>
              </Form.Item>
            </Fragment>
          ) : null}

          <Form.Item
            label="Объём"
            validateStatus={productForm.touched.amount && productForm.errors.amount ? 'error' : 'validating'}
            help={productForm.touched.amount && productForm.errors.amount}
          >
            <Input
              name="amount"
              type="number"
              min={0}
              placeholder="Объём"
              value={productForm.values.amount}
              onChange={productForm.handleChange}
              id="amount"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={productForm.handleSubmit}
              disabled={addProductInfo && addProductInfo.isLoading}
              loading={addProductInfo && addProductInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={
          state.editingMaterialItem ? `Изменение закупа: ${state.editingMaterialItem.customId}` : 'Добавление закупа'
        }
        open={state.editMaterialModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            editMaterialModalVisible: false,
            editingMaterialItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Склад"
            validateStatus={materialForm.touched.storeId && materialForm.errors.storeId ? 'error' : 'validating'}
            help={materialForm.touched.storeId && materialForm.errors.storeId}
          >
            <Select
              id="storeId"
              name="storeId"
              value={materialForm.values.storeId}
              onChange={value => materialForm.setFieldValue('storeId', value)}
            >
              {getNested(stores, 'data', 'data', 'data') &&
                getNested(stores, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Товар"
            validateStatus={materialForm.touched.nameId && materialForm.errors.nameId ? 'error' : 'validating'}
            help={materialForm.touched.nameId && materialForm.errors.nameId}
          >
            <Select
              id="nameId"
              name="nameId"
              value={materialForm.values.nameId}
              onChange={value => {
                materialForm.setFieldValue('nameId', value);
                materialForm.setFieldValue('subnameId', '');
              }}
            >
              {getNested(names, 'data', 'data', 'data') &&
                getNested(names, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Сорт"
            validateStatus={materialForm.touched.subnameId && materialForm.errors.subnameId ? 'error' : 'validating'}
            help={materialForm.touched.subnameId && materialForm.errors.subnameId}
          >
            <Select
              id="subnameId"
              name="subnameId"
              value={materialForm.values.subnameId}
              onChange={value => materialForm.setFieldValue('subnameId', value)}
            >
              {getNested(subnames, 'data', 'data', 'data') &&
                getNested(subnames, 'data', 'data', 'data')
                  .filter(item => item.parentId == materialForm.values.nameId)
                  .map((item, id) => {
                    return (
                      <Select.Option value={item.id} key={id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Тип товара"
            validateStatus={materialForm.touched.type && materialForm.errors.type ? 'error' : 'validating'}
            help={materialForm.touched.type && materialForm.errors.type}
          >
            <Select
              id="type"
              name="type"
              value={materialForm.values.type}
              onChange={value => {
                materialForm.setFieldValue('type', value);
              }}
            >
              <Select.Option value={'ready'}>Готовый</Select.Option>
              <Select.Option value={'raw'}>Сырьё</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Статус"
            validateStatus={materialForm.touched.status && materialForm.errors.status ? 'error' : 'validating'}
            help={materialForm.touched.status && materialForm.errors.status}
          >
            <Select
              id="status"
              name="status"
              value={materialForm.values.status}
              onChange={value => {
                materialForm.setFieldValue('status', value);
              }}
            >
              <Select.Option value={'sent'}>В пути</Select.Option>
              <Select.Option value={'store'}>Получено</Select.Option>
              <Select.Option value={'produced'}>Переработано</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Область"
            validateStatus={materialForm.touched.city && materialForm.errors.city ? 'error' : 'validating'}
            help={materialForm.touched.city && materialForm.errors.city}
          >
            <Input
              name="city"
              placeholder="Область"
              value={materialForm.values.city}
              onChange={materialForm.handleChange}
              id="city"
            />
          </Form.Item>
          <Form.Item
            label="Регион"
            validateStatus={materialForm.touched.region && materialForm.errors.region ? 'error' : 'validating'}
            help={materialForm.touched.region && materialForm.errors.region}
          >
            <Input
              name="region"
              placeholder="Регион"
              value={materialForm.values.region}
              onChange={materialForm.handleChange}
              id="region"
            />
          </Form.Item>
          <Form.Item
            label="Район"
            validateStatus={materialForm.touched.district && materialForm.errors.district ? 'error' : 'validating'}
            help={materialForm.touched.district && materialForm.errors.district}
          >
            <Input
              name="district"
              placeholder="Район"
              value={materialForm.values.district}
              onChange={materialForm.handleChange}
              id="district"
            />
          </Form.Item>
          <Form.Item
            label="Цена за кг"
            validateStatus={materialForm.touched.pricePerKg && materialForm.errors.pricePerKg ? 'error' : 'validating'}
            help={materialForm.touched.pricePerKg && materialForm.errors.pricePerKg}
          >
            <Input
              name="pricePerKg"
              type="number"
              min={0}
              placeholder="Цена за кг"
              value={materialForm.values.pricePerKg}
              onChange={materialForm.handleChange}
              id="pricePerKg"
            />
          </Form.Item>
          <Form.Item
            label="Цена (общая)"
            validateStatus={materialForm.touched.price && materialForm.errors.price ? 'error' : 'validating'}
            help={materialForm.touched.price && materialForm.errors.price}
          >
            <Input
              name="price"
              type="number"
              min={0}
              placeholder="Цена (общая)"
              value={materialForm.values.price}
              onChange={materialForm.handleChange}
              id="price"
            />
          </Form.Item>
          <Form.Item
            label="Обьём"
            validateStatus={
              materialForm.touched.totalAmount && materialForm.errors.totalAmount ? 'error' : 'validating'
            }
            help={materialForm.touched.totalAmount && materialForm.errors.totalAmount}
          >
            <Input
              name="totalAmount"
              type="number"
              min={0}
              placeholder="Обьём"
              value={materialForm.values.totalAmount}
              onChange={materialForm.handleChange}
              id="totalAmount"
            />
          </Form.Item>
          <Form.Item
            label="Скидка"
            validateStatus={materialForm.touched.discount && materialForm.errors.discount ? 'error' : 'validating'}
            help={materialForm.touched.discount && materialForm.errors.discount}
          >
            <Input
              name="discount"
              type="number"
              min={0}
              max={100}
              placeholder="Скидка"
              value={materialForm.values.discount}
              onChange={materialForm.handleChange}
              id="discount"
            />
          </Form.Item>
          <Form.Item label="Дата">
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="ФИО Водителя"
            validateStatus={materialForm.touched.driverName && materialForm.errors.driverName ? 'error' : 'validating'}
            help={materialForm.touched.driverName && materialForm.errors.driverName}
          >
            <Input
              name="driverName"
              placeholder="ФИО Водителя"
              value={materialForm.values.driverName}
              onChange={materialForm.handleChange}
              id="driverName"
            />
          </Form.Item>
          <Form.Item
            label="Номер авто"
            validateStatus={materialForm.touched.carNumber && materialForm.errors.carNumber ? 'error' : 'validating'}
            help={materialForm.touched.carNumber && materialForm.errors.carNumber}
          >
            <Input
              name="carNumber"
              placeholder="Номер транспорта"
              value={materialForm.values.carNumber}
              onChange={materialForm.handleChange}
              id="carNumber"
            />
          </Form.Item>
          <Form.Item
            label="Тел Номер"
            validateStatus={
              materialForm.touched.driverPhoneNumber && materialForm.errors.driverPhoneNumber ? 'error' : 'validating'
            }
            help={materialForm.touched.driverPhoneNumber && materialForm.errors.driverPhoneNumber}
          >
            <Input
              name="driverPhoneNumber"
              placeholder="Тел Номер"
              value={materialForm.values.driverPhoneNumber}
              onChange={materialForm.handleChange}
              id="driverPhoneNumber"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={materialForm.handleSubmit}
              disabled={addMaterialInfo && addMaterialInfo.isLoading}
              loading={addMaterialInfo && addMaterialInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление: ${
          state.deletingSection === 'product'
            ? state.deletingItem && renderStatus(state.deletingItem.productProcess)
            : state.deletingItem && state.deletingItem.customId
        } ${state.deletingSection === 'product' ? state.deletingItem && state.deletingItem.amount : ''}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        Вы уверены что хотите удалить элемент{' '}
        <b>
          {state.deletingItem && renderStatus(state.deletingItem.productProcess)}{' '}
          {state.deletingItem && state.deletingItem.amount}
        </b>{' '}
        ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            if (state.deletingSection === 'material') {
              deleteMaterial(state.deletingItem.id);
            }
            if (state.deletingSection === 'product') {
              deleteProduct(state.deletingItem.id);
            }
          }}
          disabled={
            state.deletingSection === 'product'
              ? deleteProductInfo && deleteProductInfo.isLoading
              : deleteMaterialInfo && deleteMaterialInfo.isLoading
          }
          loading={
            state.deletingSection === 'product'
              ? deleteProductInfo && deleteProductInfo.isLoading
              : deleteMaterialInfo && deleteMaterialInfo.isLoading
          }
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </Spin>
  );
};

export default RecyclePage;
