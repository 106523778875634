import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Button, Card, Form, Input, Modal, notification, Row, Select, Space, Table } from 'antd';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getNested } from 'utils/getNested';
import { renderStatus } from 'utils/renderStatus';
import { numberWith } from 'utils/numberWith';
import moment from 'moment';
import 'moment/locale/ru';
import CardHeader from 'components/CardHeader';

const ShipmentsPage = () => {
  const [state, setState] = useState({
    addModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null
  });

  const cache = useQueryCache();
  const shipments = useQuery('shipments', api.requests.shipments.getShipmentList);
  const stores = useQuery('stores', api.requests.stores.getStoreList);
  const contracts = useQuery('contracts', api.requests.contracts.getContractList);

  // Mutations

  const [addShipment, addShipmentInfo] = useMutation(
    state.editingItem ? api.requests.shipments.updateShipment : api.requests.shipments.createShipment,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingShipmentItem
            ? `Отгрузка успешно сохранена`
            : `Отгрузка успешно добавлена: ${getNested(mes, 'data', 'data', 'data', 'material', 'customId')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearShipmentForm();
        cache.invalidateQueries('shipments');
      }
    }
  );

  const [deleteShipment, deleteShipmentInfo] = useMutation(api.requests.shipments.deleteShipment, {
    onSuccess: () => {
      notification.success({
        message: `Отгрузка успешно удалёна`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      cache.invalidateQueries('shipments');
    }
  });

  const expandedRowRender = item => {
    console.log('item => ', item);

    const columns = [
      { title: 'ID', dataIndex: 'parent', render: parent => getNested(parent, 'material', 'customId') },
      { title: 'Наименование', dataIndex: 'parent', render: parent => getNested(parent, 'material', 'name', 'name') },
      { title: 'Сорт', dataIndex: 'parent', render: parent => getNested(parent, 'material', 'subname', 'name') },
      { title: 'Калибр', dataIndex: 'parent', render: parent => getNested(parent, 'productCaliber', 'name') },
      { title: 'Объём', dataIndex: 'amount' },
      {
        title: 'Менеджер производства',
        dataIndex: 'creator',
        render: creator =>
          `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
      },
      { title: 'Время', dataIndex: 'createdAt' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state
                });
                // startEditing(record)
                // toggleTeamMemberModal(true)
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  deletingSection: 'product'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        <Table columns={columns} bordered dataSource={getNested(item, 'products')} pagination={false} />
      </Fragment>
    );
  };

  const clearShipmentForm = () => {
    shipmentForm.resetForm();
    shipmentForm.values.productProcess = '';
    shipmentForm.values.amount = '';
    shipmentForm.values.productFormikId = '';
    shipmentForm.values.productСaliberId = '';
  };

  const startEditingShipment = item => {
    shipmentForm.values.contractId = item.contractId;
    shipmentForm.values.storeId = item.storeId;
    shipmentForm.values.containerNumber = item.containerNumber;
  };

  const shipmentForm = useFormik({
    initialValues: {
      contractId: '',
      storeId: '',
      containerNumber: ''
    },
    validationSchema: Yup.object({
      contractId: Yup.number().required('Обязательное поле'),
      storeId: Yup.number().required('Обязательное поле'),
      containerNumber: Yup.string().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingShipmentItem) {
        data = {
          id: state.editingShipmentItem.id,
          data: values
        };
      }
      console.log('values => ', values);
      addShipment(data);
    }
  });

  const FORM_ITEM_LAYOUT = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 }
    }
  };

  const onClickAdd = () => {
    clearShipmentForm();
    setState({
      ...state,
      addModalVisible: true,
      editingItem: null
    });
  };

  return (
    <Spin spinning={shipments && shipments.isLoading}>
      <CardHeader title="Отгрузка" hasButton onButtonClick={onClickAdd} />
      <Card>
        <Table
          rowKey="id"
          size="middle"
          bordered
          expandable={{ expandedRowRender }}
          style={{ overflowX: 'scroll' }}
          columns={[
            { title: 'id', dataIndex: 'id' },
            {
              title: 'Дата',
              dataIndex: 'createdAt',
              render: (createdAt, record) => {
                return (
                  <Fragment>
                    {createdAt ? moment(createdAt).format('DD.MM.YYYY') : ''} <br />
                  </Fragment>
                );
              }
            },
            { title: 'Склад', dataIndex: 'store', render: store => getNested(store, 'name') },
            { title: 'Контракт', dataIndex: 'contract', render: contract => getNested(contract, 'contractNumber') },
            { title: 'Номер контейнера', dataIndex: 'containerNumber' },
            {
              title: 'Объём',
              dataIndex: 'shippedAmount',
              render: shippedAmount => (shippedAmount ? numberWith(shippedAmount, ' ') : '-')
            },
            {
              title: 'Менеджер производства',
              dataIndex: 'creator',
              render: creator =>
                `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
            },
            {
              title: 'Действия',
              dataIndex: 'actions',
              render: (_, record) => (
                <Space>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      startEditingShipment(record);
                      setState({
                        ...state,
                        editingShipmentItem: record,
                        addModalVisible: true
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={() => {
                      setState({
                        ...state,
                        deleteModalVisible: true,
                        deletingItem: record
                      });
                    }}
                  />
                </Space>
              )
            }
          ]}
          dataSource={getNested(shipments, 'data', 'data', 'data')}
        />
      </Card>
      <Modal
        title={
          state.editingItem
            ? `Изменение отгрузки: ${state.editingItem ?? state.editingItem.customId}`
            : 'Добавление отгрузки'
        }
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearShipmentForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Контракт"
            validateStatus={shipmentForm.touched.contractId && shipmentForm.errors.contractId ? 'error' : 'validating'}
            help={shipmentForm.touched.contractId && shipmentForm.errors.contractId}
          >
            <Select
              id="contractId"
              name="contractId"
              value={shipmentForm.values.contractId}
              onChange={value => shipmentForm.setFieldValue('contractId', value)}
            >
              {getNested(contracts, 'data', 'data', 'data', 'data') &&
                getNested(contracts, 'data', 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.contractNumber}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Склад"
            validateStatus={shipmentForm.touched.storeId && shipmentForm.errors.storeId ? 'error' : 'validating'}
            help={shipmentForm.touched.storeId && shipmentForm.errors.storeId}
          >
            <Select
              id="storeId"
              name="storeId"
              value={shipmentForm.values.storeId}
              onChange={value => shipmentForm.setFieldValue('storeId', value)}
            >
              {getNested(stores, 'data', 'data', 'data') &&
                getNested(stores, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Контейнер №"
            validateStatus={
              shipmentForm.touched.containerNumber && shipmentForm.errors.containerNumber ? 'error' : 'validating'
            }
            help={shipmentForm.touched.containerNumber && shipmentForm.errors.containerNumber}
          >
            <Input
              name="containerNumber"
              placeholder="Контейнер №"
              value={shipmentForm.values.containerNumber}
              onChange={shipmentForm.handleChange}
              id="containerNumber"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={shipmentForm.handleSubmit}
              disabled={addShipmentInfo && addShipmentInfo.isLoading}
              loading={addShipmentInfo && addShipmentInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        // title={`Удаление: ${state.deletingItem.contract?.contractNumber}: ${state.deletingItem.contract?.contractNumber}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
        }}
        footer={null}
      >
        Вы уверены что хотите удалить элемент{' '}
        <b>
          {state.deletingItem && renderStatus(state.deletingItem.productProcess)}{' '}
          {state.deletingItem && state.deletingItem.amount}
        </b>{' '}
        ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteShipment(state.deletingItem.id);
          }}
          disabled={deleteShipmentInfo?.isLoading}
          loading={deleteShipmentInfo?.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </Spin>
  );
};

export default ShipmentsPage;
