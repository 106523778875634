import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, usePaginatedQuery, useQuery, useQueryCache } from 'react-query';
import {
  Pagination,
  Button,
  Card,
  Typography,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag
} from 'antd';
import ReactExport from 'react-export-excel';
import Popover from 'antd/lib/popover';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import PlusSquareOutlined from '@ant-design/icons/lib/icons/PlusSquareOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getNested } from '../../utils/getNested';
import { renderStatus } from '../../utils/renderStatus';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import { numberWith } from 'utils/numberWith';
import useQueryParams from 'hooks/useQueryParams';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import CardHeader from 'components/CardHeader';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Text } = Typography;

const ProductsPage = () => {
  const queryParams = useQueryParams();
  const history = useHistory();

  const [state, setState] = useState({
    addModalVisible: false,
    editMaterialModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null,
    editingMaterialItem: null,
    product: null,
    editingSection: '',
    deletingSection: '',
    filter: queryParams.values,
    page: queryParams.values?.page || 1,
    limit: queryParams.values && queryParams.values.limit
  });

  const cache = useQueryCache();
  const products = usePaginatedQuery(['products', state.page, state.limit], () =>
    api.requests.products.getProductList({
      ...queryParams.values,
      page: queryParams.values.page
    })
  );
  const forms = useQuery('forms', api.requests.products.forms.getProductFormList);
  const shipments = useQuery('shipments', api.requests.shipments.getShipmentList);

  // Mutations
  const [addProduct, addProductInfo] = useMutation(
    state.editingItem ? api.requests.products.updateProduct : api.requests.products.createProduct,
    {
      onSuccess: mes => {
        notification.success({
          message: `Продукт успешно добавлен`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('products');
      }
    }
  );

  const [addMaterial, addMaterialInfo] = useMutation(
    state.editingMaterialItem ? api.requests.materials.updateMaterial : api.requests.materials.createMaterial,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingMaterialItem
            ? `Закуп успешно сохранён`
            : `Закуп успешно добавлен: ${getNested(mes, 'data', 'data', 'data', 'material', 'customId')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          editMaterialModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('products');
      }
    }
  );

  const [deleteMaterial, deleteMaterialInfo] = useMutation(api.requests.materials.deleteMaterial, {
    onSuccess: () => {
      notification.success({
        message: `Закуп успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      cache.invalidateQueries('products');
    }
  });

  const [deleteProduct, deleteProductInfo] = useMutation(api.requests.products.deleteProduct, {
    onSuccess: () => {
      notification.success({
        message: `Продукт успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      cache.invalidateQueries('products');
    }
  });

  const expandedRowRender = item => {
    const columns = [
      {
        title: 'Операция',
        dataIndex: 'productProcess',
        render: productProcess => (
          <Tag color={'geekblue'} key={'tag'}>
            {renderStatus(productProcess).toUpperCase()}
          </Tag>
        )
      },
      {
        title: 'Контракт',
        dataIndex: 'shipment',
        render: shipment => getNested(shipment, 'contract', 'contractNumber')
      },
      {
        title: 'Контейнер',
        dataIndex: 'shipment',
        render: shipment => getNested(shipment, 'containerNumber')
      },
      { title: 'Объём', dataIndex: 'amount' },
      {
        title: 'Работник',
        dataIndex: 'creator',
        render: creator =>
          `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
      },
      { title: 'Время', dataIndex: 'createdAt' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setState({
                  ...state,
                  editingSection: 'product'
                });
                // startEditing(record)
                // toggleTeamMemberModal(true)
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteModalVisible: true,
                  deletingItem: record,
                  deletingSection: 'product'
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        <Table columns={columns} bordered dataSource={getNested(item, 'shipments')} pagination={false} />
      </Fragment>
    );
  };

  const clearForm = () => {
    productFormik.resetForm();
    productFormik.values.shipmentId = '';
    productFormik.values.amount = '';
  };

  const productFormik = useFormik({
    initialValues: {
      shipmentId: '',
      amount: ''
    },
    validationSchema: Yup.object({
      shipmentId: Yup.number().required('Обязательное поле'),
      amount: Yup.number().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingItem) {
        data = {
          id: state.editingItem.id,
          data: values
        };
      }

      addProduct({
        ...data,
        productProcess: 'shipped',
        parentId: state.product && state.product.id,
        materialId: state.product && state.product.materialId
      });
    }
  });

  const productColumns = [
    { title: 'id', value: 'id' },
    {
      title: 'ID',
      dataIndex: 'id',
      render: ({ material, material: { creator } }) =>
        material.customId ? (
          <Popover
            content={`${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`}
            title="Ф.И.О"
            trigger="hover"
          >
            <Button
              type="link"
              size="medium"
              style={{ paddingLeft: 0 }}
              onClick={() => history.push(`/users/${creator.id}`)}
            >
              {material.customId}
            </Button>
          </Popover>
        ) : (
          '-'
        )
    },
    {
      title: 'Дата',
      value: ({ createdAt }) => (createdAt ? moment(createdAt).format('DD.MM.YYYY') : '')
    },
    {
      title: 'Склад',
      value: ({ material }) => getNested(material, 'store', 'name')
    },
    {
      title: 'Наименование',
      value: ({ material }) => getNested(material, 'name', 'name')
    },
    {
      title: 'Сорт',
      value: ({ material }) => getNested(material, 'subname', 'name')
    },
    {
      title: 'Форма',
      value: ({ productForm }) => getNested(productForm, 'name')
    },
    {
      title: 'Калибр',
      value: ({ productCaliber }) => getNested(productCaliber, 'name')
    },
    { title: 'Объём', value: ({ amount }) => amount },
    { title: 'Отгружено', value: ({ shippedAmount }) => shippedAmount || '-' },
    {
      title: 'Остаток',
      value: record => {
        const shippedAmount = record && record.shippedAmount ? record.shippedAmount : 0;
        const balance = record.amount - shippedAmount;
        return isNaN(balance) ? '-' : balance;
      }
    }
  ];

  return (
    <Spin spinning={products && products.isFetching}>
      <CardHeader title="Продукция" />
      <ExcelFile element={<Button icon={<DownloadOutlined />}>Экспорт в Excel</Button>}>
        <ExcelSheet data={getNested(products, 'data', 'data', 'data', 'data')} name="Prods">
          {productColumns && productColumns.length
            ? productColumns.map((column, id) => {
                return <ExcelColumn label={column.title} value={column.value} key={id} />;
              })
            : []}
        </ExcelSheet>
      </ExcelFile>
      <Card>
        Общее количество элементов: <b>{products?.data?.data?.count}</b>
        <Table
          rowKey="id"
          size="small"
          bordered
          expandable={{ expandedRowRender }}
          style={{ overflowX: 'scroll' }}
          columns={[
            { title: 'id', dataIndex: 'id' },
            {
              title: 'ID',
              dataIndex: 'material',
              render: material => material && material.customId
            },

            {
              title: 'Дата',
              dataIndex: 'createdAt',
              render: createdAt => (createdAt ? moment(createdAt).format('DD.MM.YYYY') : '')
            },
            {
              title: 'Склад',
              dataIndex: 'material',
              render: material => getNested(material, 'store', 'name')
            },
            {
              title: 'Статус',
              dataIndex: 'productProcess',
              render: productProcess => (
                <Tag color={'green'} key={'tag'}>
                  {productProcess ? renderStatus(productProcess).toUpperCase() : ''}
                </Tag>
              )
            },
            {
              title: 'Наименование',
              dataIndex: 'material',
              render: material => getNested(material, 'name', 'name')
            },
            {
              title: 'Сорт',
              dataIndex: 'material',
              render: material => getNested(material, 'subname', 'name')
            },
            {
              title: 'Форма',
              dataIndex: 'productForm',
              render: productForm => getNested(productForm, 'name')
            },
            {
              title: 'Калибр',
              dataIndex: 'productCaliber',
              render: productCaliber => getNested(productCaliber, 'name')
            },
            {
              title: 'Полировка',
              dataIndex: 'polished',
              render: polished =>
                polished ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
            },
            {
              title: 'Фотосепаратор',
              dataIndex: 'photoseparator',
              render: photoseparator =>
                photoseparator ? (
                  <CheckOutlined style={{ color: 'green' }} />
                ) : (
                  <CloseOutlined style={{ color: 'red' }} />
                )
            },
            {
              title: 'Объём',
              dataIndex: 'amount',
              render: amount => numberWith(amount, ' ')
            },
            {
              title: 'Отгружено',
              dataIndex: 'shippedAmount',
              render: shippedAmount => shippedAmount || '-'
            },
            {
              title: 'Остаток',
              dataIndex: 'amount',
              render: (_, record) => {
                const shippedAmount = record && record.shippedAmount ? record.shippedAmount : 0;
                const balance = record.amount - shippedAmount;
                return isNaN(balance) ? '-' : numberWith(balance, ' ');
              }
            },
            {
              title: 'Менеджер',
              dataIndex: 'creator',
              render: creator =>
                `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
            },
            {
              title: 'Действия',
              dataIndex: 'actions',
              render: (_, record) => (
                <Space>
                  <Button
                    type="link"
                    icon={<PlusSquareOutlined />}
                    onClick={() => {
                      setState({
                        ...state,
                        addModalVisible: true,
                        product: record
                      });
                    }}
                  />
                  {/*<Button*/}
                  {/*    type="link"*/}
                  {/*    icon={<EditOutlined />}*/}
                  {/*    onClick={() => {*/}
                  {/*        startEditingMaterial(record)*/}
                  {/*        setState({*/}
                  {/*            ...state,*/}
                  {/*            editingMaterialItem: record,*/}
                  {/*            editMaterialModalVisible: true,*/}
                  {/*            editingSection: "material"*/}
                  {/*        })*/}
                  {/*    }*/}
                  {/*    }*/}
                  {/*/>*/}
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={() => {
                      setState({
                        ...state,
                        deleteModalVisible: true,
                        deletingItem: record,
                        deletingSection: 'product'
                      });
                    }}
                  />
                </Space>
              )
            }
          ]}
          dataSource={getNested(products, 'data', 'data', 'data', 'data')}
          summary={pageData => {
            let totalProduced = 0;
            let totalShipped = 0;

            pageData.forEach(({ amount, shippedAmount }) => {
              totalProduced += amount;
              totalShipped += parseInt(shippedAmount) && !isNaN(shippedAmount) ? parseInt(shippedAmount) : 0;
            });

            totalShipped = !isNaN(totalShipped) ? totalShipped : 0;

            return (
              <Fragment>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>Общий объём</Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={4}>
                    <Text type="danger">
                      <b>{totalProduced ? numberWith(totalProduced, ' ') : '-'}</b>
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>Отгружено</Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={4}>
                    <Text>
                      <b>{totalShipped ? numberWith(totalShipped, ' ') : '-'}</b>
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>Остаток</Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={4}>
                    <Text type="danger">
                      <b>{numberWith(totalProduced - totalShipped, ' ')}</b>
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Fragment>
            );
          }}
          pagination={{
            size: 'middle',
            showSizeChanger: true,
            position: ['topRight'],
            total: getNested(products, 'data', 'data', 'count'),
            showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} элементов`,
            pageSizeOptions: [10, 20, 50, 100, 150, 200, 500, 1000, 5000, 10000],
            defaultPageSize: state.limit || 10,
            defaultCurrent: parseInt(state.page) || 1,
            pageSize: state.limit,
            current: parseInt(state.page),
            onChange: (page, pageSize) => {
              setState({
                ...state,
                page,
                limit: pageSize
              });
              queryParams.merge({
                page,
                limit: pageSize
              });
            }
          }}
        />
        <br />
      </Card>
      <Modal
        title={state.editingItem ? `Изменение закупа: ${state.editingItem.customId}` : 'Добавление отгрузки'}
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Отгрузка"
            validateStatus={
              productFormik.touched.shipmentId && productFormik.errors.shipmentId ? 'error' : 'validating'
            }
            help={productFormik.touched.shipmentId && productFormik.errors.shipmentId}
          >
            <Select
              id="shipmentId"
              name="shipmentId"
              value={productFormik.values.shipmentId}
              onChange={value => productFormik.setFieldValue('shipmentId', value)}
            >
              {getNested(shipments, 'data', 'data', 'data') &&
                getNested(shipments, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.contract && item.contract.contractNumber} : {item.containerNumber}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Объём"
            validateStatus={productFormik.touched.amount && productFormik.errors.amount ? 'error' : 'validating'}
            help={productFormik.touched.amount && productFormik.errors.amount}
          >
            <Input
              name="amount"
              type="number"
              min={0}
              placeholder="Объём"
              value={productFormik.values.amount}
              onChange={productFormik.handleChange}
              id="amount"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={productFormik.handleSubmit}
              disabled={addProductInfo && addProductInfo.isLoading}
              loading={addProductInfo && addProductInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление: ${
          state.deletingSection === 'product'
            ? state.deletingItem && renderStatus(state.deletingItem.productProcess)
            : state.deletingItem && state.deletingItem.customId
        } ${state.deletingSection === 'product' ? state.deletingItem && state.deletingItem.amount : ''}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        Вы уверены что хотите удалить элемент{' '}
        <b>
          {state.deletingItem && renderStatus(state.deletingItem.productProcess)}{' '}
          {state.deletingItem && state.deletingItem.amount}
        </b>{' '}
        ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            if (state.deletingSection === 'product') {
              deleteProduct(state.deletingItem.id);
            }
          }}
          disabled={
            state.deletingSection === 'product'
              ? deleteProductInfo && deleteProductInfo.isLoading
              : deleteMaterialInfo && deleteMaterialInfo.isLoading
          }
          loading={
            state.deletingSection === 'product'
              ? deleteProductInfo && deleteProductInfo.isLoading
              : deleteMaterialInfo && deleteMaterialInfo.isLoading
          }
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </Spin>
  );
};

export default ProductsPage;
