import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { Skeleton, Card, Statistic, Row, Col, Button, Typography, Space, Divider, DatePicker } from 'antd';
import api from 'services/api/api';
import { localeDate } from 'utils/dateLocale';
import CardHeader from 'components/CardHeader';
import TableOutlined from '@ant-design/icons/lib/icons/TableOutlined';

const { RangePicker } = DatePicker;
const { Title } = Typography;

const SpendingStatsPage = () => {
  let history = useHistory();
  const [typeId, setTypeId] = useState(null);
  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null
  });
  const { startDate, endDate } = dateState;

  const stats = useQuery(['spendingStats', startDate, endDate], () =>
    api.requests.spendings.getSpendingStats({ startDate, endDate })
  );
  const statsInfo = stats?.data?.data?.data;
  const statsByType = useQuery(
    ['spendingStatsByType', typeId, startDate, endDate],
    () => api.requests.spendings.getSpendingStatsByType({ typeId, filter: { startDate, endDate } }),
    {
      enabled: !!typeId
    }
  );
  const statsByTypeInfo = statsByType?.data?.data?.data;

  const onRangeChange = value => {
    const isEmpty = value?.length !== 2;
    setDateState({
      startDate: !isEmpty ? moment(value[0]).format('YYYY-MM-DD') : undefined,
      endDate: !isEmpty ? moment(value[1]).format('YYYY-MM-DD') : undefined
    });
  };

  const formattedStartDate = moment(dateState.startDate).format('DD.MM.YYYY');
  const formattedEndDate = moment(dateState.endDate).format('DD.MM.YYYY');

  return (
    <div>
      <CardHeader title="Статистика расходов пользователей" hasButton />
      <Divider />
      {stats?.isLoading ? <Skeleton /> : null}
      <Row>
        <Space>
          <RangePicker
            locale={localeDate}
            format="DD-MM-YYYY"
            value={
              dateState.startDate && dateState.endDate
                ? [moment(dateState.startDate, 'YYYY-MM-DD'), moment(dateState.endDate, 'YYYY-MM-DD')]
                : undefined
            }
            onChange={(value, dateString) => onRangeChange(value)}
          />
        </Space>
      </Row>
      <Divider />
      {dateState?.startDate && dateState?.endDate ? (
        <Title level={3}>
          Показаны данные на период с {formattedStartDate} по {formattedEndDate}:
        </Title>
      ) : null}
      {stats?.isLoading ? <Skeleton /> : null}
      {statsInfo?.length ? (
        <Row gutter={8}>
          {statsInfo.map((spendingType, index) => {
            const isSelected = spendingType.id === typeId;
            return (
              <Col span={3} key={index}>
                <Card
                  hoverable
                  style={{ cursor: 'pointer', borderColor: isSelected && 'blue' }}
                  title={spendingType.name}
                  extra={
                    <Button
                      type="link"
                      size="large"
                      style={{ padding: 0 }}
                      onClick={() => history.push(`/transactions?bankId=${spendingType.id}`)}
                    >
                      <TableOutlined />
                    </Button>
                  }
                  onClick={() => setTypeId(spendingType.id)}
                >
                  <Statistic title="Сум" value={spendingType.total_amount} />
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : null}
      {statsByType?.isLoading || statsByType?.isFetching ? <Skeleton /> : null}
      {statsByTypeInfo?.length && !statsByType.isFetching ? (
        <>
          <Divider />
          <Row gutter={8}>
            {statsByTypeInfo?.length &&
              statsByTypeInfo.map((spendingSource, index) => {
                return (
                  <Col span={3} key={index}>
                    <Card
                      title={spendingSource.name}
                      extra={
                        <Button
                          type="link"
                          size="large"
                          style={{ padding: 0 }}
                          onClick={() => history.push(`/transactions?bankId=${spendingSource.id}`)}
                        >
                          <TableOutlined />
                        </Button>
                      }
                    >
                      <Statistic title="Сум" value={spendingSource.total_amount} />
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default SpendingStatsPage;
