import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Form, Select, Spin } from 'antd';
import debounce from 'lodash.debounce';

const { Option } = Select;

const SearchSelectFormItem = ({
  apiFunction, // Function for API call
  queryKey, // Key for react-query
  name, // Name for the Form.Item and Select
  label, // Label for the Form.Item
  placeholder, // Placeholder for the Select
  form, // Instance of antd form
  debounceDelay = 500, // Delay for debouncing the search
  rules, // Rules for Form.Item validation
  onChange, // Function to handle onChange event
  notFoundContent = 'Нет результатов', // Content to display when no options found
  optionLabel = 'name', // Label property of the options
  optionValue = 'id' // Value property of the options
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = debounce(value => {
    setSearchTerm(value);
  }, debounceDelay);

  const { data: optionsData, isFetching } = useQuery(
    [queryKey, searchTerm],
    () => apiFunction({ search: searchTerm }),
    {
      enabled: searchTerm.length > 2,
      keepPreviousData: true
    }
  );

  const options = optionsData?.data?.data || [];

  console.log('options => ', options);

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select
        showSearch
        allowClear
        placeholder={placeholder}
        onSearch={debouncedSearch}
        onChange={onChange}
        loading={isFetching}
        notFoundContent={isFetching ? <Spin size="small" /> : notFoundContent}
        filterOption={false}
      >
        {options.map(item => (
          <Option key={item[optionValue]} value={item[optionValue]}>
            {item[optionLabel]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SearchSelectFormItem;
