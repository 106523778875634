import React from 'react';
import { Button, Form } from 'antd';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import TextAreaItem from 'components/FormItems/TextAreaFormItem';
import InputNumberFormItem from 'components/FormItems/InputNumberFormItem';
import DatePickerFormItem from 'components/FormItems/DatePickerFormItem';
import SelectItem from 'components/FormItems/SelectFormItem';
import { determineDateFormat } from 'utils/helpers';

const { useForm, useWatch } = Form;

const TransferForm = ({ initialValues, filterList, onFinish, isCreating }) => {
  const { banks, currencies } = filterList;
  const [form] = useForm();
  const fromBankValue = useWatch('fromBankId', form);
  const toBankValue = useWatch('toBankId', form);

  const getFromBanks = () => {
    if (!filterList || !filterList.banks || !filterList.banks.length) {
      return null;
    }

    return banks.filter(({ id }) => id !== toBankValue);
  };

  const getToBanks = () => {
    if (!filterList || !filterList.banks || !filterList.banks.length) {
      return null;
    }

    return banks.filter(({ id }) => id !== fromBankValue);
  };

  const getCurrencies = () => {
    const uzs = currencies?.find(({ id }) => id === 1);

    if ([fromBankValue, toBankValue].includes(1)) {
      return [uzs];
    }
    return currencies;
  };

  const getInitialValues = () => {
    if (initialValues) {
      const { date, amount } = initialValues;

      return {
        ...initialValues,
        date: determineDateFormat(date),
        amount: Math.abs(amount)
      };
    }
  };

  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      form={form}
      name="transfer-form"
      onFinish={onFinish}
      labelAlign="right"
      labelWrap
      initialValues={getInitialValues()}
    >
      <DatePickerFormItem name="date" label="Дата" />
      <SelectItem
        name="fromBankId"
        label="Банк отправитель"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getFromBanks() || []}
      />
      <SelectItem
        name="toBankId"
        label="Банк получатель"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getToBanks() || []}
      />
      <SelectItem
        name="currencyId"
        label="Валюта"
        rules={[{ required: true }]}
        placeholder="Выбрать"
        options={getCurrencies() || []}
      />
      <InputNumberFormItem name="amount" label="Сумма" rules={[{ required: true }]} />
      <TextAreaItem
        name="comment"
        label="Комментарии"
        placeholder="Enter your description here"
        rules={[{ required: false, message: 'Description is required' }]}
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TransferForm;
