import React from 'react';
import { Form, Switch } from 'antd';

// You can customize this component further by passing additional props as needed.
const SwitchFormItem = ({ name, label, valuePropName, initialValue, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      valuePropName={valuePropName}
      initialValue={initialValue}
      {...rest} // Allows for passing other Form.Item props such as rules for validation
    >
      <Switch />
    </Form.Item>
  );
};

export default SwitchFormItem;
