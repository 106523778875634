import moment from 'moment';

export const findById = (items, id, key) => {
  return items?.find(item => item[key || 'id'] == id);
};

export const determineDateFormat = date => {
  if (!date) return undefined;
  if (moment.isMoment(date)) return date;
  if (typeof date === 'number') return moment(date);
  return moment(date, moment.ISO_8601);
};
