import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const TextAreaFormItem = ({
  name,
  label,
  rules,
  placeholder,
  autoSize = { minRows: 3, maxRows: 5 },
  onChange,
  ...rest
}) => (
  <Form.Item name={name} label={label} rules={rules} {...rest}>
    <TextArea placeholder={placeholder} autoSize={autoSize} onChange={onChange} />
  </Form.Item>
);

export default TextAreaFormItem;
