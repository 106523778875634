import React from 'react';
import { Button, Form } from 'antd';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import TextAreaItem from 'components/FormItems/TextAreaFormItem';
import InputNumberFormItem from 'components/FormItems/InputNumberFormItem';
import DatePickerFormItem from 'components/FormItems/DatePickerFormItem';
import SwitchFormItem from 'components/FormItems/SwitchFormItem';
import { determineDateFormat } from 'utils/helpers';

const { useForm, useWatch } = Form;

const RefundForm = ({ initialValues, filterList, onFinish, isCreating }) => {
  const [form] = useForm();
  const receivedValue = useWatch('received', form);

  const getInitialValues = () => {
    if (initialValues) {
      const { date, receivedAt } = initialValues;

      return {
        ...initialValues,
        date: determineDateFormat(date),
        receivedAt: determineDateFormat(receivedAt)
      };
    }
  };

  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      form={form}
      name="refund-form"
      labelAlign="right"
      labelWrap
      onFinish={onFinish}
      initialValues={getInitialValues()}
    >
      <DatePickerFormItem name="date" label="Дата" />
      <InputNumberFormItem
        name="amount"
        label="Сумма"
        inputProps={{ addonAfter: 'Сум' }}
        rules={[{ required: true }]}
      />
      <SwitchFormItem
        name="received"
        label="Получено"
        valuePropName="checked" // This tells Form.Item to use the 'checked' prop for the Switch component
      />
      {receivedValue ? (
        <DatePickerFormItem
          name="receivedAt"
          label="Дата получения"
          rules={[{ required: !!receivedValue, message: 'Description is required' }]}
        />
      ) : null}
      <TextAreaItem
        name="comment"
        label="Комментарии"
        placeholder="Enter your description here"
        rules={[{ required: false, message: 'Description is required' }]}
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isCreating} loading={isCreating}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RefundForm;
