import axios from 'axios';

import config from './config';
import qs from 'qs';
import { logout } from 'services/actions';

const request = axios.create({
  baseURL: config.URL
});

const subscribe = globalState => {
  const { dispatch } = globalState;
  const { accessToken } = globalState && globalState.state && globalState.state.auth;

  request.interceptors.request.use(config => {
    if (accessToken) {
      config.headers['Authorization'] = ['Bearer', accessToken].join(' ');
    }

    return config;
  });

  request.interceptors.response.use(
    config => config,
    error => {
      if (error && error.response && error.response.status === 401) {
        dispatch(logout());
      }

      throw error;
    }
  );
};

const requests = {
  auth: {
    login: data => request.post(`/users/login`, data)
  },
  users: {
    getUserList: role => request.get(`/users${qs.stringify({ role }, { addQueryPrefix: true })}`),
    getSingleUser: id => request.get(`/users/${id}`),
    createUser: data => request.post(`/users`, data),
    updateUser: data => request.patch(`/users/${data.id}`, data.data)
  },
  transactions: {
    getTransactionList: filter => {
      return request.get(`/transactions${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getInTransactionList: filter => {
      return request.get(`/transactions${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getOutTransactionList: filter => {
      return request.get(`/transactions/out${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getTransactionStats: filter => {
      return request.get(`/transactions/stats${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getTransactionStatsPersonal: filter => {
      return request.get(`/transactions/stats/personal?${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getTransactionStatsByBank: (bankId, filter) => {
      return request.get(`/transactions/stats/bank/${bankId}${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getTransactionStatsByBankAndType: (bankId, typesId, filter) => {
      return request.get(
        `/transactions/stats/bank/${bankId}/types/${typesId}${qs.stringify({ ...filter }, { addQueryPrefix: true })}`
      );
    },
    getTransactionFilters: filter => {
      return request.get(`/transactions/filters`);
    },
    getTransactionTotal: filter => {
      return request.get(`/transactions/total${qs.stringify(filter, { addQueryPrefix: true })}`);
    },
    getCashboxTotal: filter => {
      return request.get(`/transactions/cashbox/total${qs.stringify(filter, { addQueryPrefix: true })}`);
    },
    getCashboxStats: filter => {
      return request.get(`/transactions/cashbox/stats${qs.stringify({ ...filter }, { addQueryPrefix: true })}`);
    },
    getCashboxStatsByType: (typesId, filter) => {
      return request.get(
        `/transactions/cashbox/stats/types/${typesId}${qs.stringify({ ...filter }, { addQueryPrefix: true })}`
      );
    },
    createTransaction: data => request.post(`/transactions`, data),
    createTransfer: data => request.post(`/transactions/transfers`, data),
    createConversion: data => request.post(`/transactions/conversions`, data),
    updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
  banks: {
    getBankList: filter => {
      return request.get(`/banks`);
    },
    getSingleBank: id => {
      return request.get(`/banks/${id}`);
    },
    createBank: data => request.post(`/banks`, data),
    updateBank: data => request.patch(`/banks/${data.id}`, data.data),
    deleteBank: id => request.delete(`/banks/${id}`)
  },
  incomeTypes: {
    getIncomeTypeList: () => {
      return request.get(`/income-types`);
    },
    getSingleIncomeType: id => {
      return request.get(`/income-types/${id}`);
    },
    createIncomeType: data => request.post(`/income-types`, data),
    updateIncomeType: data => request.patch(`/income-types/${data.id}`, data.data),
    deleteIncomeType: id => request.delete(`/income-types/${id}`)
  },
  incomeSources: {
    getIncomeSourceList: () => {
      return request.get(`/income-sources`);
    },
    getSingleIncomeSource: id => {
      return request.get(`/income-sources/${id}`);
    },
    createIncomeSource: data => request.post(`/income-sources`, data),
    updateIncomeSource: data => request.patch(`/income-sources/${data.id}`, data.data),
    deleteIncomeSource: id => request.delete(`/income-sources/${id}`)
  },
  outcomeTypes: {
    getOutcomeTypeList: () => {
      return request.get(`/outcome-types`);
    },
    getSingleOutcomeType: id => {
      return request.get(`/outcome-types/${id}`);
    },
    createOutcomeType: data => request.post(`/outcome-types`, data),
    updateOutcomeType: data => request.patch(`/outcome-types/${data.id}`, data.data),
    deleteOutcomeType: id => request.delete(`/outcome-types/${id}`)
  },
  outcomeSources: {
    getOutcomeSourceList: () => {
      return request.get(`/outcome-sources`);
    },
    getSingleOutcomeSource: id => {
      return request.get(`/outcome-sources/${id}`);
    },
    createOutcomeSource: data => request.post(`/outcome-sources`, data),
    updateOutcomeSource: data => request.patch(`/outcome-sources/${data.id}`, data.data),
    deleteOutcomeSource: id => request.delete(`/outcome-sources/${id}`)
  },
  materials: {
    getMaterialList: filter => request.get(`/materials${qs.stringify({ ...filter }, { addQueryPrefix: true })}`),
    getMaterialInfoList: () => request.get(`/materials/info`),
    getSingleMaterial: id => request.get(`/materials/${id}`),
    createMaterial: data => request.post(`/materials`, data),
    updateMaterial: data => request.patch(`/materials/${data.id}`, data.data),
    deleteMaterial: id => request.delete(`/materials/${id}`),
    getMaterialFilters: () => request.get(`/materials/filters`),
    getMaterialsTotal: filter =>
      request.get(`/materials/total${qs.stringify({ ...filter }, { addQueryPrefix: true })}`),
    types: {
      getMaterialTypeList: () => request.get(`/material-types`),
      createMaterialType: data => request.post(`/material-types`, data),
      deleteMaterialType: id => request.delete(`/material-types/${id}`),
      updateMaterialType: data => request.patch(`/material-types/${data.id}`, data.data)
    },
    names: {
      getMaterialNameList: () => request.get(`/names`),
      createMaterialName: data => request.post(`/names`, data),
      deleteMaterialName: id => request.delete(`/names/${id}`),
      updateMaterialName: data => request.patch(`/names/${data.id}`, data.data)
    },
    spendingSources: {
      getMaterialSpendingSourceList: () => request.get(`/material-spending-sources`),
      createMaterialSpendingSource: data => request.post(`/material-spending-sources`, data),
      updateMaterialSpendingSource: data => request.patch(`/material-spending-sources/${data.id}`, data.data),
      deleteMaterialSpendingSource: id => request.delete(`/material-spending-sources/${id}`)
    },
    spendings: {
      getMaterialSpendingList: () => request.get(`/material-spendings`),
      createMaterialSpending: data => request.post(`/material-spendings`, data),
      updateMaterialSpending: data => request.patch(`/material-spendings/${data.id}`, data.data),
      deleteMaterialSpending: id => request.delete(`/material-spendings/${id}`)
    }
  },
  products: {
    getProductList: filter => {
      console.log(filter);
      return request.get(`/products?${qs.stringify({ ...filter })}`);
    },
    getProductInfoList: () => request.get(`/products/info`),
    getSingleProduct: id => request.get(`/products/${id}`),
    createProduct: data => request.post(`/products`, data),
    updateProduct: data => request.patch(`/products/${data.id}`, data.data),
    deleteProduct: id => request.delete(`/products/${id}`),
    getProductStats: params => request.get(`/products/stats${params || ''}`),
    forms: {
      getProductFormList: () => request.get(`/product-forms`),
      createProductForm: data => request.post(`/product-forms`, data),
      updateProductForm: data => request.patch(`/product-forms/${data.id}`, data.data),
      deleteProductForm: id => request.delete(`/product-forms/${id}`)
    },
    calibers: {
      getProductCaliberList: () => request.get(`/calibers`),
      createProductCaliber: data => request.post(`/calibers`, data),
      updateProductCaliber: data => request.patch(`/calibers/${data.id}`, data.data),
      deleteProductCaliber: id => request.delete(`/calibers/${id}`)
    },
    packagingTypes: {
      getProductPackagingTypeList: () => request.get(`/packaging-types`),
      createProductPackagingType: data => request.post(`/packaging-types`, data),
      updateProductPackagingType: data => request.patch(`/packaging-types/${data.id}`, data.data),
      deleteProductPackagingType: id => request.delete(`/packaging-types/${id}`)
    }
  },
  warehouse: {
    getGrandTotal: () => request.get(`/warehouse/grand`),
    getByStore: factoryId => request.get(`/warehouse/store${qs.stringify({ factoryId }, { addQueryPrefix: true })}`),
    getByName: ({ factoryId, storeId }) =>
      request.get(`/warehouse/name${qs.stringify({ factoryId, storeId }, { addQueryPrefix: true })}`),
    getByForm: ({ factoryId, storeId, nameId }) =>
      request.get(`/warehouse/form${qs.stringify({ factoryId, storeId, nameId }, { addQueryPrefix: true })}`),
    getByCaliber: ({ factoryId, storeId, nameId, formId }) =>
      request.get(`/warehouse/caliber${qs.stringify({ factoryId, storeId, nameId, formId }, { addQueryPrefix: true })}`)
  },
  factories: {
    getFactoriesList: () => request.get(`/factories`),
    createFactory: data => request.post(`/factories`, data),
    updateFactory: data => request.patch(`/factories/${data.id}`, data.data),
    deleteFactory: id => request.delete(`/factories/${id}`)
  },
  stores: {
    getStoreList: () => request.get(`/stores`),
    createStore: data => request.post(`/stores`, data),
    updateStore: data => request.patch(`/stores/${data.id}`, data.data),
    deleteStore: id => request.delete(`/stores/${id}`)
  },
  shipments: {
    getShipmentList: () => request.get(`/shipments`),
    createShipment: data => request.post(`/shipments`, data),
    updateShipment: data => request.patch(`/shipments/${data.id}`, data.data),
    deleteShipment: id => request.delete(`/shipments/${id}`)
  },
  contracts: {
    getContractList: filter => request.get(`/contracts${qs.stringify({ ...filter }, { addQueryPrefix: true })}`),
    getSingleContract: id => request.get(`/contracts/${id}`),
    createContract: data => request.post(`/contracts`, data),
    updateContract: data => request.patch(`/contracts/${data.id}`, data.data),
    deleteContract: id => request.delete(`/contracts/${id}`)
  },
  countries: {
    getCountryList: () => request.get(`/countries`),
    createCountry: data => {
      console.log('data => ', data);
      return request.post(`/countries`, data);
    },
    updateCountry: data => request.patch(`/countries/${data.id}`, data.data),
    deleteCountry: id => request.delete(`/countries/${id}`)
  },
  cities: {
    getCityList: () => request.get(`/cities`),
    createCity: data => request.post(`/cities`, data),
    updateCity: data => request.patch(`/cities/${data.id}`, data.data),
    deleteCity: id => request.delete(`/cities/${id}`)
  },
  regions: {
    getRegionList: () => request.get(`/regions`),
    createRegion: data => request.post(`/regions`, data),
    updateRegion: data => request.patch(`/regions/${data.id}`, data.data),
    deleteRegion: id => request.delete(`/regions/${id}`)
  },
  currency: {
    getCurrencyList: () => request.get(`/currencies`),
    createCurrency: data => request.post(`/currencies`, data),
    updateCurrency: data => request.patch(`/currencies/${data.id}`, data.data),
    deleteCurrency: id => request.delete(`/currencies/${id}`)
  },
  paymethods: {
    getPaymethodList: () => request.get(`/paymethods`),
    createPaymethod: data => request.post(`/paymethods`, data),
    updatePaymethod: data => request.patch(`/paymethods/${data.id}`, data.data),
    deletePaymethod: id => request.delete(`/paymethods/${id}`)
  },
  companies: {
    getCompanyList: () => request.get(`/companies`),
    createCompany: data => request.post(`/companies`, data),
    updateCompany: data => request.patch(`/companies/${data.id}`, data.data),
    deleteCompany: id => request.delete(`/companies/${id}`)
  },
  names: {
    getNameList: () => request.get(`/names`)
  },
  subnames: {
    getSubnameList: () => request.get(`/subnames`)
  },
  spendings: {
    getSpendingList: () => request.get(`/spendings`),
    createSpending: data => request.post(`/spendings`, data),
    deleteSpending: id => request.delete(`/spendings/${id}`),
    updateSpending: data => request.patch(`/spendings/${data.id}`, data.data),
    getSpendingStats: filter => request.get(`/spendings/stats${qs.stringify({ ...filter }, { addQueryPrefix: true })}`),
    getSpendingStatsByType: ({ typeId, filter }) =>
      request.get(`/spendings/stats/typeId/${typeId}${qs.stringify({ ...filter }, { addQueryPrefix: true })}`),
    spendingTypes: {
      getSpendingTypeList: () => request.get(`/spending-types`)
    },
    spendingSources: {
      getSpendingSourceList: () => request.get(`/spending-sources`),
      createSpendingSource: data => request.post(`/spending-sources`, data),
      deleteSpendingSource: id => request.delete(`/spending-sources/${id}`),
      updateSpendingSource: data => request.patch(`/spending-sources/${data.id}`, data.data)
    }
  },
  expenses: {
    getExpenseList: filter => request.get(`/expenses${qs.stringify({ ...filter }, { addQueryPrefix: true })}`),
    createExpense: data => request.post(`/expenses`, data),
    deleteExpense: id => request.delete(`/expenses/${id}`),
    updateExpense: data => request.patch(`/expenses/${data.id}`, data.data),
    getExpenseStats: () => request.get(`/expenses/stats`)
  },
  balance: {
    getCashboxTotal: () => request.get(`/balance/cashbox`)
  },
  refunds: {
    getRefundList: () => request.get(`/refunds`),
    createRefund: data => request.post(`/refunds`, data),
    deleteRefund: id => request.delete(`/refunds/${id}`),
    updateRefund: data => request.patch(`/refunds/${data.id}`, data.data)
  },
  filters: {
    getFilterList: () => request.get(`/filters`)
  }
};

export default { request, subscribe, requests };
