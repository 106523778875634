
export const numberWith = (x, replaceValue, defaultValue) => {
    const defVal = defaultValue || "";

    return (x !== "0") ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replaceValue) : defVal;
}


export const normalInteger = (str, max) => {
    str = str.replace(/\s/g, '')
    str = str?.toString().trim();

    if (!str) {
        return "";
    }
    str = str.replace(/^0+/, "") || "0";
    const n = Math.floor(Number(str));
    if (String(n) === str && n >= 0) {
        return str
    }

    return str.replace(/\D/g,'')
}
