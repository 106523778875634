const APP_NAME = 'dashboard';

export * from './roles';

// AUTH
export const SET_AUTH_CREDENTIALS = `${APP_NAME}/SET_AUTH_CREDENTIALS`;
export const SET_AUTH_TOKEN = `${APP_NAME}/SET_AUTH_TOKEN`;
export const REFRESH_ACCESS_TOKEN = `${APP_NAME}/REFRESH_ACCESS_TOKEN`;

export const CHANGE_LANGUAGE = `${APP_NAME}/CHANGE_LANGUAGE`;

export const CLEAR_ON_SIGNOUT = `${APP_NAME}/CLEAR_ON_SIGNOUT`;

export const LOCALE_DATE = {
  lang: {
    locale: 'ru_RU',
    placeholder: 'Выбрать дату',
    rangePlaceholder: ['С', 'По'],
    today: 'Сегодня',
    now: 'Сейчас',
    backToToday: 'Назад на сегодня',
    ok: 'Ok',
    clear: 'Очистить',
    month: 'Месяц',
    year: 'Год',
    timeSelect: 'Select time',
    dateSelect: 'Выбрать дату',
    monthSelect: 'Выбрать месяц',
    yearSelect: 'Выбрать год',
    decadeSelect: 'Выбрать декаду',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Previous month (PageUp)',
    nextMonth: 'Next month (PageDown)',
    previousYear: 'Last year (Control + left)',
    nextYear: 'Next year (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century'
  },
  timePickerLocale: {
    placeholder: 'Select time'
  },
  dateFormat: 'DD-MM-YYYY',
  dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM'
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};
