import React from 'react';
import { Space, Flex } from 'antd';

import './LoginPage.css';
import LoginForm from 'pages/LoginPage/form/LoginForm';
import Logo from 'components/Logo';

const LoginPage = () => {
  return (
    <div className="login-page">
      <Flex vertical align="center">
        <div className="login-page__top">
          <Flex align="center">
            <Space align="center">
              <Logo />
            </Space>
          </Flex>
        </div>
        <LoginForm />
      </Flex>
    </div>
  );
};

export default LoginPage;
