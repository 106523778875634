import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker } from 'antd';

const DatePickerFormItem = ({ name, label, rules, ...rest }) => {
  return (
    <Form.Item name={name} label={label} rules={rules} {...rest}>
      <DatePicker style={{ width: '100%' }} />
    </Form.Item>
  );
};

DatePickerFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.array
};

DatePickerFormItem.defaultProps = {
  rules: []
};

export default DatePickerFormItem;
