import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Statistic,
  Col,
  Table,
  Tag,
  Menu,
  Dropdown,
  Popover
} from 'antd';
import * as Yup from 'yup';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { useFormik } from 'formik';
import { getNested } from '../../utils/getNested';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { notification } from 'antd';
import { renderStatus } from '../../utils/renderStatus';
import { numberWith } from 'utils/numberWith';
import moment from 'moment';
import useQueryParams from 'hooks/useQueryParams';
import useFilters from 'hooks/useFilters';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import { PlusSquareOutlined } from '@ant-design/icons';
import CardHeader from 'components/CardHeader';

const MaterialsPage = () => {
  const queryParams = useQueryParams();
  const {
    list: filtersList,
    menuItems,
    values: filterValues,
    loading: filtersLoading
  } = useFilters({ fields: {}, hasRange: false });
  const { names, subnames, stores, cities, regions } = filtersList;
  const { nameId, storeId, cityId, regionId, userId, startDate, endDate, type, status: statusId } = filterValues;

  const history = useHistory();
  const [state, setState] = useState({
    addModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null,
    material: null,
    addSpendingModalVisible: false,
    deleteSpendingModalVisible: false,
    deletingSpendingItem: null,
    editingSpendingItem: null
  });
  // Cache
  const cache = useQueryCache();

  const materialsRes = useQuery(['materials', { ...filterValues }], () =>
    api.requests.materials.getMaterialList(filterValues)
  );
  const materials = materialsRes?.data?.data?.data;
  const materialsCount = materialsRes?.data?.data?.count;
  const materialsPage = parseInt(materialsRes?.data?.data?.page);
  const users = useQuery('users', () => api.requests.users.getUserList('user'));

  const materialsTotalRes = useQuery(['materialsTotal', { ...filterValues }], () =>
    api.requests.materials.getMaterialsTotal({
      nameId: nameId || undefined,
      storeId: storeId || undefined,
      cityId: cityId || undefined,
      regionId: regionId || undefined,
      userId: userId || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      type: type || undefined,
      status: statusId || undefined
    })
  );
  const materialsTotal = materialsTotalRes?.data?.data?.data;
  const spendingSources = useQuery(
    'materialSpendingSources',
    api.requests.materials.spendingSources.getMaterialSpendingSourceList
  );

  // Mutations
  const [addMaterial, addMaterialInfo] = useMutation(
    state.editingItem ? api.requests.materials.updateMaterial : api.requests.materials.createMaterial,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem
            ? `Закуп успешно сохранён`
            : `Закуп успешно добавлен: ${getNested(mes, 'data', 'data', 'data', 'material', 'customId')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('materials');
        cache.invalidateQueries('materialsTotal');
      }
    }
  );

  const [addTrip, addTripInfo] = useMutation(
    state.editingItem ? api.requests.materials.updateMaterial : api.requests.materials.createMaterial,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem
            ? `Поездка успешно сохранена`
            : `Поездка успешно добавлен: ${getNested(mes, 'data', 'data', 'data', 'material', 'customId')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('materials');
      }
    }
  );

  const [deleteMaterial, deleteMaterialInfo] = useMutation(api.requests.materials.deleteMaterial, {
    onSuccess: () => {
      notification.success({
        message: `Закуп успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      formik.resetForm();
      cache.invalidateQueries('materials');
    }
  });

  // Mutations
  const [addSpending, addSpendingInfo] = useMutation(
    state.editingSpendingItem
      ? api.requests.materials.spendings.updateMaterialSpending
      : api.requests.materials.spendings.createMaterialSpending,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem
            ? `Расход успешно сохранён`
            : `Расход успешно добавлен: ${getNested(mes, 'data', 'data', 'data', 'material', 'id')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addSpendingModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('materialSpendings');
        cache.invalidateQueries('materials');
      }
    }
  );

  const [deleteSpending, deleteSpendingInfo] = useMutation(api.requests.materials.spendings.deleteMaterialSpending, {
    onSuccess: () => {
      notification.success({
        message: `Расход успешно удалён`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteSpendingModalVisible: false
      });
      spendingForm.resetForm();
      cache.invalidateQueries('materialSpendings');
      cache.invalidateQueries('materials');
    }
  });

  const expandedRowRender = item => {
    const columns = [
      {
        title: 'Операция',
        dataIndex: 'productProcess',
        render: productProcess => (
          <Tag color={'geekblue'} key={'tag'}>
            {renderStatus(productProcess).toUpperCase()}
          </Tag>
        )
      },
      { title: 'Обьём', dataIndex: 'amount' },
      {
        title: 'Работник',
        dataIndex: 'creator',
        render: creator =>
          `${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`
      },
      { title: 'Время', dataIndex: 'createdAt' }
    ];

    const spendingsColumns = [
      {
        title: 'Наименование',
        dataIndex: 'materialSpendingSource',
        render: materialSpendingSource => materialSpendingSource.name
      },
      { title: 'Сумма', dataIndex: 'amount' },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                startEditingSpending(record);
                setState({
                  ...state,
                  addSpendingModalVisible: true,
                  editingSpendingItem: record
                });
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => {
                setState({
                  ...state,
                  deleteSpendingModalVisible: true,
                  deletingSpendingItem: record
                });
              }}
            />
          </Space>
        )
      }
    ];

    return (
      <Fragment>
        <br />
        Расходы
        <Table
          columns={spendingsColumns}
          bordered
          dataSource={item?.spendings?.length ? item.spendings : []}
          pagination={false}
        />
        <br />
        Переработка
        <Table
          columns={columns}
          bordered
          dataSource={item && item.products && item.products.length ? item.products : []}
          pagination={false}
        />
      </Fragment>
    );
  };

  const startEditing = item => {
    formik.values.status = item.status;
    formik.values.storeId = item.storeId;
    formik.values.creatorId = item.creator && item.creator.id;
    formik.values.nameId = item.nameId;
    formik.values.subnameId = item.subnameId;
    formik.values.cityId = item.cityId;
    formik.values.regionId = item.regionId;
    formik.values.district = item.district;
    formik.values.type = item.type;
    formik.values.pricePerKg = item.pricePerKg;
    formik.values.price = item.price;
    formik.values.totalAmount = item.totalAmount;
    formik.values.discount = item.discount;
    formik.values.driverName = item.driverName;
    formik.values.carNumber = item.carNumber;
    formik.values.driverPhoneNumber = item.driverPhoneNumber;
  };

  const clearForm = () => {
    formik.resetForm();
    formik.values.status = '';
    formik.values.storeId = '';
    formik.values.creatorId = '';
    formik.values.nameId = '';
    formik.values.subnameId = '';
    formik.values.cityId = '';
    formik.values.regionId = '';
    formik.values.district = '';
    formik.values.type = '';
    formik.values.pricePerKg = '';
    formik.values.price = '';
    formik.values.totalAmount = '';
    formik.values.discount = '';
    formik.values.driverName = '';
    formik.values.carNumber = '';
    formik.values.driverPhoneNumber = '';
  };

  const formik = useFormik({
    initialValues: {
      status: '',
      storeId: '',
      creatorId: '',
      nameId: '',
      subnameId: '',
      cityId: '',
      regionId: '',
      district: '',
      type: '',
      pricePerKg: '',
      price: '',
      totalAmount: '',
      discount: '',
      driverName: '',
      carNumber: '',
      driverPhoneNumber: ''
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Обязательное поле'),
      storeId: Yup.number().required('Обязательное поле'),
      nameId: Yup.number().required('Обязательное поле'),
      // subnameId: Yup.number()
      //     .required('Обязательное поле'),
      cityId: Yup.string().required('Обязательное поле'),
      regionId: Yup.string().required('Обязательное поле'),
      type: Yup.string().required('Обязательное поле'),
      pricePerKg: Yup.string().required('Обязательное поле'),
      price: Yup.string().required('Обязательное поле'),
      totalAmount: Yup.string().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingItem) {
        data = {
          id: state.editingItem.id,
          data: values
        };
      }
      addMaterial(data);
    }
  });

  const startEditingSpending = item => {
    spendingForm.values.materialSpendingSourceId = item.materialSpendingSourceId;
    spendingForm.values.amount = item.amount || '';
    spendingForm.values.date = item.date || '';
    spendingForm.values.comment = item.comment || '';
    spendingForm.values.creatorId = item.creatorId || '';
  };

  const clearSpendingForm = () => {
    spendingForm.resetForm();
    spendingForm.values.materialSpendingSourceId = '';
    spendingForm.values.amount = '';
    spendingForm.values.date = '';
    spendingForm.values.comment = '';
    spendingForm.values.creatorId = '';
  };

  const spendingForm = useFormik({
    initialValues: {
      date: '',
      materialSpendingSourceId: '',
      amount: '',
      comment: '',
      creatorId: ''
    },
    validationSchema: Yup.object({
      materialSpendingSourceId: Yup.number().required('Обязательное поле'),
      amount: Yup.number().required('Обязательное поле'),
      creatorId: Yup.number().required('Обязательное поле')
    }),
    onSubmit: values => {
      const { date, materialSpendingSourceId, amount, comment, creatorId } = values;

      let data = {
        materialId: state.material?.id,
        date: date || new Date(),
        materialSpendingSourceId: materialSpendingSourceId || undefined,
        amount,
        comment: comment || undefined,
        creatorId: creatorId || undefined
      };

      if (state.editingSpendingItem) {
        data = {
          id: state.editingSpendingItem.id,
          materialId: state.editingSpendingItem.materialId,
          data: values
        };
      }

      console.log('data => ', data);
      addSpending(data);
    }
  });

  const materialColumns = [
    { title: 'id', dataIndex: 'id', value: item => item.id },
    {
      title: 'ID',
      render: material => {
        const { creator } = material;
        return material.customId ? (
          <Popover
            content={`${creator && creator.lastName} ${creator && creator.firstName} ${creator && creator.middleName}`}
            title="Ф.И.О"
            trigger="hover"
          >
            <Button
              type="link"
              size="medium"
              style={{ paddingLeft: 0 }}
              onClick={() => history.push(`/users/${creator.id}`)}
            >
              {material.customId}
            </Button>
          </Popover>
        ) : (
          '-'
        );
      },
      value: item => item.customId
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      render: (createdAt, record) => {
        return (
          <Fragment>
            {createdAt ? moment(createdAt).format('DD.MM.YYYY') : ''} <br />
            Получено: {record.receivedAt ? moment(record.receivedAt).format('DD.MM.YYYY') : ''}
          </Fragment>
        );
      },
      value: item => item.createdAt
    },
    {
      title: 'Продукт',
      dataIndex: 'name',
      render: name => name && name.name,
      value: item => item.name?.name
    },
    {
      title: 'Сорт',
      dataIndex: 'subname',
      render: subname => subname && subname.name,
      value: item => item.subname?.name
    },
    {
      title: 'Склад',
      dataIndex: 'store',
      render: store => store && store.name,
      value: item => item.store?.name
    },
    {
      title: 'Цена за кг',
      dataIndex: 'pricePerKg',
      render: pricePerKg => `${numberWith(pricePerKg, ' ')} сум`,
      value: item => item.pricePerKg
    },
    {
      title: 'Цена продукта',
      dataIndex: 'price',
      render: price => `${numberWith(price, ' ')} сум`,
      value: item => item.price
    },
    {
      title: 'Сумма Расходов',
      dataIndex: 'spendingTotal',
      render: spendingTotal => `${numberWith(spendingTotal, ' ')} сум`,
      value: item => item.spendingTotal
    },
    {
      title: 'Общая Стоимость Закупа',
      dataIndex: 'totalCost',
      render: totalCost => `${numberWith(totalCost, ' ')} сум`,
      value: item => item.totalCost
    },
    {
      title: 'Обьём (без скидки)',
      dataIndex: 'purchasedAmount',
      render: purchasedAmount => `${numberWith(purchasedAmount, ' ')}кг`,
      value: item => item.purchasedAmount
    },
    {
      title: 'Скидка',
      dataIndex: 'discount',
      render: (item, _record) => `${_record.discount}кг (${(_record.discount / _record.totalAmount) * 100}%)`
    },
    {
      title: 'Обьём (общий)',
      dataIndex: 'totalAmount',
      render: totalAmount => `${numberWith(totalAmount, ' ')}кг`,
      value: item => item.totalAmount
    },

    {
      title: 'Статус',
      dataIndex: 'status',
      render: status => (
        <Tag color={'green'} key={'tag'}>
          {status ? renderStatus(status).toUpperCase() : ''}
        </Tag>
      ),
      value: item => renderStatus(item.status)
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      value: item => item.description
    }
  ];

  const actionColumn = {
    title: 'Действия',
    dataIndex: 'actions',
    render: (_, record) => (
      <Space>
        <Button
          type="link"
          icon={<PlusSquareOutlined />}
          onClick={() => {
            setState({
              ...state,
              addSpendingModalVisible: true,
              material: record
            });
          }}
        />
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            startEditing(record);
            setState({
              ...state,
              addModalVisible: true,
              editingItem: record
            });
          }}
        />
        <Button
          type="link"
          icon={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => {
            setState({
              ...state,
              deleteModalVisible: true,
              deletingItem: record
            });
          }}
        />
      </Space>
    )
  };

  const onClickAdd = () => {
    clearForm();
    setState({
      ...state,
      addModalVisible: true,
      editingItem: null
    });
  };

  return (
    <>
      <CardHeader title="Закуп" hasButton onButtonClick={onClickAdd} />

      <Spin spinning={filtersLoading}>{menuItems}</Spin>
      <br />
      {materialsTotal ? (
        <Row>
          <Col span={6}>
            <Card>
              <Statistic title="Объём (кг)" value={materialsTotal?.total_amount || 0} />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic title="Стоимость (сум)" value={materialsTotal?.total_price || 0} />
            </Card>
          </Col>
        </Row>
      ) : null}
      <br />
      <Card>
        Общее количество элементов: <b>{materialsCount}</b>
        <Table
          rowKey="id"
          size="middle"
          bordered
          loading={materialsRes?.isLoading}
          expandable={{ expandedRowRender }}
          style={{ overflowX: 'scroll' }}
          columns={[...materialColumns, actionColumn]}
          dataSource={materials}
          pagination={{
            size: 'default',
            position: ['topRight'],
            total: materialsCount,
            defaultCurrent: materialsPage || 1,
            current: materialsPage,
            onChange: page => queryParams.set('page', page)
          }}
        />
      </Card>
      <Modal
        title={state.editingItem ? `Изменение закупа: ${state.editingItem.customId}` : 'Добавление закупа'}
        visible={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Закупщик"
            validateStatus={formik.touched.creatorId && formik.errors.creatorId ? 'error' : 'validating'}
            help={formik.touched.creatorId && formik.errors.creatorId}
          >
            <Select
              id="creatorId"
              name="creatorId"
              value={formik.values.creatorId}
              onChange={value => formik.setFieldValue('creatorId', value)}
            >
              {getNested(users, 'data', 'data', 'data') &&
                getNested(users, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.fullName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Склад"
            validateStatus={formik.touched.storeId && formik.errors.storeId ? 'error' : 'validating'}
            help={formik.touched.storeId && formik.errors.storeId}
          >
            <Select
              id="storeId"
              name="storeId"
              value={formik.values.storeId}
              onChange={value => formik.setFieldValue('storeId', value)}
            >
              {stores &&
                stores.map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Товар"
            validateStatus={formik.touched.nameId && formik.errors.nameId ? 'error' : 'validating'}
            help={formik.touched.nameId && formik.errors.nameId}
          >
            <Select
              id="nameId"
              name="nameId"
              value={formik.values.nameId}
              onChange={value => {
                formik.setFieldValue('nameId', value);
                formik.setFieldValue('subnameId', '');
              }}
            >
              {names &&
                names.map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Сорт"
            validateStatus={formik.touched.subnameId && formik.errors.subnameId ? 'error' : 'validating'}
            help={formik.touched.subnameId && formik.errors.subnameId}
          >
            <Select
              id="subnameId"
              name="subnameId"
              value={formik.values.subnameId}
              onChange={value => {
                formik.setFieldValue('subnameId', value);
              }}
            >
              {subnames &&
                subnames
                  .filter(item => item.parentId == formik.values.nameId)
                  .map((item, id) => {
                    return (
                      <Select.Option value={item.id} key={id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Тип товара"
            validateStatus={formik.touched.type && formik.errors.type ? 'error' : 'validating'}
            help={formik.touched.type && formik.errors.type}
          >
            <Select
              id="type"
              name="type"
              value={formik.values.type}
              onChange={value => {
                formik.setFieldValue('type', value);
              }}
            >
              <Select.Option value={'ready'}>Готовый</Select.Option>
              <Select.Option value={'raw'}>Сырьё</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Статус"
            validateStatus={formik.touched.status && formik.errors.status ? 'error' : 'validating'}
            help={formik.touched.status && formik.errors.status}
          >
            <Select
              id="status"
              name="status"
              value={formik.values.status}
              onChange={value => {
                formik.setFieldValue('status', value);
              }}
            >
              <Select.Option value={'sent'}>В пути</Select.Option>
              <Select.Option value={'store'}>Получено</Select.Option>
              <Select.Option value={'produced'}>Переработано</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Город"
            validateStatus={formik.touched.cityId && formik.errors.cityId ? 'error' : 'validating'}
            help={formik.touched.cityId && formik.errors.cityId}
          >
            <Select
              id="cityId"
              name="cityId"
              value={formik.values.cityId}
              onChange={value => {
                formik.setFieldValue('cityId', value);
                formik.setFieldValue('subnameId', '');
              }}
            >
              {cities &&
                cities.map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Район"
            validateStatus={formik.touched.regionId && formik.errors.regionId ? 'error' : 'validating'}
            help={formik.touched.regionId && formik.errors.regionId}
          >
            <Select
              id="regionId"
              name="regionId"
              value={formik.values.regionId}
              onChange={value => {
                formik.setFieldValue('regionId', value);
                formik.setFieldValue('cityId', '');
              }}
            >
              {regions &&
                regions.map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Цена за кг"
            validateStatus={formik.touched.pricePerKg && formik.errors.pricePerKg ? 'error' : 'validating'}
            help={formik.touched.pricePerKg && formik.errors.pricePerKg}
          >
            <Input
              name="pricePerKg"
              type="number"
              min={0}
              placeholder="Цена за кг"
              value={formik.values.pricePerKg}
              onChange={formik.handleChange}
              id="pricePerKg"
            />
          </Form.Item>
          <Form.Item
            label="Цена (общая)"
            validateStatus={formik.touched.price && formik.errors.price ? 'error' : 'validating'}
            help={formik.touched.price && formik.errors.price}
          >
            <Input
              name="price"
              type="number"
              min={0}
              placeholder="Цена (общая)"
              value={formik.values.price}
              onChange={formik.handleChange}
              id="price"
            />
          </Form.Item>
          <Form.Item
            label="Обьём"
            validateStatus={formik.touched.totalAmount && formik.errors.totalAmount ? 'error' : 'validating'}
            help={formik.touched.totalAmount && formik.errors.totalAmount}
          >
            <Input
              name="totalAmount"
              type="number"
              min={0}
              placeholder="Обьём"
              value={formik.values.totalAmount}
              onChange={formik.handleChange}
              id="totalAmount"
            />
          </Form.Item>
          <Form.Item
            label="Скидка"
            validateStatus={formik.touched.discount && formik.errors.discount ? 'error' : 'validating'}
            help={formik.touched.discount && formik.errors.discount}
          >
            <Input
              name="discount"
              type="number"
              min={0}
              max={100}
              placeholder="Скидка"
              value={formik.values.discount}
              onChange={formik.handleChange}
              id="discount"
            />
          </Form.Item>
          <Form.Item label="Дата">
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Водитель"
            validateStatus={formik.touched.driverName && formik.errors.driverName ? 'error' : 'validating'}
            help={formik.touched.driverName && formik.errors.driverName}
          >
            <Input
              name="driverName"
              placeholder="ФИО Водителя"
              value={formik.values.driverName}
              onChange={formik.handleChange}
              id="driverName"
            />
          </Form.Item>
          <Form.Item
            label="Номер авто"
            validateStatus={formik.touched.carNumber && formik.errors.carNumber ? 'error' : 'validating'}
            help={formik.touched.carNumber && formik.errors.carNumber}
          >
            <Input
              name="carNumber"
              placeholder="Номер транспорта"
              value={formik.values.carNumber}
              onChange={formik.handleChange}
              id="carNumber"
            />
          </Form.Item>
          <Form.Item
            label="Тел Номер"
            validateStatus={
              formik.touched.driverPhoneNumber && formik.errors.driverPhoneNumber ? 'error' : 'validating'
            }
            help={formik.touched.driverPhoneNumber && formik.errors.driverPhoneNumber}
          >
            <Input
              name="driverPhoneNumber"
              placeholder="Номер телефона"
              value={formik.values.driverPhoneNumber}
              onChange={formik.handleChange}
              id="driverPhoneNumber"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={formik.handleSubmit}
              disabled={addMaterialInfo && addMaterialInfo.isLoading}
              loading={addMaterialInfo && addMaterialInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление закупа: ${state.deletingItem && state.deletingItem.customId}`}
        visible={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        Вы уверены что хотите удалить закуп <b>{state.deletingItem && state.deletingItem.customId}</b> ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteMaterial(state.deletingItem.id);
          }}
          disabled={deleteMaterialInfo && deleteMaterialInfo.isLoading}
          loading={deleteMaterialInfo && deleteMaterialInfo.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
      <Modal
        title={state.editingSpendingItem ? `Изменение расхода: ${state.editingSpendingItem?.id}` : 'Добавление расхода'}
        visible={state.addSpendingModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addSpendingModalVisible: false,
            editingSpendingItem: null
          });
          clearSpendingForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item label="Дата">
            <DatePicker
              value={moment(spendingForm.values.date || new Date())}
              onChange={(obj, datestring) => spendingForm.setFieldValue('date', datestring)}
            />
          </Form.Item>
          <Form.Item
            label="Создатель"
            validateStatus={spendingForm.touched.creatorId && spendingForm.errors.creatorId ? 'error' : 'validating'}
            help={spendingForm.touched.creatorId && spendingForm.errors.creatorId}
          >
            <Select
              id="creatorId"
              name="creatorId"
              value={spendingForm.values.creatorId}
              onChange={value => spendingForm.setFieldValue('creatorId', value)}
            >
              {getNested(users, 'data', 'data', 'data') &&
                getNested(users, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.fullName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Тип расхода"
            validateStatus={
              spendingForm.touched.materialSpendingSourceId && spendingForm.errors.materialSpendingSourceId
                ? 'error'
                : 'validating'
            }
            help={spendingForm.touched.materialSpendingSourceId && spendingForm.errors.materialSpendingSourceId}
          >
            <Select
              id="materialSpendingSourceId"
              name="materialSpendingSourceId"
              value={spendingForm.values.materialSpendingSourceId}
              onChange={value => spendingForm.setFieldValue('materialSpendingSourceId', value)}
            >
              {spendingSources?.data?.data?.data &&
                spendingSources?.data?.data?.data.map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Сумма"
            validateStatus={spendingForm.touched.amount && spendingForm.errors.amount ? 'error' : 'validating'}
            help={spendingForm.touched.amount && spendingForm.errors.amount}
          >
            <Input
              name="amount"
              type="number"
              min={0}
              max={100}
              placeholder="Сумма"
              value={spendingForm.values.amount}
              onChange={spendingForm.handleChange}
              id="amount"
            />
          </Form.Item>

          <Row justify="space-between">
            <Button
              onClick={spendingForm.handleSubmit}
              disabled={addSpendingInfo && addSpendingInfo.isLoading}
              loading={addSpendingInfo && addSpendingInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление расхода: ${state.deletingSpendingItem && state.deletingSpendingItem.id}`}
        visible={state.deleteSpendingModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteSpendingModalVisible: false,
            editingSpendingItem: null,
            deletingSpendingItem: null
          });
          clearSpendingForm();
        }}
        footer={null}
      >
        Вы уверены что хотите удалить расход{' '}
        <b>
          {state.deletingSpendingItem?.materialSpendingSource?.name}: {state.deletingSpendingItem?.amount}
        </b>{' '}
        ?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteSpending(state.deletingSpendingItem.id);
          }}
          disabled={deleteSpendingInfo && deleteSpendingInfo.isLoading}
          loading={deleteSpendingInfo && deleteSpendingInfo.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </>
  );
};

export default MaterialsPage;
