import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Button, Card, Form, Input, Modal, Row, Select, Space, Table } from 'antd';
import * as Yup from 'yup';

import api from '../../services/api/api';
import Spin from '../../components/Spin/Spin';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { useFormik } from 'formik';
import { getNested } from '../../utils/getNested';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { notification } from 'antd';
import { FORM_ITEM_LAYOUT } from 'services/constants';
import CardHeader from 'components/CardHeader';

const { TextArea } = Input;

const CompaniesPage = () => {
  const [state, setState] = useState({
    addModalVisible: false,
    deleteModalVisible: false,
    deletingItem: null,
    editingItem: null
  });
  // Cache
  const cache = useQueryCache();

  const companies = useQuery('companies', api.requests.companies.getCompanyList);
  const countries = useQuery('countries', api.requests.countries.getCountryList);

  // Mutations
  const [addCompany, addCompanyInfo] = useMutation(
    state.editingItem ? api.requests.companies.updateCompany : api.requests.companies.createCompany,
    {
      onSuccess: mes => {
        notification.success({
          message: state.editingItem
            ? `Компания успешно сохранена`
            : `Компания успешно добавлена: ${getNested(mes, 'data', 'data', 'data', 'name')}`,
          placement: 'topRight'
        });
        setState({
          ...state,
          addModalVisible: false
        });
        clearForm();
        cache.invalidateQueries('companies');
      }
    }
  );

  const [deleteCompany, deleteCompanyInfo] = useMutation(api.requests.companies.deleteCompany, {
    onSuccess: () => {
      notification.success({
        message: `Компания успешно удалена`,
        placement: 'topRight'
      });
      setState({
        ...state,
        deleteModalVisible: false
      });
      formik.resetForm();
      cache.invalidateQueries('companies');
    }
  });

  const startEditing = item => {
    formik.values.name = item.name;
    formik.values.countryId = item.countryId;
    formik.values.address = item.address || '';
  };

  const clearForm = () => {
    formik.resetForm();
    formik.values.name = '';
    formik.values.countryId = '';
    formik.values.address = '';
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      countryId: '',
      address: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательное поле'),
      countryId: Yup.string().required('Обязательное поле')
    }),
    onSubmit: values => {
      let data = values;
      if (state.editingItem) {
        data = {
          id: state.editingItem.id,
          data: values
        };
      }
      console.log('values => ', values);
      addCompany(data);
    }
  });

  const onClickAdd = () => {
    clearForm();
    setState({
      ...state,
      addModalVisible: true,
      editingItem: null
    });
  };

  return (
    <Spin spinning={companies && companies.isLoading}>
      <CardHeader title="Компании" hasButton onButtonClick={onClickAdd} />
      <Card>
        <Table
          rowKey="id"
          size="middle"
          bordered
          loading={companies.isLoading || companies.isFetching}
          style={{ overflowX: 'scroll' }}
          columns={[
            { title: 'id', dataIndex: 'id' },
            { title: 'Наименование', dataIndex: 'name' },
            {
              title: 'Страна',
              dataIndex: 'country',
              render: country => country && country.name
            },
            { title: 'Адрес', dataIndex: 'address' },
            {
              title: 'Действия',
              dataIndex: 'actions',
              render: (_, record) => (
                <Space>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      startEditing(record);
                      setState({
                        ...state,
                        addModalVisible: true,
                        editingItem: record
                      });
                    }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={() => {
                      setState({
                        ...state,
                        deleteModalVisible: true,
                        deletingItem: record
                      });
                    }}
                  />
                </Space>
              )
            }
          ]}
          dataSource={getNested(companies, 'data', 'data', 'data')}
        />
      </Card>
      <Modal
        title={state.editingItem ? `Изменение компании: ${state.editingItem.name}` : 'Добавление компании'}
        open={state.addModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            addModalVisible: false,
            editingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        <Form {...FORM_ITEM_LAYOUT}>
          <Form.Item
            label="Название"
            validateStatus={formik.touched.name && formik.errors.name ? 'error' : 'validating'}
            help={formik.touched.name && formik.errors.name}
          >
            <Input
              name="name"
              placeholder="Наименование"
              value={formik.values.name}
              onChange={formik.handleChange}
              id="name"
            />
          </Form.Item>
          <Form.Item
            label="Страна"
            validateStatus={formik.touched.countryId && formik.errors.countryId ? 'error' : 'validating'}
            help={formik.touched.countryId && formik.errors.countryId}
          >
            <Select
              id="countryId"
              name="countryId"
              value={formik.values.countryId}
              onChange={value => formik.setFieldValue('countryId', value)}
            >
              {getNested(countries, 'data', 'data', 'data') &&
                getNested(countries, 'data', 'data', 'data').map((item, id) => {
                  return (
                    <Select.Option value={item.id} key={id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Адрес">
            <TextArea
              rows={4}
              name="address"
              placeholder="Адрес"
              value={formik.values.address}
              onChange={formik.handleChange}
              id="address"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              onClick={formik.handleSubmit}
              disabled={addCompanyInfo && addCompanyInfo.isLoading}
              loading={addCompanyInfo && addCompanyInfo.isLoading}
              type="primary"
            >
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`Удаление компании: ${state.deletingItem && state.deletingItem.name}`}
        open={state.deleteModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setState({
            ...state,
            deleteModalVisible: false,
            editingItem: null,
            deletingItem: null
          });
          clearForm();
        }}
        footer={null}
      >
        Вы уверены что хотите удалить компанию <b>{state.deletingItem && state.deletingItem.name}</b>?
        <br />
        <br />
        <Button
          danger
          onClick={() => {
            deleteCompany(state.deletingItem.id);
          }}
          disabled={deleteCompanyInfo && deleteCompanyInfo.isLoading}
          loading={deleteCompanyInfo && deleteCompanyInfo.isLoading}
          type="primary"
        >
          Удалить
        </Button>
      </Modal>
    </Spin>
  );
};

export default CompaniesPage;
