import React, { Fragment, useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { store } from '../services/store.js';
import AdminLayout from '../layouts/AdminLayout/AdminLayout';
import MaterialsPage from '../pages/MaterialsPage/MaterialsPage';
import api from '../services/api/api';
import RecyclePage from '../pages/RecyclePage/RecyclePage';
import CatalogPage from '../pages/CatalogPage/CatalogPage';
import CompaniesPage from '../pages/CompaniesPage/CompaniesPage';
import UsersPage from '../pages/UsersPage/UsersPage';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import GroupOutlined from '@ant-design/icons/lib/icons/GroupOutlined';
import BankOutlined from '@ant-design/icons/lib/icons/BankOutlined';
import SketchOutlined from '@ant-design/icons/lib/icons/SketchOutlined';
import SwapOutlined from '@ant-design/icons/lib/icons/SwapOutlined';
import UnorderedListOutlined from '@ant-design/icons/lib/icons/UnorderedListOutlined';
import RetweetOutlined from '@ant-design/icons/lib/icons/RetweetOutlined';
import DropboxOutlined from '@ant-design/icons/lib/icons/DropboxOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import PieChartOutlined from '@ant-design/icons/lib/icons/PieChartOutlined';
import InsertRowBelowOutlined from '@ant-design/icons/lib/icons/InsertRowBelowOutlined';
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined';
import ContainerOutlined from '@ant-design/icons/lib/icons/ContainerOutlined';
import CreditCardOutlined from '@ant-design/icons/lib/icons/CreditCardOutlined';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import ShipmentsPage from '../pages/ShipmentsPage/ShipmentsPage';
import ContractsPage from 'pages/ContractsPage/ContractsPage';
import StorePage from 'pages/StorePage/StorePage';
import BanksPage from 'pages/BanksPage/BanksPage';
import TransactionsPage from 'pages/TransactionsPage/TransactionsPage';
import CashboxPage from 'pages/CashboxPage/CashboxPage.js';
import SpendingsPage from 'pages/SpendingsPage/SpendingsPage';
import SpendingStatsPage from 'pages/SpendingStatsPage/SpendingStatsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import MainPage from 'pages/MainPage/MainPage.js';
import SupportPage from 'pages/SupportPage/SupportPage.js';
import TermsOfUsePage from 'pages/TermsOfUsePage/TermsOfUsePage.js';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage.js';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const AltLayout = ({ children }) => <Fragment>{children}</Fragment>;

export const privateRoutes = [
  {
    path: '/',
    exact: true,
    layout: AdminLayout,
    component: () => <Redirect to="/banks" />
  },
  {
    name: 'Счета',
    path: '/banks',
    layout: AdminLayout,
    component: BanksPage,
    icon: <BankOutlined />
  },
  {
    name: 'Касса',
    path: '/cashbox',
    layout: AdminLayout,
    component: CashboxPage,
    icon: <ContainerOutlined />
  },
  {
    name: 'Транзакции',
    path: '/transactions',
    layout: AdminLayout,
    component: TransactionsPage,
    icon: <SwapOutlined />
  },
  {
    name: 'Статистика расходов пользователей',
    path: '/spendings-stats',
    layout: AdminLayout,
    component: SpendingStatsPage,
    icon: <PieChartOutlined />
  },
  {
    name: 'Расходы пользователей',
    path: '/spendings',
    layout: AdminLayout,
    component: SpendingsPage,
    icon: <CreditCardOutlined />
  },
  {
    name: 'Закуп',
    path: '/materials',
    layout: AdminLayout,
    component: MaterialsPage,
    icon: <UnorderedListOutlined />
  },
  {
    name: 'Производство',
    path: '/recycle',
    layout: AdminLayout,
    component: RecyclePage,
    icon: <RetweetOutlined />
  },
  {
    name: 'Продукция',
    path: '/products',
    layout: AdminLayout,
    component: ProductsPage,
    icon: <DropboxOutlined />
  },
  {
    name: 'Склад',
    path: '/store/:nameId?/:subnameId?/:productFormId?/:caliberId?',
    routePath: '/store',
    layout: AdminLayout,
    component: StorePage,
    icon: <HomeOutlined />
  },
  {
    name: 'Отгрузка',
    path: '/shipments',
    layout: AdminLayout,
    component: ShipmentsPage,
    icon: <InsertRowBelowOutlined />
  },
  {
    name: 'Компании',
    path: '/companies',
    layout: AdminLayout,
    component: CompaniesPage,
    icon: <SketchOutlined />
  },
  {
    name: 'Контракты',
    path: '/contracts',
    layout: AdminLayout,
    component: ContractsPage,
    icon: <AuditOutlined />
  },
  {
    name: 'Пользователи',
    path: '/users',
    layout: AdminLayout,
    component: UsersPage,
    icon: <UserOutlined />
  },
  {
    name: 'Каталог',
    path: '/catalog',
    layout: AdminLayout,
    component: CatalogPage,
    icon: <GroupOutlined />
  }
];

const publicRoutes = [
  {
    path: '/',
    exact: true,
    layout: AltLayout,
    component: () => <MainPage />
  },
  {
    path: '/support',
    exact: true,
    layout: AltLayout,
    component: () => <SupportPage />
  },
  {
    path: '/terms-of-use',
    exact: true,
    layout: AltLayout,
    component: () => <TermsOfUsePage />
  },
  {
    path: '/privacy-policy',
    exact: true,
    layout: AltLayout,
    component: () => <PrivacyPolicyPage />
  }
];

const loginRoute = [
  {
    path: '/login',
    exact: true,
    layout: AltLayout,
    component: () => <LoginPage />
  }
];

const Routes = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  api.subscribe(globalState);

  const privateRoutesList = privateRoutes.map((item, id) => {
    return (
      <AppRoute
        key={id}
        exact
        path={item.path}
        layout={item.layout}
        component={item.component}
      />
    );
  });

  const publicRoutesList = publicRoutes.map((item, id) => {
    return (
      <AppRoute
        key={id}
        exact
        path={item.path}
        layout={item.layout}
        component={item.component}
      />
    );
  });

  const loginRoutesList = loginRoute.map((item, id) => {
    return (
      <AppRoute
        key={id}
        exact
        path={item.path}
        layout={item.layout}
        component={item.component}
      />
    );
  });

  return (
    <Fragment>
      <Switch>
        {state?.auth?.accessToken ? privateRoutesList : loginRoutesList}
        {publicRoutesList}
        <Redirect from="*" to="/" />
      </Switch>
    </Fragment>
  );
};

export default withRouter(Routes);
