import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Button, Menu, Dropdown, Row, DatePicker, Card, Space } from 'antd';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import moment from 'moment';
import SwapLeftOutlined from '@ant-design/icons/lib/icons/SwapLeftOutlined';
import SwapRightOutlined from '@ant-design/icons/lib/icons/SwapRightOutlined';

import useQueryParams from 'hooks/useQueryParams';
import api from 'services/api/api';
import { findById } from 'utils/helpers';
import { localeDate } from 'utils/dateLocale';

const { RangePicker } = DatePicker;

const useFilters = ({ fields, hasRange }) => {
  const queryParams = useQueryParams();

  const {
    values,
    values: {
      nameId,
      storeId,
      countryId,
      cityId,
      regionId,
      productFormId,
      productCaliberId,
      payMethodId,
      transactionType: transactionTypeId,
      currencyId,
      bankId,
      incomeTypeId,
      incomeSourceId,
      outcomeTypeId,
      outcomeSourceId,
      spendingSourceId,
      creatorId,
      receiverId,
      startDate,
      endDate,
      contract,
      flow: flowId,
      materialType: materialTypeId,
      status: statusId,
      page
    }
  } = queryParams;

  const filtersRes = useQuery('filters', api.requests.filters.getFilterList);

  const filtersList = filtersRes?.data?.data?.data || {};
  const {
    names,
    stores,
    countries,
    cities,
    regions,
    productForms,
    productCalibers,
    payMethods,
    currencies,
    banks,
    incomeTypes,
    incomeSources,
    outcomeTypes,
    outcomeSources,
    spendingSources,
    users
  } = filtersList;
  const bankList = useMemo(() => {
    return banks
      ? [
          {
            name: 'Все кроме кассы',
            id: 'not-cashbox'
          },
          ...banks
        ]
      : null;
  }, [banks]);

  const flows = useMemo(
    () => [
      {
        name: <SwapLeftOutlined style={{ color: 'green' }} />,
        id: 'in'
      },
      {
        name: <SwapRightOutlined style={{ color: 'red' }} />,
        id: 'out'
      }
    ],
    []
  );

  const transactionTypeList = useMemo(() => {
    const inFLow = [
      {
        name: 'Приход',
        id: 'income'
      },
      {
        name: 'Возврат',
        id: 'refunded'
      }
    ];

    const outFlow = [
      {
        name: 'Расход',
        id: 'outcome'
      }
    ];

    const universal = [
      {
        name: 'Перевод',
        id: 'transferred'
      },
      {
        name: 'Конвертация',
        id: 'converted'
      }
    ];

    if (flowId === 'in') {
      return [...inFLow, ...universal];
    }

    if (flowId === 'out') {
      return [...outFlow, ...universal];
    }

    return [...inFLow, ...outFlow, ...universal];
  }, [flowId]);

  const materialTypes = useMemo(
    () => [
      {
        name: 'Готовый',
        id: 'ready'
      },
      {
        name: 'Сырьё',
        id: 'raw'
      }
    ],
    []
  );

  const statusList = useMemo(
    () => [
      {
        name: 'В пути',
        id: 'sent'
      },
      {
        name: 'Получено',
        id: 'store'
      }
    ],
    []
  );

  const flow = useMemo(() => flows.find(({ id }) => id === flowId), [
    flows,
    flowId
  ]);
  const transactionType = useMemo(
    () => transactionTypeList.find(({ id }) => id === transactionTypeId),
    [transactionTypeList, transactionTypeId]
  );
  const materialType = useMemo(
    () => materialTypes.find(({ id }) => id === materialTypeId),
    [materialTypes, materialTypeId]
  );
  const status = useMemo(() => statusList.find(({ id }) => id === statusId), [
    statusId,
    statusList
  ]);
  const name = useMemo(() => findById(names, nameId), [names, nameId]);
  const store = useMemo(() => findById(stores, storeId), [stores, storeId]);
  const country = useMemo(() => findById(countries, countryId), [
    countries,
    countryId
  ]);
  const city = useMemo(() => findById(cities, cityId), [cities, cityId]);
  const region = useMemo(() => findById(regions, regionId), [
    regions,
    regionId
  ]);
  const productForm = useMemo(() => findById(productForms, productFormId), [
    productForms,
    productFormId
  ]);
  const productCaliber = useMemo(
    () => findById(productCalibers, productCaliberId),
    [productCalibers, productCaliberId]
  );
  const payMethod = useMemo(() => findById(payMethods, payMethodId), [
    payMethods,
    payMethodId
  ]);
  const currency = useMemo(() => findById(currencies, currencyId), [
    currencies,
    currencyId
  ]);
  const bank = useMemo(() => findById(bankList, bankId), [banks, bankId]);
  const creator = useMemo(() => findById(users, creatorId), [users, creatorId]);
  const receiver = useMemo(() => findById(users, receiverId), [
    users,
    receiverId
  ]);
  const incomeType = useMemo(() => findById(incomeTypes, incomeTypeId), [
    incomeTypes,
    incomeTypeId
  ]);
  const incomeSource = useMemo(() => findById(incomeSources, incomeSourceId), [
    incomeSources,
    incomeSourceId
  ]);
  const outcomeType = useMemo(() => findById(outcomeTypes, outcomeTypeId), [
    outcomeTypes,
    outcomeTypeId
  ]);
  const outcomeSource = useMemo(
    () => findById(outcomeSources, outcomeSourceId),
    [outcomeSources, outcomeSourceId]
  );
  const spendingSource = useMemo(
    () => findById(spendingSources, spendingSourceId),
    [spendingSources, spendingSourceId]
  );

  const menuData = [
    {
      id: 'status',
      title: 'Статус',
      list: statusList,
      current: status,
      queryKey: 'status'
    },
    {
      id: 'materialType',
      title: 'Тип Закупа',
      list: materialTypes,
      current: materialType,
      queryKey: 'materialType'
    },
    {
      id: 'flow',
      title: 'Поток',
      list: flows,
      current: flow,
      queryKey: 'flow'
    },
    {
      id: 'transactionType',
      title: 'Тип транзакции',
      list: transactionTypeList,
      current: transactionType,
      queryKey: 'transactionType'
    },
    {
      id: 'name',
      title: 'Товар',
      list: names,
      current: name,
      queryKey: 'nameId'
    },
    {
      id: 'store',
      title: 'Склад',
      list: stores,
      current: store,
      queryKey: 'storeId'
    },
    {
      id: 'country',
      title: 'Страна',
      list: countries,
      current: country,
      queryKey: 'countryId'
    },
    {
      id: 'city',
      title: 'Город',
      list: cities,
      current: city,
      queryKey: 'cityId'
    },
    {
      id: 'region',
      title: 'Регион',
      list: regions,
      current: region,
      queryKey: 'regionId'
    },
    {
      id: 'productForm',
      title: 'Форма продукта',
      list: productForms,
      current: productForm,
      queryKey: 'productFormId'
    },
    {
      id: 'productCaliber',
      title: 'Калибр продукта',
      list: productCalibers,
      current: productCaliber,
      queryKey: 'productCaliberId'
    },
    {
      id: 'payMethod',
      title: 'Способ Оплаты',
      list: payMethods,
      current: payMethod,
      queryKey: 'payMethodId'
    },
    {
      id: 'currency',
      title: 'Валюта',
      list: currencies,
      current: currency,
      queryKey: 'currencyId'
    },
    {
      id: 'bank',
      title: 'Банк',
      list: bankList,
      current: bank,
      queryKey: 'bankId'
    },
    {
      id: 'incomeType',
      title: 'Тип Прихода',
      list: incomeTypes,
      current: incomeType,
      queryKey: 'incomeTypeId'
    },
    {
      id: 'incomeSource',
      title: 'Источник Прихода',
      list: incomeSources,
      current: incomeSource,
      queryKey: 'incomeSourceId'
    },
    {
      id: 'outcomeType',
      title: 'Тип Расхода',
      list: outcomeTypes,
      current: outcomeType,
      queryKey: 'outcomeTypeId'
    },
    {
      id: 'outcomeSource',
      title: 'Источник Расхода',
      list: outcomeSources,
      current: outcomeSource,
      queryKey: 'outcomeSourceId'
    },
    {
      id: 'spendingSource',
      title: 'Тип Других Расходов',
      list: spendingSources,
      current: spendingSource,
      queryKey: 'spendingSourceId'
    },
    {
      id: 'creatorId',
      title: 'Создатель',
      list: users,
      current: creator,
      queryKey: 'creatorId',
      nameKey: 'fullName'
    },
    {
      id: 'receiverId',
      title: 'Получатель',
      list: users,
      current: receiver,
      queryKey: 'receiverId',
      nameKey: 'fullName'
    }
  ];

  const createDropdownMenu = ({
    id,
    title,
    list,
    current,
    queryKey,
    nameKey
  }) => {
    if (!fields[id]) {
      return null;
    }

    const menu = (
      <Menu>
        <Menu.Item
          key="all"
          onClick={() =>
            queryParams.merge({ [queryKey]: undefined, page: undefined })
          }
        >
          Все
        </Menu.Item>
        {list?.length
          ? list.map(item => (
              <Menu.Item
                key={item.id}
                onClick={() =>
                  queryParams.merge({
                    [queryKey]: item.id,
                    page: undefined
                  })
                }
              >
                {item[nameKey || 'name']}
              </Menu.Item>
            ))
          : null}
      </Menu>
    );

    return (
      <Dropdown key={id} overlay={menu} trigger={['click']}>
        <Button type={current ? 'primary' : undefined}>
          {current ? current[nameKey || 'name'] : title} <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const onRangeChange = useCallback(value => {
    const isEmpty = value?.length !== 2;
    queryParams.merge({
      startDate: !isEmpty ? moment(value[0]).format('YYYY-MM-DD') : undefined,
      endDate: !isEmpty ? moment(value[1]).format('YYYY-MM-DD') : undefined
    });
  }, []);

  const dropdownMenuItems = (
    <Card>
      <Row>
        <Space>
          <Button
            type={
              Object.keys(values).length && !page
                ? // !queryParams.values.startDate &&
                  // !queryParams.values.endDate
                  'dashed'
                : undefined
            }
            onClick={() =>
              queryParams.clear({
                startDate,
                endDate,
                contract
              })
            }
          >
            Очистить фильтры
          </Button>
          {menuData.map(item => createDropdownMenu(item))}
        </Space>
      </Row>
      {hasRange && (
        <>
          <br />
          <Row>
            <Space>
              <RangePicker
                locale={localeDate}
                format="DD-MM-YYYY"
                value={
                  startDate && endDate
                    ? [
                        moment(startDate, 'YYYY-MM-DD'),
                        moment(endDate, 'YYYY-MM-DD')
                      ]
                    : undefined
                }
                onChange={(value, dateString) => onRangeChange(value)}
              />
            </Space>
          </Row>
        </>
      )}
    </Card>
  );

  return {
    loading: filtersRes?.isLoading,
    list: filtersList,
    menuItems: dropdownMenuItems,
    values
  };
};

export default useFilters;
