import React, { Component } from 'react';
import { Typography } from 'antd';

import './SupportPage.css';

const { Title } = Typography;

class SupportPage extends Component {
  render() {
    return (
      <div className="support-page">
        <div>
          <Title>Support</Title>
          <Title level={2}>
            Find answers to your questions and get support for BMP
          </Title>
          <Title>Contact Us</Title>
          <Title level={4}>Email: group.decoders@gmail.com</Title>
        </div>
      </div>
    );
  }
}

export default SupportPage;
