import React, { Component } from 'react';
import { Typography, Space } from 'antd';

const { Title, Text } = Typography;

class TermsOfUsePage extends Component {
  render() {
    return (
      <Space direction="vertical">
        <Title>Terms of Use</Title>

        <Text>Last Updated: 23 May 2024</Text>

        <Text>
          Welcome to BMP! These Terms of Use ("Terms") govern your use of our
          website and mobile application (collectively, the "Service"). By
          accessing or using the Service, you agree to be bound by these Terms.
        </Text>

        <Title>1. Acceptance of Terms</Title>
        <Text>
          By using our Service, you accept and agree to be bound by these Terms.
          If you do not agree to these Terms, you should not use the Service.
        </Text>

        <Title>2. Changes to Terms</Title>
        <Text>
          We may modify these Terms at any time. Any changes will be effective
          immediately upon posting on the Service. Your continued use of the
          Service after any changes indicates your acceptance of the new Terms.
        </Text>

        <Title>3. Use of the Service</Title>
        <Text>
          You agree to use the Service only for lawful purposes and in
          accordance with these Terms. You are responsible for your use of the
          Service and for any content you post.
        </Text>

        <Title>4. User Accounts</Title>
        <Text>
          To use certain features of the Service, you may need to create an
          account. You agree to provide accurate and complete information when
          creating your account and to keep this information up to date.
        </Text>

        <Title>5. Privacy Policy</Title>
        <Text>
          Your use of the Service is also governed by our Privacy Policy, which
          can be found at{' '}
          <a href="https://www.bmpio.com/privacy-policy">
            https://www.bmpio.com/privacy-policy
          </a>{' '}
          .
        </Text>

        <Title>6. Intellectual Property</Title>
        <Text>
          All content and materials on the Service, including but not limited to
          text, graphics, logos, and software, are the property of BMP or its
          licensors and are protected by intellectual property laws.
        </Text>

        <Title>7. Termination</Title>
        <Text>
          We may terminate or suspend your access to the Service at any time,
          without notice, for conduct that we believe violates these Terms or is
          harmful to other users of the Service.
        </Text>

        <Title>8. Disclaimer of Warranties</Title>
        <Text>
          The Service is provided "as is" and "as available" without warranties
          of any kind, either express or implied. We do not warrant that the
          Service will be uninterrupted or error-free.
        </Text>

        <Title>9. Limitation of Liability</Title>
        <Text>
          In no event shall BMP be liable for any indirect, incidental, special,
          consequential, or punitive damages arising out of or related to your
          use of the Service.
        </Text>

        <Title>10. Governing Law</Title>
        <Text>
          These Terms are governed by and construed in accordance with the laws
          of Uzbekistan, without regard to its conflict of law principles.
        </Text>

        <Title>11. Contact Us</Title>
        <Text>
          If you have any questions about these Terms, please contact us at
          group.decoders@gmail.com
        </Text>

        <Text>
          By using the Service, you acknowledge that you have read and
          understood these Terms and agree to be bound by them.
        </Text>
      </Space>
    );
  }
}

export default TermsOfUsePage;
