import React, { useContext, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import withLink from '../../containers/HOC/withLink';
import LogoutOutlined from '@ant-design/icons/lib/icons/LogoutOutlined';
import { privateRoutes } from '../../routes/routes';
import { store } from '../../services/store';
import { logout } from 'services/actions';

const { Header, Content, Footer, Sider } = Layout;

const renderMenuItem = (route, key, empty = false, icon) => {
  const { path, name } = route;

  const children = (
    <React.Fragment {...{ key }}>
      <span>{name}</span>
    </React.Fragment>
  );

  if (empty) return children;

  return (
    <Menu.Item icon={icon} {...{ key }}>
      {withLink(route.routePath || path)(children)}
    </Menu.Item>
  );
};

const AdminLayout = ({ children }) => {
  const location = useLocation();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [state, setState] = useState({
    collapsed: false
  });

  const onCollapse = collapsed => {
    setState({ collapsed });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        // collapsedWidth="0"
        collapsible
        collapsed={state.collapsed}
        onCollapse={collapsed => onCollapse(collapsed)}
      >
        <div
          className="logo-container"
          style={{
            height: '3.8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'rgba(255, 255, 255, 0.65)',
            fontWeight: 'bold'
          }}
        >
          {/*<img src={logo} alt="Logo"/>*/}
          BMP v0.1.4
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
          {privateRoutes.map(route => {
            if (!route.name) return null;

            return renderMenuItem(
              route,
              route.routePath || route.path,
              false,
              route.icon
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: 0,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <div
            className="logout"
            style={{
              padding: '0 8px',
              cursor: 'pointer'
            }}
            onClick={() => {
              dispatch(logout());
            }}
          >
            <LogoutOutlined color="#fff" />
            <span
              style={{
                marginLeft: '8px'
              }}
            >
              Выйти
            </span>
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>BMP</Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
