import React, { useMemo } from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

import useQueryParams from 'hooks/useQueryParams';

const { RangePicker } = DatePicker;

const DateRangePicker = () => {
  const {
    values: { startDate, endDate },
    merge
  } = useQueryParams();

  const formattedStartDate = useMemo(() => (startDate ? moment(startDate, 'YYYY-MM-DD') : null), [startDate]);
  const formattedEndDate = useMemo(() => (endDate ? moment(endDate, 'YYYY-MM-DD') : null), [endDate]);

  const onChange = value => {
    const isEmpty = value?.length !== 2;
    merge({
      startDate: !isEmpty ? moment(value[0]).format('YYYY-MM-DD') : undefined,
      endDate: !isEmpty ? moment(value[1]).format('YYYY-MM-DD') : undefined
    });
  };

  return (
    <Space>
      <RangePicker format="DD.MM.YYYY" value={[formattedStartDate, formattedEndDate]} onChange={onChange} />
    </Space>
  );
};

export default DateRangePicker;
